import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Drawer as BaseDrawer } from '@mui/material';

export default function Drawer({ children, name, ...rest }) {
  const isOpenDrawer = useSelector(state => state.app.isOpenDrawer[name]);

  return (
    <FullWidthDrawer open={isOpenDrawer} {...rest}>
      {children}
    </FullWidthDrawer>
  );
}

const FullWidthDrawer = styled(BaseDrawer)`
  // Todo : find another way to width customize
  .MuiPaper-root {
    width: 100%;
  }
`;
