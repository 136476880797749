import API from 'api';

export const fetchEventList = async () => {
  try {
    const response = await API.get('v1/shopEvent/list');

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
