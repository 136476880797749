import React from 'react';
import { useDispatch } from 'react-redux';
import { changeActiveTab } from 'store/app';
import { setProduct } from 'store/mall';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
} from '@mui/material';
import TextSet from 'components/coupon/TextSet';

export default function CouponItem({ shape, product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClickItem = e => {
    dispatch(setProduct(product));
    dispatch(changeActiveTab({ name: 'product', nextTabIdx: 0 }));
    navigate('/coupon/' + e.currentTarget.id);
  };

  return (
    <React.Fragment>
      {shape === 'row' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
      {shape === 'col' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
      {shape === 'card' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
    </React.Fragment>
  );
}

const CardShape = ({ product, onClick }) => {
  return (
    <Box p={1}>
      <Card>
        <CardActionArea onClick={onClick} id={product.productId}>
          <CardMedia component="img" src={product.imageUrl} width="180" />
          <CardContent>
            <TextSet product={product} />
            <Grid container justifyContent="end">
              {/* Todo: Need to be Button */}
              {/* <Typography fontSize="14px" fontWeight="bold" color="#FF203B">
                구매하기
              </Typography> */}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

