import { Rating } from '@mui/material';

export default function RatingItem({ product }) {
  return (
    <Rating
      precision={0.1}
      defaultValue={0}
      value={
        product !== undefined && product?.reviewScoreAvg
          ? Number(product?.reviewScoreAvg?.toFixed(1))
          : Number(product?.scoreInfo?.reviewScoreAvg)
      }
      size="small"
      readOnly
    />
  );
}
