import React from 'react';
import { Typography, Box } from '@mui/material';
import { parseStringToPrice } from 'utils';
import RatingItem from 'components/product/RatingItem';

export default function TextSet({ product }) {
  return (
    <Box>
      {/* <Typography
        style={{ fontSize: '10px', fontWeight: 'bold', color: 'red' }}
      >
        구매시 추가 적립 혜택
      </Typography> */}
      <Typography
        sx={{
          minHeight: '44px',
          fontSize: '15px',
          color: '#191919',
          overflow: 'hidden',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          mb: 1,
        }}
      >
        {product?.itemOriginName || product?.name}
      </Typography>
      {/* <Box>
        <Typography
          style={{ fontSize: '10px', fontWeight: 'bold', color: '#999999' }}
        >
          상품 부제목 부연설명(글자수 제한)
        </Typography>
      </Box> */}
      <Typography
        style={{ fontSize: '10px', fontWeight: 'bold', color: '#191919' }}
      >
        {Boolean(product?.discount) && product?.discount}
        {Boolean(product?.discount) && (
          <span
            style={{
              fontSize: '10px',
              fontWeight: 'bold',
              color: '#191919',
              textDecoration: 'line-through',
            }}
          >
            {product?.price}
          </span>
        )}
      </Typography>
      <RatingItem product={product} />
      <Typography
        fontSize="0.8rem"
        fontWeight="bold"
        color="grey.600"
        sx={{ textDecoration: 'line-through', mt: 0.2 }}
      >
        {parseStringToPrice(product?.marketPrice)}원
      </Typography>
      <Typography fontSize="0.95rem" fontWeight="bold" color="#131313">
        {parseStringToPrice(product?.price)}원{' '}
        <Typography variant="span" fontSize="1rem" color="primary.main">
          {Math.round(product?.discountByMarketPrice)}
        </Typography>
        <Typography
          variant="span"
          fontSize="1rem"
          color="primary.main"
          fontWeight="light"
        >
          %
        </Typography>
      </Typography>
      <Typography fontSize="0.8rem" fontWeight="bold" color="#FF203B">
        수익금 {parseStringToPrice(product?.point)}P
      </Typography>
    </Box>
  );
}
