import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Navigate,
  useHref,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import QueryString from 'qs';

import {
  Box,
  Grid,
  Typography,
  Button,
  ImageListItem,
  Container,
} from '@mui/material';
import { parseStringToPrice } from 'utils';
import { sendOrderStatus, setOrderStatus, setPayWithPoint } from 'store/order';

import paymentSuccessBanner from 'assets/img/banner/paymentSuccess.png';
import Divider from 'components/Divider';
import PageLayout from 'components/layout/PageLayout';
import PaymentFailure from 'pages/payment/PaymentFailure';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { orderInfoId } = useParams();

  const orderStatus = useSelector(state => state.order.orderStatus);
  const successOrderInfo = useSelector(state => state.order.successOrderInfo);
  const payWithPoint = useSelector(state => state.order.payWithPoint);

  const isSuccess = location.pathname.split('/').includes('success');
  const isFail = location.pathname.split('/').includes('fail');

  const orderStatusParams = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (payWithPoint) {
      dispatch(sendOrderStatus(orderInfoId));
      return;
    }
    // if (location.pathname.split('/').reverse()[0] === 'success') {
    //   dispatch(sendOrderStatus(orderInfoId));
    //   return;
    // }

    dispatch(sendOrderStatus(orderStatusParams));
  }, []);

  useEffect(() => {
    return () => dispatch(setOrderStatus(null));
  }, []);

  useEffect(() => {
    return () => dispatch(setPayWithPoint(false));
  }, []);

  useEffect(() => {
    if (isSuccess || isFail) {
      dispatch(setOrderStatus(true));
    }
  }, [orderStatus]);

  return (
    <Box Box sx={{ minHeight: 'calc(100vh - 118px)' }}>
      {(!orderStatus || (orderStatus && successOrderInfo === 'loading')) && (
        <PageLayout noBackButton>
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            진행중입니다 잠시만 기다려주세요
          </Typography>
        </PageLayout>
      )}
      {
        // orderStatus &&
        // successOrderInfo !== 'idle' &&
        // successOrderInfo !== 'failed' &&
          (
          <PageLayout title="주문결제 완료" footerOff>
            <Box sx={{ minHeight: 'calc(100vh - 178px)' }}>
              <Typography
                pt={4}
                pb={3}
                fontSize="24px"
                fontWeight="bold"
                align="center"
              >
                결제 완료되었습니다.
              </Typography>

              <Box p={2} pt={2.5} pb={2.8}>
                <Typography
                  my={1}
                  color="primary.main"
                  fontSize="0.9rem"
                  fontWeight="bold"
                >
                  받는분 정보
                </Typography>
                <Typography mr={2} fontSize="1.2rem" fontWeight="bold">
                  {successOrderInfo?.deliveryInfo?.recipientName} /{' '}
                  {successOrderInfo?.recipientPhone}
                </Typography>
                <Typography
                  my={1}
                  color="primary.main"
                  fontSize="0.9rem"
                  fontWeight="bold"
                >
                  결제상품
                </Typography>
                <Typography>{successOrderInfo?.itemName}</Typography>
              </Box>

              <Divider thickness="13px" />
              <Box p={2} pt={2.5}>
                <Typography mb={2} fontSize="1.3rem" fontWeight="bold">
                  결제금액{' '}
                </Typography>

                <Grid container justifyContent="space-between" my={3}>
                  <Grid item>{successOrderInfo?.orderInfo?.method}</Grid>
                  <Grid item>
                    <Typography
                      variant="span"
                      fontSize="1.3rem"
                      fontWeight="bold"
                    >
                      {parseStringToPrice(
                        successOrderInfo?.payInfo?.paymentPrice,
                      )}
                      원
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider thickness="13px" />

              <Box p={2} pt={2.5} pb={4} sx={{ mb: 3 }}>
                <Grid container justifyContent="space-between">
                  <Grid item>사용포인트</Grid>
                  <Grid item>
                    <Typography variant="span">
                      {successOrderInfo?.payInfo?.usePoint}P
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid
                container
                sx={{ display: 'flex', gap: 1, height: '42px', px: 2 }}
              >
                <Button
                  variant="outlined"
                  sx={{ flex: 1 }}
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  쿠폰 상세보기
                </Button>
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  onClick={() => {
                    navigate('/coupon');
                  }}
                >
                  쿠폰 더하기
                </Button>
              </Grid>
            </Box>
          </PageLayout>
        )}
      {orderStatus && successOrderInfo === 'failed' && <PaymentFailure />}
    </Box>
  );
}
