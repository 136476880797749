import { useNavigate } from 'react-router-dom';
import { Box, Grid, CardMedia } from '@mui/material';
import TextSet from 'components/Items/TextSet';

export default function ListShapeItem({ product, ...rest }) {
  const navigate = useNavigate();

  const handleOnClickItem = e => {
    navigate('/product/' + e.currentTarget.id);
  };
  return (
    <Grid id={product.itemId} container onClick={handleOnClickItem} {...rest}>
      <Grid item xs={4}>
        <Box pr={1.5} mb={3}>
          <CardMedia
            component="img"
            image={product.thumbNail}
            alt="product image"
            style={{ borderRadius: 8 }}
          />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <TextSet product={product} />
      </Grid>
    </Grid>
  );
}
