import { Zoom, useScrollTrigger, Box, Fab } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollTop = ({ customization, children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });
  const handleClick = event => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      {customization ? (
        <Zoom in={trigger} onClick={handleClick}>
          {children}
        </Zoom>
      ) : (
        <Zoom
          in={trigger}
          sx={{
            position: 'fixed',
            zIndex: 1100,
            right: 15,
            bottom: 80,
          }}
        >
          <Box onClick={handleClick}>
            <Fab
              size="medium"
              aria-label="scroll back to top"
              sx={{ bgcolor: '#FFFFFF', color: '#999999' }}
            >
              <KeyboardArrowUp
                sx={{
                  '& .MuiButtonBase-root-MuiFab-root:hover': {
                    backgroundColor: 'red',
                  },
                }}
              />
            </Fab>
          </Box>
        </Zoom>
      )}
    </>
  );
};
export default ScrollTop;
// const ScrollTop = forwardRef(({ children, window }, ref) => {
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//     disableHysteresis: true,
//     threshold: 100,
//   });

//   const handleClick = event => {
//     ref?.current.scrollIntoView({
//       behavior: 'smooth',
//       block: 'center',
//     });
//   };

//   return (
//     <Zoom
//       in={trigger}
//       sx={{ position: 'fixed', zIndex: 9999, bottom: 80, right: 15 }}
//     >
//       <Box onClick={handleClick} role="presentation">
//         <Fab
//           size="medium"
//           aria-label="scroll back to top"
//           sx={{ bgcolor: '#FFFFFF', color: '#999999' }}
//         >
//           <KeyboardArrowUp />
//         </Fab>
//       </Box>
//     </Zoom>
//   );
// });

// export default ScrollTop;
