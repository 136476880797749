import { useSelector, useDispatch } from 'react-redux';

import { Grid, IconButton, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { setOrderProductCount, setProductOptionDetail } from 'store/mall';
import { setCartUpdate } from 'store/cart';
import { addShopItemToBasket } from 'api/cart';
import { useEffect } from 'react';

export default function CountController({ currentOption, ...rest }) {
  const productOptionDetail = useSelector(
    state => state?.mall.productOptionDetail,
  );
  const orderCartItems = useSelector(state => state?.cart.orderCartItems);
  const dispatch = useDispatch();

  const addCartItem = async itemData => {
    await addShopItemToBasket(itemData);
  };

  const handleCount = operator => {
    let newQty = 0;
    let newQtyForCartItem = currentOption.itemCount;
    const currentOptionQty = currentOption.qty
      ? currentOption.qty
      : currentOption.itemCount;
    if (currentOption.qty) {
      if (operator === 'minus') {
        if (currentOptionQty === 1) return;
        newQty = currentOptionQty - 1;
      }
      if (operator === 'plus') {
        if (currentOptionQty === currentOption.stockCount) return;
        newQty = currentOptionQty + 1;
      }
      const unFreezedOptions = productOptionDetail?.map(item => ({
        ...item,
      }));
      const newOptions = unFreezedOptions.map(presentOption => {
        if (presentOption?.name === currentOption?.name) {
          presentOption.qty = newQty;
        }
        return presentOption;
      });
      dispatch(setProductOptionDetail(newOptions));
    }
    if (!currentOption.qty) {
      if (operator === 'minus') {
        if (currentOptionQty === 1) return;
        newQtyForCartItem -= 1;
      }
      if (operator === 'plus') {
        // if (currentOptionQty === currentOption.stockCount) return;
        newQtyForCartItem += 1;
      }
      const itemData = {
        itemId: currentOption.itemId,
        itemCount: newQtyForCartItem,
        optionToBaskets: [],
        textOption: '',
      };

      addCartItem(itemData);
      dispatch(setCartUpdate(newQtyForCartItem));
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      gap={1.2}
      sx={{
        bgcolor: '#ffffff',
        border: '1px solid #cdcdcd',
        borderRadius: 2,
      }}
      {...rest}
    >
      <IconButton
        size="small"
        onClick={() => {
          handleCount('minus');
        }}
      >
        <RemoveIcon fontSize="10px" />
      </IconButton>
      <Typography>
        {currentOption?.qty ? currentOption?.qty : currentOption?.itemCount}
      </Typography>
      <IconButton
        size="small"
        onClick={() => {
          handleCount('plus');
        }}
      >
        <AddIcon fontSize="10px" />
      </IconButton>
    </Grid>
  );
}
