import API from 'api';

export const updateUserInfo = async data => {
  try {
    const response = await API.post('/v1/user/updateInfo', data);
  } catch (e) {
    console.error(e);
  }
};
export const fetchUserPoint = async data => {
  try {
    const response = await API.post('v1/userPoint/all', data);

    return response.data;
  } catch (e) {
    console.error(e);
  }
};
