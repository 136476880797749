// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { Box } from '@mui/material';

// import { getEventList } from 'store/mall';

// import Banner from 'components/Banner';
// import PageLayout from 'components/layout/PageLayout';

// export default function EventPanel() {
//   const dispatch = useDispatch();
//   const eventList = useSelector(state => state?.mall.eventList);

//   useEffect(() => {
//     dispatch(getEventList());
//   }, []);

//   const moveToEventPage = banner => {
//     let to = '';

//     if (banner.moveUrl === null) to = `event/${banner.id}`;
//     if (banner.moveUrl !== null) to = banner.moveUrl;

//     window.location.href = to;
//   };

//   return (
//     <PageLayout title="이벤트" noBackButton={true} footerOff>
//       <Box p={2}>
//         {eventList?.map((banner, index) => (
//           <Box key={index} mb={2}>
//             <Banner
//               image={banner.imageUrl}
//               height="175"
//               alt="event banner"
//               sx={{ borderRadius: 2 }}
//               onClick={() => moveToEventPage(banner)}
//             />
//           </Box>
//         ))}
//       </Box>
//     </PageLayout>
//   );
// }

/// 쿠폰 업로드


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';
import { getEventList, getMainCoupon } from 'store/coupon';

import Banner from 'components/Banner';
import PageLayout from 'components/layout/PageLayout';
import Item from 'components/Items/Item';
import ItemsName from 'components/Items/ItemsName';

import {
  getMainBestItems,
  setProductListForInfiniteScroll,
} from 'store/mall';

import CouponItem from 'components/CouponItem';
import axios from 'axios';
import XMLParser from 'react-xml-parser';
export default function EventPanel() {


  let categoryName = "best";
  let name = "";
  let shape = "col";
  let xs = 6;
  let tabIndex = 1;
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth.user);

  const productList = useSelector(
    state => state?.mall.productListForInfiniteScroll,
  );
  const status = useSelector(state => state?.mall.status);
  const currentCategoryCode = useSelector(state => state?.mall.currentCategory);
  const bestProducts = useSelector(state => state?.mall.productsBest);
  const coupones = useSelector(state => state?.coupon.coupons);

  const currentHomeTab = useSelector(state => state?.app.activeTab.home);
  const sortBy = useSelector(state => state?.app.sortBy);
  const [page, setPage] = useState(1);



  useEffect(() => {
    (() => {
      if (user) {
        dispatch(getMainCoupon())
      }
    })();
  }, [currentCategoryCode]);




  const addProductIntoList = items => {
    const same = items.find(item => {
      const result = productList?.find(data => data.itemId === item.itemId);
      return result;
    });

    if (same) return;
    const newList = [...productList, ...items];
    dispatch(setProductListForInfiniteScroll(newList));
  };

  const changePage = () => {
    setPage(prev => prev + 1);
  };





  return (
    <PageLayout title="쿠폰선물" noBackButton={true} footerOff>
    <Box m={2}>
      <ItemsName name={name} tabIndex={tabIndex} />
      <>
          {status === 'loading' && (
            <Box
              sx={{
                pt: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <InfiniteScroll
            dataLength={10}
            next={changePage}
            hasMore={
              currentHomeTab === 1
                ? coupones?.length !== 0
                : coupones?.length !== 0
            }

            loader={<Typography align="center">상품을 불러오는 중</Typography>}
            >
             <Grid container spacing={2}>
              {/* Home Tab Sub Category  */}
              {/* currentHomeTab이 0일 때(스토어홈) */}
              {
                coupones?.map((product,index) => (
                  <React.Fragment key={product.name}>
                    <Grid item xs={xs} mb={2}>
                      <CouponItem shape={shape} product={product} />
                    </Grid>
                  </React.Fragment>
                ))}
              {/* end - Home Page List */}
            </Grid>
          </InfiniteScroll>
        </>

    </Box>

    </PageLayout>

  );
}

