import { useDispatch, useSelector } from 'react-redux';
import {  SvgIcon } from '@mui/material';

import 'swiper/css';
import 'swiper/css/pagination';

import styled, {css} from "styled-components";

import { ReactComponent as PointIcon } from 'assets/icons/point.svg';
import { parseStringToPrice } from 'utils';
import { getUserInfo } from 'store/auth';
import {useEffect} from "react";

export default function UserPointSection() {
  const dispatch = useDispatch();

    const point = useSelector(state => state.auth.userPoint);

    useEffect(() => {
        dispatch(getUserInfo());
    }, []);


  return (
        <UserPointBenefit>
            <div>
                <div>
                    <SvgIcon component={PointIcon} />
                    <span className="benefit_size-small">현재 포인트</span>
                </div>
                <div>
                    <span style={{ color: '#FFC94A' }}>
                      {parseStringToPrice(point)} P
                    </span>
                </div>
            </div>
        </UserPointBenefit>
  );
}

const UserPointBenefit = styled.div`
  ${({ theme }) => {
    return css`
      padding: 0 15px;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 100%;
      float: right;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
        border-radius: 10px;
        > div {
          padding: 15px 20px;
          display: flex;
          align-items: center;
          > span {
            color: #767676;
            font-weight: bold;
            > b {
              color: #191919;
            }
            &.benefit_size-small {
              margin-left: 10px;
              font-size: 12px;
            }
          }
        }
      }
    `;
}}
`;