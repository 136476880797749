import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppBar from 'components/layout/AppBar';
import BottomNavigation from 'components/layout/BottomNavigation';

import { Outlet, useLocation } from 'react-router-dom';

export default function Layout() {
  const top = useRef(null);

  const scrollTop = useSelector(state => state?.app.scrollTop);
  const productId = useSelector(state => state.mall.productDetail.itemId);
  const activeTab = useSelector(state => state?.app.activeTab);

  const isPaymentPage = useLocation().pathname === '/payment';
  const isProductDetailPage =
    useLocation().pathname === `/product/${productId}`;

  useEffect(() => {
    if (scrollTop === false) return;
    top.current.scrollIntoView();
  });
  useEffect(() => {
    if (scrollTop === false) return;
    top.current.scrollIntoView();
  }, [activeTab]);

  const showAppBar = () => {
    if (isPaymentPage || isProductDetailPage) {
      return false;
    }

    return true;
  };

  return (
    <React.Fragment>
      <span ref={top}></span>
      {showAppBar() && <AppBar />}
      <Outlet />
      {showAppBar() && <BottomNavigation />}
    </React.Fragment>
  );
}
