import React from 'react';
import {
  BottomNavigation as _BottomNavigation,
  BottomNavigationAction as NavigationActionTap,
} from '@mui/material';
import {isMobile} from 'react-device-detect';

import { useSelector, useDispatch } from 'react-redux';
import { changeBottomTab, changeActiveTab } from 'store/app';

import { BOTTOM_NAVIGATION, BOTTOM_NAVIGATION_NEW } from '../../contants/index';

export default function BottomNavigation() {
  const dispatch = useDispatch();
  const activeTabName = useSelector(state => state.app.activeBottomNavTabName);

  const redirect = () => window.location.replace('http://adsward.co.kr/main');

  const handleOnClick = (e, activeTab) => {
    if (activeTab === 'advertisement') redirect();
    if (activeTab === 'point-shop')
      dispatch(changeActiveTab({ name: 'home', nextTabIdx: 0 }));
    dispatch(changeBottomTab(activeTab));
  };

  function readCookie(cookieName) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === cookieName) {
                return decodeURIComponent(value);
            }
        }
        return "";
  }

    const renewapp = readCookie('renewapp');

  return (
    <React.Fragment>
      <_BottomNavigation
        onChange={handleOnClick}
        value={activeTabName}
        showLabels
        sx={{
          width: '100%',
          height: '70px',
          position: 'sticky',
          zIndex: '999',
            justifyContent:"space-around",
          bottom: 0,
        }}
      >
        {BOTTOM_NAVIGATION.map(data => {
        if(isMobile && data.value === "step" && renewapp === "true" )
        {
            return (
                <NavigationTap
                    key={data.label}
                    label={data.label}
                    value={data.value}
                    icon={data.icon}
                    component={data.component}
                    to={data.to}
                    style={{ maxWidth:"20%", maxHeight:"65px", width:"20%", minWidth:"65px", padding:"0px"}}
                />
            )
        }else if(data.value !== "step" ){
            return (
                <NavigationTap
                    key={data.label}
                    label={data.label}
                    value={data.value}
                    icon={data.icon}
                    component={data.component}
                    to={data.to}
                    style={{ maxWidth:"20%", width:"20%", minWidth:"65px", padding:"0px"}}

                />
            )
        }
        })}
      </_BottomNavigation>
    </React.Fragment>
  );
}

const NavigationTap = ({ label, value, icon, ...rest }) => (
  <NavigationActionTap
    label={label}
    value={value}
    icon={icon}
    {...rest}
    sx={style}
  />
);

const style = {
  '& .MuiBottomNavigationAction-label': {
    pt: 0.5,
    font: 'normal normal bold 10px/15px Noto Sans CJK KR',
    fontSize: '0.75rem !important',
  },

};
