import API from 'api';
import { decodeToken, encodeToken } from 'utils';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const storage = sessionStorage;
const initialState = {
  status: 'idle',
  user: null,
  userPoint: '',
  appLoad: false,
  error: null,
};

export const getValidUser = createAsyncThunk(
  'auth/getValidUser',
  async token => {
    try {
      const user = await API.get('v1/user/viewMyInfo', {
        headers: {
          authorization: token,
        },
      });
      console.log('USER ERROR:', user)

      storage.setItem(
        'ACCESS_AUTH_TOKEN',
        decodeToken(user.headers.authorization).accessToken,
      );
      storage.setItem(
        'REFRESH_AUTH_TOKEN',
        decodeToken(user.headers.authorization).refreshToken,
      );

      const encodedToken = encodeToken({
        refreshToken: storage.getItem('REFRESH_AUTH_TOKEN'),
      });

      API.setAuthInterceptor(encodedToken, authSlice.actions.signOut);

      return user.data;
    } catch (error) {
      console.log('USER ERROR:', error)
      console.error(error);
    }
  },
);

export const getUserInfo = createAsyncThunk('auth/getUserInfo', async () => {
  try {
    const res = await API.get('v1/user/viewMyInfo');
    return res.data;
  } catch (error) {
    console.error(error);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state, action) => {
      API.clearAuthInterceptor();
      storage.removeItem('ACCESS_AUTH_TOKEN');
      storage.removeItem('REFRESH_AUTH_TOKEN');

      state.user = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userPoint = action.payload?.data?.point;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(getValidUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getValidUser.fulfilled, (state, action) => {
        state.status = 'succeeded';

        if (action?.payload?.data) {
          state.user = action.payload?.data;
        }
        state.appLoad = true;
      })
      .addCase(getValidUser.rejected, (state, action) => {
        state.error = action?.error.message;
      });
  },
});

export const { setUser, signOut } = authSlice.actions;
export default authSlice.reducer;
