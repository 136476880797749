import React from 'react';
import { Typography, Box } from '@mui/material';

import SquareIcon from '@mui/icons-material/Square';

export default function DeliveryPolicy() {
  return (
    <Box m={2.7} pb={10}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SquareIcon sx={{ fontSize: '0.9rem', mr: 0.6 }} />
        <Typography fontSize="0.9rem" variant="span">
          배송안내
        </Typography>
      </Box>
      <Typography fontSize="0.8rem" paragraph>
        당일 발주 마감은 오전 11시까지 입니다.
        <br />
        11시 이후 주문건의 경우 익일에 발주가 처리됩니다.
        <br />
        주문상품 대부분은 당일 출고되나 몇 가지 제품의 경우, 익일 출고 또는
        2~3일 후 출고 되는 상품도 있으니 참고 부탁드립니다.
      </Typography>
    </Box>
  );
}
