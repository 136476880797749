import API from 'api';
import axios from 'axios';
import XMLParser from 'react-xml-parser';

var coupons = [];
var couponCategories = [];
var brands = [];
var coupon = {};
var error = "";
/// 쿠폰
export const fetchMainCoupon = async data => {
    try {
        await API.post('/v1/coupon/coupons', data).then((res) => {

            const data = res.data.data;
            error = res.data.error;

            coupons = [];
            for(var i = 0;i < data.length; i++){
                let item = data[i];
                const itemId =  item.couponId;
                const name = item.couponName;
                const price = item.totalPrice ;
                const description = item.content;
                const imageUrl = item.couponImage;
                const marketPrice = item.realTotalPrice;
                const productId = item.id;
                const organization = item.brandName;
                const expireDate = item.limitDate;
                const couponPoint = item.couponPoint;
                coupons.push({
                    itemId,
                    name,
                    price,
                    description,
                    imageUrl,
                    marketPrice,
                    productId,
                    organization,
                    expireDate,
                    couponPoint
                });
            }
        });

        return coupons;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCouponCategory = async data => {
    try {

        await API.get('/v1/coupon/category').then((res) => {

            const data = res.data.data;
            error = res.data.error;


            couponCategories = [];
            for(var i = 0;i < data.length; i++){
                let item = data[i];
                const id =  item.id;
                const name =  item.categoryName;
                const value =  item.categoryVal;
                const beginEnd =  item.beginEnd;
                const image = item.categoryImage ;
                couponCategories.push({
                    id,
                    name,
                    value,
                    image,
                    beginEnd
                });
            }
        });

        return couponCategories;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCouponData = async data=>{
    try {

        await API.post('/v1/coupon/getDetailById', {
            id: data.itemId,
        }).then((res)=>{

            let item = res.data.data;
            error = res.data.error;

            const itemId =  item.couponId;
            const name = item.couponName;
            const price = item.salePrice ;
            const description = item.content;
            const imageUrl = item.couponImage;
            const marketPrice = item.salePrice;
            const productId = item.id;
            const organization = item.brandName;
            const expireDate = item.limitDate;
            const couponPoint = item.couponPoint;

            coupon = {
                itemId,
                name,
                price,
                description,
                imageUrl,
                marketPrice,
                productId,
                organization,
                expireDate,
                couponPoint, error
            };

        });

        return coupon;
    } catch (error) {
        console.error(error);
    }
}

export const fetchCouponBrandList = async data => {
    try {

        await API.post('/v1/coupon/brands', data).then((res) => {

            const data = res.data.data;
            error = res.data.error;

            brands = [{name: "전체", value:"", image:""}];
            for(var i = 0;i < data.length; i++){
                let item = data[i];
                const id =  item.id;
                const name =  item.brandName;
                const value =  item.brandCode;
                const image = item.brandImage ;
                brands.push({
                    id,
                    name,
                    value,
                    image
                });
            }
        });

        return brands;
    } catch (error) {
        console.error(error);
    }
};




