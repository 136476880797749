import React from 'react';
import { useDispatch } from 'react-redux';
import { changeActiveTab } from 'store/app';
import { setProduct } from 'store/mall';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextSet from 'components/Items/TextSet';

export default function Item({ shape, product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClickItem = e => {
    dispatch(setProduct(product));
    dispatch(changeActiveTab({ name: 'product', nextTabIdx: 0 }));
    navigate('/product/' + e.currentTarget.id);
  };

  return (
    <React.Fragment>
      {shape === 'row' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
      {shape === 'col' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
      {shape === 'card' && (
        <CardShape product={product} onClick={handleOnClickItem} />
      )}
    </React.Fragment>
  );
}

const CardShape = ({ product, onClick }) => {
  return (
    <Box p={1}>
      <Card>
        <CardActionArea onClick={onClick} id={product.itemId}>
          <CardMedia component="img" src={product.thumbNail} width="180" />
          <CardContent>
            <TextSet product={product} />
            <Grid container justifyContent="end">
              {/* Todo: Need to be Button */}
              {/* <Typography fontSize="14px" fontWeight="bold" color="#FF203B">
                구매하기
              </Typography> */}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const RowShape = ({ product, onClick }) => {
  return (
    <Grid id={product.itemId} container onClick={onClick}>
      <Grid item xs={4}>
        <Box pr={1.5} mb={3}>
          <CardMedia
            component="img"
            image={product.thumbNail}
            alt="product image"
            style={{ borderRadius: 8 }}
          />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <TextSet product={product} />
      </Grid>
    </Grid>
  );
};

const ColShape = ({ product, onClick }) => {
  const classes = useStyles();

  return (
    <Grid id={product.itemId} container direction="column" onClick={onClick}>
      <Grid item xs={10}>
        <Box pr={1.5} mb={2}>
          <CardMedia
            component="img"
            image={product.thumbNail}
            alt="product image"
            classes={{ img: classes.img }}
          />
        </Box>
      </Grid>

      <Grid item xs={2}>
        <TextSet product={product} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  img: {
    height: '166px',
    /*
      Responsive
      [theme.breakpoints.down('xs')]: {
        height: '166px',
      },
    */
    borderRadius: 8,
  },
}));
