import {
  Box,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'components/Modal';
import Divider from 'components/Divider';

export const ProvidePersonalInfoAgreementModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} fullScreen>
      <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Typography m={2} align="center" fontSize="18px" fontWeight={400}>
        개인정보 제 3자 제공 동의
      </Typography>
      <Divider />
      <Box p={2} sx={{ color: '#777' }}>
        <Typography fontSize="12px" mt={5} mb={5}>
          개인정보 제3자 제공
        </Typography>
        <Typography fontSize="12px" mb={1}>
          ① 회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않으나, 계약
          이행을 위 한 서비스 제공 등에 관한 이용자의 동의가 있거나, 법령에
          의하여 정해진 절차에 따라 정보를 요청 받은 경우는 예외로 하며, 이 경우
          법령에서 정한 최소한의 개인정보를 이 용 및 제공할 수 있습니다.
        </Typography>
        <Typography fontSize="12px" mb={1}>
          ② 회사는 법령에 정해진 책임의 준수를 위해 보관하고 있는 개인정보를
          제3자에 제공 하는 내역은 아래와 같으며, 이용자가 사전에 동의하지 않은
          경우, 제공하지 않습니다
        </Typography>

        <ThemeProvider theme={tableTheme}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">제공받는자</TableCell>
                <TableCell align="center">제공목적</TableCell>
                <TableCell align="center">제공항목</TableCell>
                <TableCell align="center">보유 및 이용기간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">제품판매사와국 내배송업체</TableCell>
                <TableCell align="left">
                  판매사와구매자 간원활한거래의 진행(구매자확인, 해피콜등), 고객
                  상담및불만처리, 서비스제공, 상품 과경품배송(전 송및설치등포
                  함)
                </TableCell>
                <TableCell align="left">
                  수취인정보(이름, 휴대전화번호, 주 소)
                </TableCell>
                <TableCell align="left">
                  이용목적달성시 까지또는관계법 령에따른보존기 간까지
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ThemeProvider>

        <Typography fontSize="12px" mb={1}>
          ③ 이용자는 제3자에 대한 개인정보 제공 동의를 거부할 수 있으며, 동의
          거부 시 해당 서비스 또는 이벤트 등의 이용에 제한이 있을 수 있습니다.
        </Typography>
        <Typography fontSize="12px" mb={1}>
          ④ 이용자는 정보를 회사로부터 개인정보를 제공받은 제3자의 확인을 요청
          수 있으며 회사는 이용자의 요청이 접수되는 경우 개인정보를 제공받은
          자의 정보를 이용자에게 안내합니다.
        </Typography>
      </Box>
    </Modal>
  );
};

export const CollectPersonalInfoAgreementModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} fullScreen>
      <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Typography m={2} align="center" fontSize="18px" fontWeight={400}>
        개인정보 수집 및 이용동의
      </Typography>
      <Divider />
      <Typography m={2} align="center" fontSize="16px" fontWeight={400}>
        구매신청 및 개인정보 제공 동의 등
      </Typography>
      <Box p={2} sx={{ color: '#777' }}>
        <Typography fontSize="12px" mb={1}>
          ① ‘프로그램’ 이용자는 “프로그램”상에서 다음 또는 이와 유사한 방법에
          의하여 구매 를 신청하며, ‘회사’는 이용자가 구매신청을 함에 있어서
          다음의 각 내용을 알기 쉽게 제공하여야 합니다. <br />
          1. 재화 등의 검색 및 선택 2. 받는 사람의 성명, 주소, 전화번호,
          전자우편주소(또는 이동전화번호) 등의 입력 3. 약관내용, 청약철회권이
          제한되는 서비스, 배송료.설치비 등의 비용부담과 관련한 내용에 대한 확인
          4. 이 약관에 동의하고 위 제3.호의 사항을 확인하거나 거부하는 표시(예,
          마우스 클릭) 5. 재화 등의 구매신청 및 이에 관한 확인 또는 ‘회사’의
          확인에 대한 동의 <br />
          6. 결제방법의 선택
        </Typography>
        <ThemeProvider theme={tableTheme}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">수집이용목적</TableCell>
                <TableCell align="center" colSpan={2}>
                  수집항목
                </TableCell>
                <TableCell align="center">보유기간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" rowSpan={4}>
                  대금 결제/환불 서비스 제공, 주문/배송/거래내역조회 서비스
                  제공, 전자상거래법 준수
                </TableCell>
                <TableCell align="left">주문자 정보</TableCell>
                <TableCell align="left">이름 연락처</TableCell>
                <TableCell align="left" rowSpan={5}>
                  이용 목적 달성 시까지 또는 관계 법령에 의한 보존 필요 시까지
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">신용카드 결제 시</TableCell>
                <TableCell align="left">카드사명, 카드번호</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">휴대전화 결제 시</TableCell>
                <TableCell align="left">
                  휴대폰번호, 통신사, 결제승인번호
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">계좌이체 시</TableCell>
                <TableCell align="left">은행명, 계좌번호</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" rowSpan={4}>
                  상품배송(반품/환불/취소)등, 배송지 확인, 최초 입력 후 불러오기
                  기능 제공
                </TableCell>
                <TableCell align="left">배송지 정보</TableCell>
                <TableCell align="left">
                  이름, 주소, 우편번호, 휴대폰번호
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ThemeProvider>
        <Typography fontSize="12px">
          ※ 이용계약(이용약관)이 존속 중인 탈퇴하지 않은 회원의 경우 보유기간은
          보존의무 기간 이상으로 보관할 수 있으며, 이 기간이 경과된 기록에
          대해서 파기 요청이 있는 경우 파기합니다
        </Typography>
      </Box>
    </Modal>
  );
};

export const CancelAndRefundModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} fullScreen>
      <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Typography m={2} align="center" fontSize="18px" fontWeight={400}>
        상품 구매조건 확인/취소,환불 규정 동의
      </Typography>
      <Divider />
      <Box p={2} sx={{ color: '#777' }}>
        <Typography fontSize="12px" mb={1}>
          제14조 (재화 등의 공급)① ‘회사’는 이용자와 재화 등의 공급시기에 관하여
          별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화
          등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
          다만, ‘회사’가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는
          대금의 전부 또는 일부를 받은 날부터 영업일 3일 이내에 조치를
          취합니다.  이때 ‘회사’는 이용자가 재화 등의 공급 절차 및 진행 사항을
          확인할 수 있도록 적절한 조치를 합니다.② ‘회사’는 이용자가 구매한
          재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
          명시합니다. 만약 ‘회사’가 고의나 과실로 약정 배송기간을 초과한
          경우에는 그로 인한 이용자의 손해를 배상합니다. 다만 ‘회사’가
          고의·과실이 없음을 입증한 경우에는 그러하지 않습니다
          <br />
          <br />
          제15조 (환급) “회사”는 이용자가 구매신청한 재화 등이 품절 등의 사유로
          인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게
          통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터
          영업일 3일 이내에, 그렇지 않은 경우에는 그 사유 발생일로부터 3일
          이내에 환급하거나 환급에 필요한 조치를 취합니다.
        </Typography>
      </Box>
    </Modal>
  );
};
export const TermsOfService = () => (
  <Box p={1.2} pt={0}>
    <ThemeProvider theme={typographyTheme}>
      <Typography mb={2} fontWeight={600} fontSize="1rem" align="center">
        이용약관 개인 애즈워드 통합 서비스
      </Typography>
      <Typography color="grey.500" fontSize="0.8rem" align="center">
        공정거래위원회 ‘전자상거래 표준약관 제10023호 (2015. 6.26. 개정)을
        적용하였습니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제1조 (목적)
      </Typography>
      <Typography>
        이 이용 약관(이하 ’약관’이라 한다)은 이용 고객이 ‘애즈워드 주식회사’
        (이하 ’회사’라고 한다)가 제공하는 ’프로그램’을 이용함에 있어 필요한 조건
        및 절차와 기타 관련사항을 규정하는 것을 목적으로 합니다. <br />{' '}
        ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지
        않는 한 이 약관을 준용합니다.」
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제2조 (정의)
      </Typography>
      <Typography mb={1}>
        이 ‘약관’에서 사용하는 용어의 정의는 다음과 같습니다.
      </Typography>
      <Typography>
        ① ’프로그램’이라 함은 ’회사’가 개발한 사용자 수익쉐어 플랫폼인
        ’애즈워드’ 및 이를 운영함에 있어 부수적으로 필요하여 ‘회사’가 설치한
        소프트웨어를 의미 합니다. 부수적으로 설치된 소프트웨어의 경우 추가되거나
        제외될 수 있습니다.
      </Typography>
      <Typography>
        ② ’회원’이라 함은 본 약관에 동의한 후 ‘프로그램’을 설치하고 회원 등록을
        한 자로서, 계속적으로 ‘회사’가 제공하는 서비스를 이용할 수 있는
        ‘이용자’를 말합니다.
      </Typography>
      <Typography>
        ③ ’서비스’라 함은 ‘회사’의 ‘프로그램’을 모바일 및 PC 등에 회원이
        설치하고 약관에 동의한 후 본 ‘프로그램’을 이용하는 것을 말합니다.
      </Typography>
      <Typography>
        ④ ’광고주’라 함은 ‘회사’와 계약을 체결하고 ‘프로그램’을 통하여 자신의
        영업활동(재화나 서비스 등)을 홍보하거나 ‘프로그램’을 통해 마케팅을
        실행하는 개인 혹은 사업자(광고대행사 등 광고 중개사업자 포함)를
        말합니다.
      </Typography>
      <Typography>
        ⑤ ‘몰’이란 애즈워드(주)가 재화 또는 용역(이하 “재화 등”이라 함)을
        이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을
        거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을
        운영하는 사업자의 의미로도 사용합니다. ‘프로그램’으로 통칭할 수
        있습니다.
      </Typography>
      <Typography>
        ⑥ ‘제휴쇼핑(몰)’이라함은 ‘(주)링크프라이스’로 부터 api로 연계해서 제공
        받는 모든 서비스를 말합니다.
      </Typography>
      <Typography>
        ⑦ ‘오퍼월’은 ‘(주)티앤케이팩토리’로 부터 api로 연계해서 제공 받는 모든
        서비스를 말합니다.
      </Typography>
      <Typography>
        ⑧ ’리워드’라 함은 ‘서비스’를 통해서 ‘광고수익, 쇼핑수익, 제휴몰 수익,
        오퍼월 수익’ 등과 같이 ‘회사’가 ‘회원’에게 수익금으로 지급하는 모든
        포인트를 의미합니다. 여기서 ‘리워드’와 ‘수익금 적립’ 그리고 포인트
        적립은 같은 의미 입니다.
      </Typography>
      <Typography>
        ⑨ ’PC 등’이라 함은 회원이 ‘프로그램’을 설치하여 이용하는 PC, 노트북,
        태블릿 PC, 휴대폰, 기타 관련 기기를 의미합니다.
      </Typography>
      <Typography>
        ⑩ 이 ‘약관’에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 ‘회사’에서
        운영하는 홈페이지 혹은 ‘프로그램’에서 정하는 바에 따르며 그 외에는 일반
        관례에 따릅니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제3조 (약관 등의 명시와 설명 및 개정)
      </Typography>
      <Typography>
        ① 본 ‘약관’은 ‘프로그램’의 설치 시에 ‘회사’가 이 ‘약관’을 안내하고
        ‘회원’이 동의함으로써 효력이 발생합니다. ‘회사’는 이 약관의 내용과 상호,
        영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소 포함),
        대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등),
        통신판매업신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록
        “앱”의 초기 서비스화면(하단)에 게시합니다. 다만, 약관의 내용은 이용자가
        연결화면을 통하여 볼 수 있도록 할 수 있습니다.
      </Typography>
      <Typography>
        ② “회사”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중
        청약철회·배송책임·환불조건 등과 같은 중요한 내용을 이용자가 이해할 수
        있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구할
        수 있습니다.
      </Typography>
      <Typography>
        ③ ‘회사’는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
        규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」,
        「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
        「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는
        범위에서 이 약관을 개정할 수 있습니다.
      </Typography>
      <Typography>
        ④ ‘회사’는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 ‘프로그램’의 초기화면에 그 적용일자 7일 이전부터
        적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
        변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이
        경우 "회사“는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가
        알기 쉽도록 표시합니다.
      </Typography>
      <Typography>
        ⑤ ’회사’는 제 4항에 따라 변경된 ‘약관’을 공지하면서 ‘회원’이 ‘약관’ 변경
        적용일 까지 거부 의사를 표시하지 않으면 ‘회원’은 변경된 ‘약관’에
        동의하는 것으로 간주 합니다.
      </Typography>
      <Typography>
        ⑥ ’회원’은 이 ‘약관’ 혹은 개정된 ‘약관’에 동의하지 않는 경우
        ‘프로그램’을 설치하지 않거나 ‘회원’ 탈퇴 후 삭제함으로써 이용계약을
        체결하지 않거나 해지할 수 있습니다.
      </Typography>
      <Typography>
        ⑦ ‘회사’는 본 ‘약관’을 개정하는 경우 적용 일자와 개정사항을 ‘회사’
        홈페이지 또는 ‘프로그램’의 서비스 화면에 게재 하거나 이메일 등으로
        공지할 수 있습니다.
      </Typography>
      <Typography>
        ⑧ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래
        등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
        공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령
        또는 상관례에 따릅니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제4조 (서비스의 제공 및 변경)
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ① ‘회사’는 다음과 같은 업무를 수행합니다.
        </Typography>
        <Typography ml={1}>1. ‘프로그램’의 개발 및 운영 서비스</Typography>
        <Typography ml={1}>
          2. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
        </Typography>
        <Typography ml={1}>
          3. 구매계약이 체결된 재화 또는 용역의 배송
        </Typography>
        <Typography ml={1}>4. 마케팅 정보 제공 및 이행</Typography>
        <Typography ml={1}>5. 기타 ‘회사’가 정하는 업무</Typography>
      </Box>
      <Typography>
        ② ‘회사’는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는
        장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수
        있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공 일자를
        명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
      </Typography>
      <Typography>
        ③ ‘회사’가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의
        품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를
        이용자에게 통지 가능한 주소(‘회사’와 미리 약정하여 지정한 연락처,
        전자우편주소 등 연락 가능한 수단을 포함)로 즉시 통지합니다.
      </Typography>
      <Typography>
        ④ 전항의 경우 ‘회사’는 이로 인하여 이용자가 입은 손해를 배상 합니다.
        다만, ‘회사’가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
        아니합니다.
      </Typography>
      <Typography>
        ⑤ ‘회사’가 제공하는 세부적인 서비스 내용에 관하여 별도의 개별약관, 공지,
        안내 등(이하 ‘개별약관 등’이라 함)이 있는 경우 개별약관 등은 본 약관에
        우선하여 적용되며, 개별약관 등에 명시되지 아니한 내용은 본 약관의 내용을
        준용합니다.
      </Typography>
      <Typography>
        ⑥ ’회원’이 본 ‘약관’에 대한 동의를 하고 ‘회원’에 가입하는 경우 ‘회사’는
        서비스의 설치 및 운영상의 품질유지를 위하여 ‘회원’의 ‘PC 등’의 사양
        정보를 수집하고 이를 이용할 수 있습니다.
      </Typography>
      <Typography>
        ⑦ ’회원’이 ‘프로그램’을 설치하거나 ‘프로그램’이 업데이트되는 경우
        ‘회사’는 본 ‘서비스’ 이외에 광고 콘텐츠 및 기타 서비스를 직접 제공할 수
        있습니다.
      </Typography>
      <Typography>
        ⑧ ‘회사’는 본 ‘서비스’와 관련된 공지사항을 ‘회사’ 홈페이지 또는
        ‘프로그램’의 서비스 화면에 게재 하거나 어플 푸시 서비스, 문자메시지,
        이메일 등으로 제공할 수 있습니다.
      </Typography>
      <Typography>
        ⑨ ’회사’는 본 ‘서비스’ 운영과 유지를 위해 ‘프로그램’에 광고를 게재하거나
        ‘회원’에게 어플 푸시 서비스, 문자메시지, 이메일, 등으로 광고를 전송할 수
        있습니다. ‘회원’은 본 서비스 이용약관을 동의할 경우 광고 게재 및 제공을
        동의하는 것으로 합니다. 단, 본 광고 제공은 ‘회원’이 고객센터 등을 통해
        거부할 수 있습니다. 그럼에도 불구하고 ‘서비스’ 이용에 관련하여 불가피한
        경우 ‘회사’는 ‘회원’에게 관련 정보를 제공할 수 있습니다.
      </Typography>
      <Typography>
        ⑩ ’회사’는 ‘회사’ 또는 ‘광고주’의 옵션 설정에 의해 ‘회원’에 따라
        ‘서비스’를 다르게 제공할 수 있습니다.
      </Typography>
      <Typography>
        ⑪ ‘회사’는 이용자에게 최상의 서비스를 원활하게 제공하기 위한 재정기반을
        마련하기 위하여 상업용 광고를 화면에 게재 하거나 「정보통신망 이용촉진
        및 정보보호 등에 관한 법률」 등에서 정한 요건에 따라 이메일 또는
        전화문자 등을 이용하여 개별 이용자에게 보낼 수 있습니다. 단, 수신 거절의
        의사를 명백히 표시한 이용자에 대해서는 더 이상 개별적인 이메일 또는
        전화문자 등을 발송하지 않습니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제5조 (서비스의 중단)
      </Typography>
      <Typography>
        ① “회사”는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절
        등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며,
        이 경우 사전에 ‘프로그램’ 혹은 홈페이지에 공지합니다.
      </Typography>
      <Typography>
        ② ’회사’는 국가비상사태, 정전, 서비스 이용의 폭주 등 ‘회사’가 통제할 수
        없는 상황으로 인하여 ‘프로그램’의 이용 혹은 업무의 지속이 어려운 경우
        사전 혹은 사후에 그 내용을 공지합니다.
      </Typography>
      <Typography>
        ③ 제1항에 의한 서비스 중단의 경우에는 “회사”는 제9조에 정한 방법으로
        이용자에게 통지합니다.
      </Typography>
      <Typography>
        ④ “회사”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여
        이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, ‘회사’가 고의
        또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
      </Typography>
      <Typography>
        ⑤ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를
        제공할 수 없게 되는 경우에는 ‘회사’는 제9조에 정한 방법으로 이용자에게
        통지하고 당초 ‘회사’에서 제시한 조건에 따라 소비자에게 보상합니다. 다만,
        ‘회사’가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 적립금 등을
        ‘프로그램’에서 통용되는 통화가치에 상응하는 현물 또는 현금으로
        이용자에게 지급합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제6조 (회원가입)
      </Typography>
      <Typography>
        ① 이용자는 ‘회사’가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에
        동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ② ‘회사’는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각
          호에 해당하지 않는 한 회원으로 등록합니다.
        </Typography>
        <Typography ml={1}>
          1. 가입 신청자가 이 약관 제 7조 제 3항에 의하여 이전에 회원 자격을
          상실한 적이 있는 경우, 다만 제 7조 제 3항에 의한 회원자격 상실 후
          3년이 경과한 자로서 ‘회사’의 회원 재가입 승낙을 얻은 경우에는 예외로
          한다.
        </Typography>
        <Typography ml={1}>
          2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
        </Typography>
        <Typography ml={1}>
          3. 기타 회원으로 등록하는 것이 ‘프로그램’의 기술상 현저히 지장이
          있다고 판단되는 경우
        </Typography>
        <Typography ml={1}>
          4. 회원가입 신청자 중 회원 가입일 현재 만 14세 미만인 경우
        </Typography>
        <Typography ml={1}>
          5. 가입 신청자가 ‘프로그램’ 회원 탈퇴를 한지 30일이 경과 하지 않은
          이용자의 경우
        </Typography>
      </Box>
      <Typography>
        ③ 회원가입 계약의 성립 시기는 ‘회사’의 승낙이 회원에게 도달한 시점으로
        합니다.
      </Typography>
      <Typography>
        ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에
        ‘프로그램’에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야
        합니다. 이를 게을리하여 발생하는 회원의 불이익이 있는 경우 이는 ‘회사’의
        책임으로 보지 않습니다.
      </Typography>
      <Typography>
        ⑤ ‘회원’은 원칙적으로 1개의 아이디 계정만을 이용할 수 있습니다. 본인
        명의로 2개 이상의 아이디 계정을 사용할 수 없습니다.
      </Typography>
      <Typography>
        ⑥ 아이디 (이메일)는 변경할 수 없으며, 탈퇴 후 새로운 아이디로 재 가입할
        수 있습니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제7조(회원 탈퇴 및 자격 상실 등)
      </Typography>
      <Typography>
        ① 회원은 ‘회사’에 언제든지 탈퇴를 요청할 수 있으며 ‘회사’는 즉시
        회원탈퇴를 처리합니다. 다만, 재화 또는 용역 구매와 관련한
        채권·채무관계가 남아 있는 경우에는 탈퇴조치가 되지 않거나 개인정보를
        채권추심 등의 목적으로 계속 이용할 수 있고, 법령상 특별한 규정이 있는
        경우에는 해당 개인정보를 법령이 정한 목적 및 기간의 범위 내에서 계속
        보관할 수 있습니다.
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ② 회원이 다음 각 호의 사유에 해당하는 경우, ‘회사’는 회원자격을 제한
          및 정지시킬 수 있습니다.
        </Typography>
        <Typography pl={1}>
          1. 가입 신청 시에 허위 내용을 등록한 경우
        </Typography>
        <Typography pl={1}>
          2. ‘프로그램’을 이용하여 구입한 재화 등의 대금, 기타 ‘프로그램’이용에
          관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
        </Typography>
        <Typography pl={1}>
          3. ‘프로그램’을 이용하여 구입한 재화를 정당한 사유 없이 수취 거부
          하였을 경우
        </Typography>
        <Typography pl={1}>
          4. 다른 사람의 ‘프로그램’ 이용을 방해하거나 그 정보를 도용하는 등
          전자상거래 질서를 위협하는 경우
        </Typography>
        <Typography pl={1}>
          5. ‘프로그램’을 이용하여 법령 또는 이 약관이 금지하거나 공공의 질서와
          선량한 풍속 (공서양속)에 반하는 행위를 하는 경우
        </Typography>
        <Typography pl={1} mb={1}>
          6. 기타 다음과 같은 행위 등으로 ‘프로그램’의 건전한 운영을 해하거나
          ‘회사’의 업무를 방해하는 경우
        </Typography>
        <Typography pl={2}>
          ㄱ. ‘프로그램’의 운영에 관련하여 근거 없는 사실 또는 허위의 사실을
          적시하거나 유포하여 ‘회사’의 명예를 실추시키거나 ‘회사’의 신뢰성을
          해하는 경우
        </Typography>
        <Typography pl={2}>
          ㄴ. ‘프로그램’의 이용 등과 관련하여 ‘회사’의 직원 및 ‘회사’의 업무를
          수행하는 협력사의 직원에게 폭언 또는 음란한 언행을 하여 업무환경을
          심각히 해하는 경우
        </Typography>
        <Typography pl={2}>
          ㄷ. ‘프로그램’의 이용 등과 관련하여 이유 없는 잦은 연락을 하거나 소란
          또는 협박, 인과관계가 없는 피해에 대한 보상(적립금, 현금, 상품, 쿠폰)
          요구 등으로 업무를 방해하는 경우
        </Typography>
        <Typography pl={2}>
          ㄹ. ‘프로그램’을 통해 구입한 재화·상품 또는 용역에 특별한 하자가
          없는데도 불구하고 일부 사용 후 상습적인 취소, 전부 또는 일부 반품
          등으로 방해하는 경우. 상습성의 판단은 내부 규정이 있는 경우 그에
          따릅니다.
        </Typography>
        <Typography pl={2}>
          ㅁ. ‘프로그램’의 이용 등과 관련하여 이유 없는 취소·반품 또는 기타 행위
          등의 방법으로 ‘서비스’에서 포인트등을 부정하게 적립을 받거나 쿠폰 등을
          부당하게 발급받거나 사용하여 부당하게 이득을 취한 경우
        </Typography>
        <Typography pl={2}>
          ㅂ. 재판매 목적으로 재화 등을 중복 구매하여 거래질서를 방해하는 경우
        </Typography>
      </Box>
      <Typography>
        ③ ‘회사’가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상
        반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 ‘회사’는
        회원자격을 상실시킬 수 있습니다.
      </Typography>
      <Typography>
        ④ ‘회사’가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우
        회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을
        정하여 소명할 기회를 부여합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제8조(휴면계정 관리)
      </Typography>
      <Typography>
        ① "회사”는 회원이 최종접속일로부터 1 년 이상 (365 일) 이상 ‘프로그램’에
        로그인을 하지 않거나 포인트의 적립금이 없을 경우, 휴면계정으로 간주하고
        회사가 제공하는 서비스 이용을 제한/상실시킬 수 있습니다.
      </Typography>
      <Typography>
        ② 휴면계정으로 전환될 경우, ‘프로그램’ 의 서비스를 일체 이용하실 수
        없습니다. 이후 서비스를 재개하려면 확인 절차를 거쳐야 합니다.
      </Typography>
      <Typography>
        ③ ‘회사’ 는 휴면계정으로 전환하기 최소 30 일 전에는 전자우편, 문자
        메세지 등을 통해 사전에 안내하여 드립니다.
      </Typography>
      <Typography>
        ④ 휴면계정으로 전환된 회원의 정보 중, 개인정보(기기정보, 이용자 ID,
        이름, 연락처, 본인확인정보, 비밀번호, 상담/문의내역, 결제내역, 접속기록
        등)는 별도 분리하여 안전하게 관리됩니다.
      </Typography>
      <Typography>
        ⑤ 분리 보관된 개인정보는 휴면해제 외의 다른 용도로는 사용되지 않습니다.
      </Typography>
      <Typography>
        ⑥ 휴면계정 전환 후 1 년의 기간 동안 로그인을 통해 휴면 계정 해지를
        신청하지 않을 시, 회원의 개인정보는 모두 파기됩니다
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제9조 (회원에 대한 통지)
      </Typography>
      <Typography>
        ① ‘회사’가 회원에 대한 통지를 하는 경우, 회원이 ‘회사’와 미리 약정하여
        지정한 문자수신번호와 카카오톡 또는 전자우편 주소 등으로 할 수 있습니다.
      </Typography>
      <Typography>
        ② ‘회사’는 불특정다수 회원에 대한 통지의 경우 1주일이상 ‘프로그램’
        게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의
        거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제10조(구매신청 및 개인정보 제공 동의 등)
      </Typography>

      <Box mb={1}>
        <Typography mb={1}>
          ① ‘프로그램’ 이용자는 “프로그램”상에서 다음 또는 이와 유사한 방법에
          의하여 구매를 신청하며, ‘회사’는 이용자가 구매신청을 함에 있어서
          다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        </Typography>
        <Typography ml={1}>1. 재화 등의 검색 및 선택</Typography>
        <Typography ml={1}>
          2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호)
          등의 입력
        </Typography>
        <Typography ml={1}>
          3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의
          비용부담과 관련한 내용에 대한 확인
        </Typography>
        <Typography ml={1}>
          4. 이 약관에 동의하고 위 제3.호의 사항을 확인하거나 거부하는 표시(예,
          마우스 클릭)
        </Typography>
        <Typography ml={1}>
          5. 재화 등의 구매신청 및 이에 관한 확인 또는 ‘회사’의 확인에 대한 동의
        </Typography>
        <Typography ml={1}>6. 결제방법의 선택</Typography>
      </Box>

      <Typography>
        ② ‘회사’가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1)
        개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3)
        제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및
        이용기간을 구매자에게 알리고 동의를 받습니다. (동의를 받은 사항이
        변경되는 경우에도 같습니다.)
      </Typography>
      <Typography>
        ③ ‘회사’가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를
        위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을
        하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은
        사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을
        위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진
        및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보
        취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제11조 (계약의 성립)
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ① ‘회사’는 제10조와 같은 구매신청에 대하여 다음 각 호에 해당하면
          승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는
          법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을
          취소할 수 있다는 내용을 고지하여야 합니다.
        </Typography>
        <Typography ml={1}>
          1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
        </Typography>
        <Typography ml={1}>
          2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을
          구매하는 경우
        </Typography>
        <Typography ml={1}>
          3. 상행위(재판매) 목적으로 구매하는 거래이거나, 거래 정황상
          상행위(재판매)를 목적으로 한 구매로 판단되는 경우(단, 상행위로
          인정하는 기준, 구매청약 거절, 계약 취소 등의 조치 방법은 해당
          ‘서비스’에서 정한 정책에 따름)
        </Typography>
        <Typography ml={1}>4. 국외 배송의 경우</Typography>
        <Typography ml={1}>
          5. 기타 구매신청에 승낙하는 것이 ‘프로그램’ 기술상 현저히 지장이
          있다고 판단하는 경우
        </Typography>
        <Typography ml={1}>
          6. 제 7조 제 2항에 해당하여 회원자격이 제한 및 정지된 고객의
          구매신청임이 확인된 경우
        </Typography>
        <Typography ml={1}>7. 기타 위 각 호와 유사한 경우</Typography>
      </Box>
      <Typography>
        ② ‘회사’의 승낙이 제13조 제1항의 수신확인통지형태로 이용자에게 도달한
        시점에 계약이 성립한 것으로 봅니다.
      </Typography>
      <Typography>
        ③ ‘회사’의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및
        판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야
        합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제12조 (재화 또는 용역 구매에 대한 지급방법)
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ‘프로그램’에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각
          호의 방법 중 가용한 방법으로 할 수 있습니다. 단, ‘회사’는 이용자의
          지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
          징수할 수 없습니다.
        </Typography>
        <Typography ml={1}>
          1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
        </Typography>
        <Typography ml={1}>
          2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
        </Typography>
        <Typography ml={1}>3. 온라인무통장입금</Typography>
        <Typography ml={1}>4. 전자화폐에 의한 결제</Typography>
        <Typography ml={1}>
          5. 수령 시 대금지급(단, 본 호의 대금지급 방법은 ‘서비스’별로 특정상품
          및 특정기간에 한정하여 부정기적 시행할 수 있음)
        </Typography>
        <Typography ml={1}>
          6. 적립 포인트 등 “회사”가 지급한 포인트에 의한 결제
        </Typography>
        <Typography ml={1}>
          7. ‘회사’와 계약을 맺었거나 ‘회사’가 인정한 상품권에 의한 결제
        </Typography>
        <Typography ml={1}>
          8. 기타 전자적 지급 방법에 의한 대금 지급 등
        </Typography>
      </Box>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제13조(수신확인통지·구매신청 변경 및 취소)
      </Typography>
      <Typography>
        ① ‘회사’는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를
        합니다.
      </Typography>
      <Typography>
        ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는
        수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고
        ‘회사’는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에
        따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제17조의
        청약철회 등에 효과에 관한 규정에 따릅니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제14조 (재화 등의 공급)
      </Typography>
      <Typography>
        ① ‘회사’는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상,
        이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록
        주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, ‘회사’가 이미
        재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를
        받은 날부터 영업일 3일 이내에 조치를 취합니다. 이때 ‘회사’는 이용자가
        재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를
        합니다.
      </Typography>
      <Typography>
        ② ‘회사’는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자,
        수단별 배송기간 등을 명시합니다. 만약 ‘회사’가 고의나 과실로 약정
        배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상합니다. 다만
        ‘회사’가 고의·과실이 없음을 입증한 경우에는 그러하지 않습니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제15조 (환급)
      </Typography>
      <Typography>
        “회사”는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을
        할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화
        등의 대금을 받은 경우에는 대금을 받은 날부터 영업일 3일 이내에, 그렇지
        않은 경우에는 그 사유 발생일로부터 3일 이내에 환급하거나 환급에 필요한
        조치를 취합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제16조(할인쿠폰)
      </Typography>
      <Typography>
        ① 회사 또는 판매자는 구매서비스를 이용하는 회원에게 상품 구매 시 일정액
        또는 일정비율을 할인 받을 수 있는 할인쿠폰을 발급할 수 있습니다.
      </Typography>
      <Typography>
        ② 회원은 할인쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한
        경우에도 이를 타인에게 실질적으로 매매 또는 양도할 수 없습니다. 단,
        회사는 양도방법과 조건을 정하여 할인쿠폰의 양도를 허용할 수 있으며, 이
        경우 회원은 그 방법과 조건에 따라서만 할인쿠폰을 양도할 수 있습니다.
      </Typography>
      <Typography>
        ③ 할인쿠폰은 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이
        지난 후에는 사용할 수 없습니다.
      </Typography>
      <Typography>
        ④ 할인쿠폰을 사용하여 상품을 구입한 후 취소나 반품으로 인하여 환불이
        이루어진 경우에는 원칙적으로 할인쿠폰의 재사용이 가능하나 단순변심에
        의한 구매취소 등 회사의 내규로 정한 일정한 경우에는 재사용이 불가능할 수
        있으며, 할인이 적용된 금액을 회원이 반환할 수 있습니다.
      </Typography>
      <Typography>
        ⑤ 1회 사용된 쿠폰은 어떠한 사유에도(고객 변심으로 인한 취소 및 상품
        불량일 경우 포함) 원상 복구되지 않는 것을 원칙으로 합니다.
      </Typography>
      <Typography>
        ⑥ 할인쿠폰은 ‘회사’가 정한 상품 또는 서비스에 대하여 사용 가능하며,
        순금, 상품권 등의 일부 재화에 대해서는 사용할 수 없습니다.
      </Typography>
      <Typography>
        ⑦ 회원이 할인쿠폰을 부당 또는 부정하게 취득한 경우 회원은 이를 사용할 수
        없으며, 이로 인해 발생하는 모든 법률적인 책임을 부담합니다. 또한
        ‘회사’는 부당, 부정하게 취득한 할인쿠폰에 대해서 회수 또는 말소할 수
        있습니다.
      </Typography>
      <Typography>
        ⑧ 회원 자격이 상실된 경우 해당 회원이 소지한 할인쿠폰은 유효기간 내라고
        하더라도 사용이 불가합니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제17조 (청약철회 등)
      </Typography>
      <Typography>
        ① ‘회사’와 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래
        등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한
        서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진
        경우에는 재화 등을 공급 받거나 재화 등의 공급이 시작된 날을
        말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만,
        청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리
        정함이 있는 경우에는 동 법 규정에 따릅니다.
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ② 이용자는 재화 등을 배송 받은 경우 다음 각호의 1에 해당하는 경우에는
          반품 및 교환을 할 수 없습니다.
        </Typography>
        <Typography ml={1}>
          1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만,
          재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를
          할 수 있습니다.)
        </Typography>
        <Typography ml={1}>
          2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히
          감소한 경우(화장품류, 식품은 밀봉 개봉 시, 의류·침구류는 수선했거나
          세탁하였을 시, 기타 사용·분리·훼손에 의해 상품의 가치가 현저히
          감소하여 재판매가 불가할 시)
        </Typography>
        <Typography ml={1}>
          3. 시간의 경과에 의하여 재판매가 곤란할 정도의 재화 등의 가치가 현저히
          감소한 경우
        </Typography>
        <Typography ml={1}>
          4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의
          포장을 훼손한 경우(CD, DVD, GAME, 서적 등의 경우 포장 개봉 시)
        </Typography>
        <Typography ml={1}>
          5. 그 밖에 거래의 안전을 위하여 관련 법령이 정하는 경우
        </Typography>
      </Box>
      <Typography>
        ③ 제2항 2호 내지 4호의 경우에 ‘회사’가 사전에 청약철회 등이 제한되는
        사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는
        등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
      </Typography>
      <Typography>
        ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고
        내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은
        날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에
        청약철회 등을 할 수 있습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제18조 (청약철회 등의 효과)
      </Typography>
      <Typography>
        ① ‘회사’는 이용자로부터 재화 등을 반환 받은 경우 영업일 3일 이내에 이미
        지급받은 재화 등의 대금을 환급합니다. 이 경우 ‘회사’가 이용자에게 재화
        등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의
        소비자보호에 관한 법률 시행령」이 정하는 지연이자율을 곱하여 산정한
        지연이자를 지급합니다.
      </Typography>
      <Typography>
        ② ‘회사’는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐
        등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해
        결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는
        취소하도록 요청합니다.
      </Typography>
      <Typography>
        ③ 가상계좌 입금, 휴대폰결제 등, 결제수단의 대금 청구 정지 또는 취소를
        통해 환급이 불가능한 경우 고객의 계좌를 통한 환급을 진행할 수 있으며,
        환급 시, 주문자와 환불 받을 계좌의 소유주가 동일해야 합니다.
      </Typography>
      <Typography>
        ④ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가
        부담합니다. ‘회사’는 이용자에게 청약철회 등을 이유로 위약금 또는
        손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과
        다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의
        반환에 필요한 비용은 ‘회사’가 부담합니다.
      </Typography>
      <Typography>
        ⑤ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 ‘회사’는
        청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게
        표시합니다..
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제 19 조(포인트 적립금)
      </Typography>
      <Typography>
        ① ‘회원’은 ‘프로그램’에서 광고 시청과 각종 이벤트 그리고 재화 등의 구매
        및 공급을 완료한 후 ‘회사’가 정한 적립 기준 등에 따라 ‘회사’로부터
        수익금의 일부를 적립금 등으로 적립 받을 수 있습니다.
      </Typography>
      <Typography>
        ② 제 1 항에 따라 완료된 재화 등의 구매 및 공급이 취소 또는 철회되거나,
        전산시스템의 오류, 또는 기타 비정상적인 방법으로 적립금이 적립된 경우,
        ‘회사’는 적립된 적립금을 회수할 수 있습니다
      </Typography>
      <Typography>
        ③ ‘회원’은 ‘프로그램’에서 상품구매 시 단독으로 또는 다른 결제 수단과
        함께 적립금을 사용할 수 있습니다
      </Typography>
      <Typography>
        ④ ‘회원’은 적립금을 제 3 자에게 또는 다른 아이디로 양도할 수 없으며,
        유상으로 거래할 수 없습니다.
      </Typography>
      <Typography>
        ⑤ ‘프로그램’을 통해서 적립된 적립금의 유효기간은 “회사“의 정책에 따라
        책정될 수 있으며 이는 별도로 공지합니다. 고지된 유효기간 내에 사용하지
        않은 적립금은 해당 유효기간 만료 시 즉시 소멸됩니다.
      </Typography>
      <Typography>
        ⑥ ‘회원’이 구매한 물품 등을 환불하는 경우, 적립이나 사용한 적립금은 취소
        또는 복원됩니다.
      </Typography>
      <Typography>
        ⑦ ‘회원’이 광고시청, 제품 구매 또는 이벤트 등으로 받은 적립금을 사용한
        후 해당 적립 원인이 취소(반품)되는 경우 이를 ‘회사’에 반환하여야 하며
        적립금 잔여분이 있는 경우 자동으로 차감 되거나 또는 적립되는 즉시 해당
        적립금이 충족 될 때까지 자동으로 차감됩니다.
      </Typography>
      <Typography>
        ⑧ 적립금은 유효기간 종료일이 빠른 순서로 사용되며, 종료일이 동일한
        경우에는 발생일이 빠른 순으로 사용됩니다.
      </Typography>
      <Typography>
        ⑨ 회원 탈퇴/휴면회원 전환 시 미사용한 적립금은 즉시 소멸되며, 탈퇴 후
        재가입 하더라도 소멸된 적립금은 복구되지 않습니다.
      </Typography>
      <Typography>
        ⑩ 부정한 방법 등으로 ‘회원’에게 적립된 적립금을 ‘회사’ 는 회수 및 기타
        조치를 취할 수 있습니다.
      </Typography>
      <Typography>
        ⑪ 회원 자격이 상실된 경우 해당 회원이 소지한 적립금은 유효기간 내라고
        하더라도 사용이 불가합니다.
      </Typography>
      <Typography>
        ⑫ “회사”와 업무 제휴에 의한 ‘링크프라이스’와 ‘TNK Factory’는 자체적으로
        운영하는 적립금의 적립 및 사용 등과 관련된 기타 상세한 사항에 대해서
        해당 적립금이 발생한 각 “제휴사”의 운영정책에 따릅니다.
      </Typography>
      <Typography>
        ⑬ 리워드 적립 포인트에 관련한 활용 방안은 현금전환, 물품구매, 간편결재
        등 사용자 편의를 최대한 고려하여 그 방법과 방향을 회사가 정하고 시행
        30일 전부터 고지합니다.
      </Typography>
      <Typography>
        ⑭ ‘프로그램’내에서 적립 받은 포인트는 통합 운영되며 ‘1포인트=1원’의
        동일한 가치를 갖습니다.
      </Typography>
      <Typography mt={2} mb={0.5} fontWeight={600}>
        제20조 (개인정보보호)
      </Typography>
      <Typography>
        ① ‘회사’는 이용자의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서
        최소한의 개인정보를 수집합니다.
      </Typography>
      <Typography>
        ② ‘회사’는 회원가입 시 구매계약이행에 필요한 정보를 미리 수집하지
        않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이
        필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지
        아니합니다.
      </Typography>
      <Typography>
        ③ ‘회사’는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그
        목적을 고지하고 동의를 받습니다.
      </Typography>
      <Typography>
        ④ ‘회사’는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운
        이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
        이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
        다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
      </Typography>
      <Typography>
        ⑤ ‘회사’가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는
        개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의
        수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자,
        제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호
        등에 관한 법률」 제23조 제2항이 규정한 사항을 미리 명시하거나 고지해야
        하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
      </Typography>
      <Typography>
        ⑥ ‘회원’은 언제든지 ‘회사’가 가지고 있는 자신의 개인정보에 대해 열람 및
        오류정정을 요구할 수 있으며 ‘회사’는 이에 대해 지체 없이 필요한 조치를
        취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 ‘회사’는 그
        오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
      </Typography>
      <Typography>
        ⑦ ‘회사’는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를
        최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의
        개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한
        이용자의 손해에 대하여 모든 책임을 집니다.
      </Typography>
      <Typography>
        ⑧ ‘회사’ 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적
        또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
      </Typography>
      <Typography>
        ⑨ ‘회사’는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로
        설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의
        거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌
        개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등
        서비스 제공을 제한하거나 거절하지 않습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제21조 (‘회사’의 의무)
      </Typography>
      <Typography>
        ① ‘회사’는 법령과 이 약관이 금지하거나 공공의 질서와 선량한 풍속에
        반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
        안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.
      </Typography>
      <Typography>
        ② ‘회사’는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
        개인정보 (신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
      </Typography>
      <Typography>
        ③ ‘회사’가 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」
        제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는
        이를 배상할 책임을 집니다.
      </Typography>

      <Typography>
        ④ ’회사’는 본 ‘서비스’ 제공과 관련하여 수집한 ‘회원’의 개인정보를 해당
        ‘회원’의 동의 없이 제 3자에게 제공, 유출, 전송, 배포하지 않습니다. 단,
        전기통신사업법, 통신비밀보호법, 정보통신망 이용촉진 및 정보보호 등에
        관한 법률 등 관계법령에 따라 권한이 있는 자가 적법한 절차에 의거
        개인정보제공을 요구할 때에는 그렇지 아니합니다.
      </Typography>
      <Typography>
        ⑤ ‘회사’는 서비스 개선, 고객 만족도 향상 등의 목적으로 ‘회원’의 사전
        동의 없이 ‘회원’ 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여
        이를 사용할 수 있습니다.
      </Typography>
      <Typography>
        ⑥ ’회사’는 전기통신사업법, 통신비밀보호법, 정보통신망이용촉진 및
        정보보호 등에 관한 법률 등 본 서비스의 운영, 유지와 관련된 법규를
        지킵니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제22조 (회원의 ID 및 비밀번호에 대한 의무)
      </Typography>
      <Typography>
        ① 제20조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
        있습니다.
      </Typography>
      <Typography>
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
      </Typography>
      <Typography>
        ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을
        인지한 경우에는 바로 ‘회사’에 통보하고 ‘회사’의 안내가 있는 경우에는
        그에 따라야 합니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제23조 (‘이용자’의 의무)
      </Typography>
      <Typography mb={0.5}>이용자는 다음 행위를 하여서는 안됩니다.</Typography>
      <Typography>① 개인정보의 등록 또는 변경 시 허위내용의 등록</Typography>
      <Typography>② 타인 정보의 도용</Typography>
      <Typography>
        ③ ‘회사’가 운영하는 ‘프로그램’에 게시된 정보의 변경
      </Typography>
      <Typography>
        ④ ‘회사’ 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
      </Typography>
      <Typography>
        ⑤ ‘회사’ 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
      </Typography>
      <Typography>
        ⑥ ‘회사’ 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      </Typography>
      <Typography>
        ⑦ 외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를
        ‘회사’가 운영하는 ‘프로그램’에 공개 또는 게시하는 행위
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제24조(연결”몰”과 피연결“몰” 간의 관계)
      </Typography>
      <Typography>
        ① 상위“몰”과 하위“몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림
        및 동화상 등이 포함됨) 방식 등으로 연결된 경우, 전자를
        연결“몰”(웹사이트)이라고 하고 후자를 피연결”몰”(웹사이트)이라고 합니다.
      </Typography>
      <Typography>
        ② 연결”몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와
        행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결”몰”의 초기화면
        또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증
        책임을 지지 않습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제25조 (지식재산권의 귀속 및 이용제한)
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ⑤ ‘이용자’가 당사 ‘프로그램’에 등록 또는 게시한 게시물에 대한 모든
          책임은 작성자 본인에게 있으며, 회사는 회원의 게시물이 다음 각 항에
          해당한다고 판단되는 경우 사전통지 없이 해당 게시물의 게시중단 및 삭제,
          게시물 작성 권한 차단 등의 조치를 취할 수 있고, 이에 대하여 회사는
          어떠한 책임도 지지 않습니다.
        </Typography>
        <Typography ml={1}>
          가) 타인 및 업체, 상담 직원을 모욕/비방/비하하거나 타인 및 업체,
          상담직원의 명예를 훼손하는 게시물
        </Typography>
        <Typography ml={1}>
          나) 가격 비교, 비방 또는 욕설 등의 내용을 게시하여 판매자 등의 판매
          활동을 방해하는 게시물
        </Typography>
        <Typography ml={1}>
          다) 직/간접적인 제품의 재 판매 행위 및 이를 구매, 사용하도록 권하거나
          연락을 유도하는 등 당사의 운영정책에 위반되는 내용의 게시물
        </Typography>
        <Typography ml={1}>
          라) 범죄 및 각종 불법 행위에 악용될 수 있는 정보 및 링크를 담고 있는
          게시물
        </Typography>
        <Typography ml={1}>
          마) 타인의 저작권, 상표권 등 권리를 침해하는 게시물
        </Typography>
        <Typography ml={1}>
          바) 어린이 및 청소년에게 정신적 신체적 피해가 될 수 있는 내용 및
          음란물 또는 상담 직원 및 일반인의 성적 굴욕감이나 수치심을 유발할 수
          있는 내용 또는 링크 등을 포함한 게시물
        </Typography>
        <Typography ml={1}>사) 특정인의 개인정보가 노출된 게시물</Typography>
        <Typography ml={1}>
          아) 당사의 이용 목적에 부합하지 않거나 해당 서비스의 취지와 무관한
          내용의 게시물
        </Typography>
        <Typography ml={1}>
          자) 악성코드 등이 담긴 링크 등을 기재하여 다른 회원, 회사의 시스템
          성능 저하, 개인정보 유출 등의 피해를 줄 수 있는 악의적인 게시물
        </Typography>
        <Typography ml={1}>
          차) 정당한 사유 없이 회사의 영업을 방해하는 내용의 게시물
        </Typography>
        <Typography ml={1}>
          카) 고의적으로 회사 또는 판매자의 판매 활동을 방해하는 행위 (검증되지
          않은 내용의 제품 후기 등 포함 )
        </Typography>
        <Typography ml={1}>
          타) 법령에 위배되거나 회사가 부적절 하다고 판단되는 게시물
        </Typography>
        <Typography ml={1}>
          파) 기타 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로
          판단되는 경우
        </Typography>
      </Box>
      <Typography>
        ① ‘회사’가 작성한 저작물에 대한 저작권 기타 지식재산권은 ‘회사’에
        귀속합니다.
      </Typography>
      <Typography>
        ② 이용자는 ‘프로그램’을 이용함으로써 얻은 정보 중 ‘회사’에게
        지적재산권이 귀속된 정보를 ‘회사’의 사전 승낙 없이 복제, 송신, 출판,
        배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
        하여서는 안됩니다.
      </Typography>
      <Typography>
        ③ ‘이용자’가 ‘프로그램’의 서비스 이용 시 작성한 상품평, 상품 관련 질문
        등의 게시물에 대한 저작권은 ‘이용자’에게 귀속합니다. 단, ‘회사’는
        ‘이용자’에게 서비스를 제공하기 위하여, 해당 게시물에 대한 저장, 복제,
        수정, 공중 송신, 전지, 배포, 2차적 저작물 작성 등의 이용 권한(기한과
        지역 제한에 정함이 없으며, 별도 대가 지급이 없는 라이선스)을 부여
        받습니다. ‘회사’는 이러한 이용 권한에 따라, ‘회사’, ‘프로그램’에서
        저작권법 등 관련 법령에서 정하는 범위 내에서 ‘이용자’ 게시물의 노출,
        ‘회사’의 각종 전자상거래 관련 서비스(이하 “서비스”) 홍보를 위한 활용,
        서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구, 상품 관련 정보 통합
        관리 및 상품 마케팅을 위한 목적으로 활용할 수 있습니다.
      </Typography>
      <Typography>
        ④ ‘이용자’가 작성한 게시물( 댓글 및 1:1문의 등 작성하는 모든 글에 해당함
        )이 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
        산업 안전 보건법 등 관련 법령에 위반되는 내용을 포함하는 경우, 회사는
        관련 법령이 정한 지침 및 절차에 따라 게시물의 게시중단 및 삭제, 게시물
        작성 권한 차단, 회원가입 불가 등의 조치를 취할 수 있습니다.
      </Typography>
      <Typography>
        ⑥ ‘회사’는 개별 서비스별로 게시물과 관련된 세부이용지침을 별도로 정하여
        시행할 수 있으며, 회원은 그 지침에 따라 게시물(회원간 전달 포함)을 게재
        하여야 합니다.
      </Typography>
      <Typography>
        ⑦ ‘회사’는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해
        이용자에게 통보하여야 합니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제26조(면책)
      </Typography>
      <Typography>
        ① ‘회사’는 천재지변, 불가항력 기타 ‘회사’의 합리적인 통제범위를 벗어난
        사유로 인하여 ‘서비스’를 제공할 수 없는 경우에는 그에 대한 책임을
        부담하지 않습니다.
      </Typography>
      <Typography>
        ② ‘회사’는 이용자의 귀책사유로 인하여 ‘서비스’를 제공할 수 없는 경우에는
        그에 대한 책임을 부담하지 않습니다.
      </Typography>
      <Typography>
        ③ ‘회사’는 이용자가 ‘서비스’를 이용함으로써 기대되는 수익을 얻지
        못하거나 ‘서비스’를 통해 얻은 자료를 이용하여 발생한 손해에 대해서는
        책임을 부담하지 않습니다.
      </Typography>
      <Typography>
        ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는
        정확성에 대하여는 해당 이용자가 책임을 부담하며, ‘회사’는 내용의 부정확
        또는 허위로 인해 이용자 또는 제3자에게 발생한 손해에 대하여는 아무런
        책임을 부담하지 않습니다.
      </Typography>
      <Typography>
        ⑤ ‘회사’는 ‘서비스’ 이용과 관련하여 이용자의 고의 또는 과실로 인하여
        이용자 또는 제3자에게 발생한 손해에 대하여는 아무런 책임을 부담하지
        않습니다.
      </Typography>
      <Typography>
        ⑥ ’회사’는 ‘회원’과 제품 판매자 사이 혹은 ‘회원’과 광고주 사이에 발생한
        문제에 대하여는 관여하거나 아무런 책임을 지지 않습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제27조(계약의 해지 및 손해배상)
      </Typography>
      <Box mb={1}>
        <Typography mb={1}>
          ① ’회사’는 ‘회원’이 이용 약관을 준수하지 않거나 다음의 경우 사전 통보
          없이 해당 ‘회원’과의 계약을 해지하거나 ‘회원’ 자격을 중지 또는
          상실시킬 수 있습니다.
        </Typography>
        <Typography ml={1}>
          가. 타인의 개인정보 또는 휴대폰 번호, 기기 등을 도용하여 포인트 적립,
          회원가입 등의 활동을 한 경우.
        </Typography>
        <Typography ml={1}>
          나. ‘회사’의 ‘프로그램’을 변경, 해킹하거나 회사, 운영자 등을 사칭하는
          등의 방법으로 ‘회사’의 ‘서비스’를 방해하는 경우.
        </Typography>
        <Typography ml={1}>
          다. 허위사실 유포, 위계 등의 방법으로 회사의 명예 또는 신용을
          훼손하거나 업무를 방해한 경우. ⑥ ’회원’이 본 ‘서비스’를 이용 중 본
          ‘약관’을 위반 또는 불법 행위를 하여 ‘회사’가 해당 ‘회원’ 이외의
          제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의 제기를 받는다면
          해당 ‘회원’은 자신의 책임과 비용으로 ‘회사’를 면책시켜야 하며,
          ‘회사’가 면책되지 못하면 ‘회사’에 발생한 모든 손해를 배상해야 합니다.
        </Typography>
        <Typography ml={1}>
          라. 본 약관 23조 ‘이용자’의 의무를 위반한 경우.
        </Typography>
        <Typography ml={1}>
          마. 기타 본 약관을 위배하거나 법령에 위반되는 행위를 한 경우.
        </Typography>
      </Box>
      <Typography>
        ② ’회원’의 귀책사유로 계약이 해지된 경우 ‘회원’은 포인트를 비롯한 일체의
        권리를 상실하며 그에 대한 보상을 요구할 수 없습니다.
      </Typography>
      <Typography>
        ③ ’회사’는 제휴관계의 종료, 기타 ‘서비스’를 지속할 수 없는 중대한 사업적
        이유가 있는 경우에는 ‘회원’에 대한 손해배상 없이 장래의 ‘서비스’ 제공을
        중단할 수 있으며, 기존에 발생한 포인트의 경우에는 제12조 ‘서비스의 종료’
        의 조항에 따릅니다.
      </Typography>
      <Typography>
        ④ ’회원’은 언제라도 ‘프로그램’을 삭제하고 ‘회원’ 계약을 해지할 수
        있습니다. 다만 이 경우에 ‘회원’은 포인트를 비롯한 일체의 권리를 상실하며
        그에 대한 보상을 요구할 수 없습니다.
      </Typography>
      <Typography>
        ⑤ 본 ‘약관’의 규정을 위반하여 ‘회사’에 손해를 끼친 ‘회원’은 회사에
        발생한 모든 손해를 배상해야 합니다.
      </Typography>
      <Typography>
        ⑥ ’회원’이 본 ‘서비스’를 이용 중 본 ‘약관’을 위반 또는 불법 행위를 하여
        ‘회사’가 해당 ‘회원’ 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한
        각종 이의 제기를 받는다면 해당 ‘회원’은 자신의 책임과 비용으로 ‘회사’를
        면책시켜야 하며, ‘회사’가 면책되지 못하면 ‘회사’에 발생한 모든 손해를
        배상해야 합니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제28조 (서비스의 종료)
      </Typography>
      <Typography>
        ① ’회사’는 ‘서비스’를 종료하고자 할 때에는 종료 예정일로부터 3개월
        이전에 본 ‘약관’ 9조의 ‘회원에 대한 통지’에 따라서 ‘회원’들에게 공지
        하여야 합니다.
      </Typography>
      <Typography>
        ② 본 조 1항에 따른 ‘서비스’ 종료가 통지된 날(이하 ‘종료 통지일’) 이후
        ‘회사’는 ‘회원’의 포인트 적립 등의 ‘서비스’ 이용을 제한할 수 있습니다.
        또한 ‘회원’은 서비스 종료 예정일까지 보유 포인트를 소진해야 합니다. 종료
        예정일 이후로는 포인트가 자동 소멸되며 ‘회원’은 포인트에 대한 권리를
        주장할 수 없습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제29조 (분쟁해결)
      </Typography>
      <Typography>
        ① ‘회사’는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
        보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.
      </Typography>
      <Typography>
        ② ‘회사’는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을
        처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와
        처리일정을 즉시 통보해 드립니다.
      </Typography>
      <Typography>
        ③ ‘회사’와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의
        피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는
        분쟁조정기관의 조정에 따를 수 있습니다.
      </Typography>

      <Typography mt={2} mb={0.5} fontWeight={600}>
        제30조 (재판권 및 준거법)
      </Typography>
      <Typography>
        ① ‘회사’와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의
        이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의
        전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지
        않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </Typography>
      <Typography>
        ② ‘회사’와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
      </Typography>

      <Typography pt={5} pb={3} align="right">
        변경 공지일자 : 2022년 6월 1일
        <br />
        최초 공지일자 : 2020년 6월 1일
      </Typography>
    </ThemeProvider>
  </Box>
);
export const TermsOfProtectionOfPrivateInfo = () => (
  <Box p={1.2} pt={0}>
    <ThemeProvider theme={typographyTheme}>
      <Box mb={2.5}>
        <Typography mt={0} mb={1}>
          애즈워드㈜(이하 “회사”)는 『개인정보보호법』 등 관련 법령상의 개인정보
          보호규정을 준수하며, 『개인정보보호법』에 의거한 개인정보처리방침을
          통하여 이용자가 제공한 개인정보가 어떠한 용도와 방식으로 이용되고
          있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지를
          알려드립니다.
        </Typography>
        <Typography mt={0} mb={1}>
          본 개인정보처리방침은 관련 법률 및 지침의 변경 등에 따라 수시로 변경될
          수 있는바, 회사는 개인정보처리방침이 변경되는 경우 이용자가 쉽게
          알아볼 수 있도록 회사가 제공하는 제반 서비스(모바일 웹/앱 포함)의
          공지사항을 통해 게시됩니다.
        </Typography>
      </Box>
      <Typography mb={0.5} fontWeight={600}>
        1. 개인정보의 수집 및 이용목적
      </Typography>
      <Typography>
        ① 회사는 원활한 앱서비스(애즈워드) 제공을 위해 필요한 최소한의
        개인정보만을 이용자의 동의 하에 수집하고 있습니다.
        <br />※ 개인정보란 생존하는 개인에 관한 정보로서 특정 개인을 식별하거나
        식별할 수 있는 모든 정보.
      </Typography>
      <Typography mt={2.5} pl={1}>
        (1) 회사는 회원가입, 주문, 고객상담 등 각종 서비스의 제공을 위해 아래와
        같이 이용자의 개인정보를 수집합니다.
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">대상</TableCell>
              <TableCell align="center">수집시기</TableCell>
              <TableCell align="center">이용목적</TableCell>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">수집항목</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" rowSpan={5}>
                회원
              </TableCell>
              <TableCell align="left">가입</TableCell>
              <TableCell align="left">
                회원식별 및 상담, 부정이용 방지, 이벤트 등 마케팅 정보 활용 및
                통계 분석
              </TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                아이디, 이름, 휴대폰번호, 비밀번호, 이메일,{' '}
                <Typography variant="span" color="red" fontSize="0.8rem">
                  범용고유식별정보(UUID)
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">주문</TableCell>
              <TableCell align="left">
                결제 시 본인인증, 결제 및 배송, 현금영수증 발급
              </TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                이름, 생년월일, 본인명의,{' '}
                <Typography variant="span" color="red" fontSize="0.8rem">
                  중복가입확인정보(DI), 동일인식별정보(CI)
                </Typography>
                <Typography fontSize="0.8rem" color="grey.500">
                  *결제 정보(카드번호, 유효기간, 비밀번호 앞 2 자리),
                  주문/배송/거래내역, 주문자 정보(주문자명, 휴대폰), 배송지
                  정보(배송지명, 수령자명, 연락처, 주소)
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">설치</TableCell>
              <TableCell align="left">앱 서비스 이용자 식별</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                단말기 정보(단말기명, OS, 범용고유식별정보(UUID))
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" rowSpan={2}>
                포인트전환
              </TableCell>
              <TableCell align="left">포인트 현금전환</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                월1회 5,000포인트 이상 현금 전환시 개인 은행계좌번호
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">소득세신고</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                소득세 원천징수 신고시 필요한 주민등록번호 등<br />* 포인트전환
                월1회 50,000포인트 이상부터 적용
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography color="#777">
        * 결제정보(카드번호, 유효기간, 비밀번호 앞2자리)는 수집 후 즉시 암호화
        처리 되어 PG사로 전송되는 방식으로 회사는 상세 내용을 확인할 수
        없습니다.
      </Typography>
      <Typography mt={2.5} pl={1}>
        (2) 회사는 입점/제휴 문의 등을 통해 업체로부터 다음의 정보를 수집합니다.
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">대상</TableCell>
              <TableCell align="center">수집시기</TableCell>
              <TableCell align="center">이용목적</TableCell>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">수집항목</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" rowSpan={2}>
                업체
              </TableCell>
              <TableCell align="left" rowSpan={2}>
                문의
              </TableCell>
              <TableCell align="left" rowSpan={2}>
                입점/제휴 또는 대량 구매 문의를 통해 계약 이행
              </TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">담당자명, 전화번호</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">선택</TableCell>
              <TableCell align="left">이메일, 문의 내용</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography mb={1} pl={1}>
        (3) 회사는 서비스 이용 과정에서 자동으로 생성된 다음과 같은 정보를
        수집합니다.
      </Typography>
      <Typography pl={2.5} color="red">
        - IP Address, 쿠키, 방문 일시, 서비스 이용 기록 등
      </Typography>
      <Typography pl={2}>
        ② 회사는 이용자로부터 수집한 개인정보를 본 개인정보 처리방침에 명시한
        목적 이외의 용도로 사용하지 않으며, 이용자의 동의 없이 제3자에게
        제공하지 않습니다.
      </Typography>
      <Typography pl={2}>
        ③ 회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(사상,
        신념, 가족 및 친인척관계, 학력, 병력, 기타 사회활동 경력 등 개인의
        권리∙이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보)를 수집하지
        않습니다.
      </Typography>
      <Typography pl={2}>
        ④ 회사는 다음과 같은 방법으로 개인정보를 수집할 수 있습니다.
      </Typography>
      <Box my={0.7}>
        <Typography pl={3}>
          (1) 홈페이지, 웹/앱을 통한 동의절차 ('동의함' 또는 '확인' 버튼을
          클릭하거나 정보를 입력하고{' '}
          <Typography variant="span" color="red">
            '내정보 관리 &lt; 기본정보 수정' 등의 버튼을 클릭하면 개인정보수집에
            동의한 것으로 봅니다)
          </Typography>
        </Typography>
        <Typography pl={3}>
          (2) 서면양식, 전화, 팩스를 통한 회원 가입 및 상담 게시판, 경품행사
          응모, 배송요청
        </Typography>
        <Typography pl={3}>
          (3) 로그 분석 프로그램을 통한 생성정보 수집
        </Typography>
      </Box>
      <Typography pl={2}>
        ⑤ 회사는 모바일 어플리케이션(애즈워드)의 서비스 제공과정에서 아래와 같이
        단말기의 접근권한을 이용자의 동의를 받아 수집 또는 이용합니다. 접근권한
        중 선택항목의 경우, 거부하셔도 서비스 이용이 가능하며,{' '}
        <Typography variant="span" color="red">
          앱 내 설정을 통해 변경하실 수 있습니다.
        </Typography>
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">접근권한</TableCell>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">필요 사유</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">범용고유식별정보 (UUID)</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                사용자 식별 정보 확인, 서비스 최적화 및 오류 확인
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">카메라 (UUID)</TableCell>
              <TableCell align="left">선택</TableCell>
              <TableCell align="left">
                상품후기,상품문의(포토/동영상) 촬영/업로드
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">범용고유식별정보 (UUID)</TableCell>
              <TableCell align="left">선택</TableCell>
              <TableCell align="left">
                상품후기,상품문의(포토/동영상) 업로드
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography mb={0.5} fontWeight={600}>
        2. 개인정보 수집 및 이용에 대한 동의
      </Typography>
      <Typography>
        ① 회사는 개인정보 수집 시 수집되는 개인정보의 항목 및 수집이용 목적을
        이용자가 명확하게 인지할 수 있도록 이용자에게 고지하고 동의를 받는
        절차를 마련하고 있습니다.{' '}
        <Typography variant="span" color="red">
          이용자는 동의, 정보변경 등의 버튼을 클릭
        </Typography>
        하거나 회사가 이용자에게 알린 동의사항에 대하여 구두로 동의의 의사를
        표시함으로써 개인정보 수집 및 이용에 대해 동의할 수 있습니다. 다만,
        아래의 경우에는 개인정보 수집 및 이용에 대한 이용자의 사전 동의를 구하지
        않을 수 있습니다.
      </Typography>
      <Typography pl={1}>
        (1) 정보통신서비스 제공에 관한 계약을 이행하기 위하여 필요한
        개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
        곤란한 경우
      </Typography>
      <Typography pl={1}>
        (2) 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우
      </Typography>
      <Typography pl={1}>(3) 법률에 특별한 규정이 있는 경우</Typography>
      <Typography>
        ② 애즈워드 어플리케이션은 회원으로 가입 완료하고 로그인을 마친 ‘회원’을
        대상으로 운영하며, 로그인을 마친 이용자는 개인정보 수집 및 이용에 대한
        동의를 거부할 권리가 있으며 동의를 거부하더라도 별도의 불이익을 받지
        않습니다. 단, 필수동의사항에 대한 동의를 거부할 경우 서비스의 이용이
        불가능하며, 선택동의사항에 거부할 경우에는 일부 서비스(상품배송, 구매 및
        결제 등)의 이용이 제한될 수 있습니다.
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">수집이용목적</TableCell>
              <TableCell align="center" colSpan={2}>
                수집항목
              </TableCell>
              <TableCell align="center">보유기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" rowSpan={4}>
                대금 결제/환불 서비스 제공, 주문/배송/거래내역조회 서비스 제공,
                전자상거래법 준수
              </TableCell>
              <TableCell align="left">주문자 정보</TableCell>
              <TableCell align="left">이름 연락처</TableCell>
              <TableCell align="left" rowSpan={5}>
                이용 목적 달성 시까지 또는 관계 법령에 의한 보존 필요 시까지
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">신용카드 결제 시</TableCell>
              <TableCell align="left">카드사명, 카드번호</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">휴대전화 결제 시</TableCell>
              <TableCell align="left">
                휴대폰번호, 통신사, 결제승인번호
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">계좌이체 시</TableCell>
              <TableCell align="left">은행명, 계좌번호</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" rowSpan={4}>
                상품배송(반품/환불/취소)등, 배송지 확인, 최초 입력 후 불러오기
                기능 제공
              </TableCell>
              <TableCell align="left">배송지 정보</TableCell>
              <TableCell align="left">
                이름, 주소, 우편번호, 휴대폰번호
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography color="#777">
        ※ 이용계약(이용약관)이 존속 중인 탈퇴하지 않은 회원의 경우 보유기간은
        보존의무기간 이상으로 보관할 수 있으며, 이 기간이 경과된 기록에 대해서
        파기 요청이 있는 경우 파기합니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        3. 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항
      </Typography>
      <Typography>
        ① 회사는 원활한 서비스의 제공을 위하여 '쿠키(cookie)'를 사용합니다.
        쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 브라우저에게
        보내는 소량의 정보입니다.
      </Typography>
      <Typography>
        ② 회사는 쿠키를 통해 이용자의 접속 빈도나 방문시간을 분석하고 이용자의
        취향과 관심 분야를 파악하여 타겟(Target)마케팅 및 서비스 개편 등의
        척도로 활용합니다. 또한 각종 이벤트에서 이용자의 참여 정도 및 방문
        횟수를 파악하여 응모 기회를 부여하고, 개인의 관심분야에 따라 차별화된
        정보를 제공하기 위한 자료로 사용됩니다.
      </Typography>
      <Typography>
        ③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹 브라우저에서
        옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을
        거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. <br />
        <br />
        다만, 모든 쿠키의 저장을 거부할 경우, 쿠키를 통해 회사에서 제공하는
        서비스 이용에 어려움이 발생할 수 있습니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        4. 개인정보 제3자 제공
      </Typography>
      <Typography>
        ① 회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않으나, 계약
        이행을 위한 서비스 제공 등에 관한 이용자의 동의가 있거나, 법령에 의하여
        정해진 절차에 따라 정보를 요청 받은 경우는 예외로 하며, 이 경우 법령에서
        정한 최소한의 개인정보를 이용 및 제공할 수 있습니다.
      </Typography>
      <Typography>
        ② 회사는 법령에 정해진 책임의 준수를 위해 보관하고 있는 개인정보를
        제3자에 제공하는 내역은 아래와 같으며, 이용자가 사전에 동의하지 않은
        경우, 제공하지 않습니다
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">제공받는자</TableCell>
              <TableCell align="center">제공목적</TableCell>
              <TableCell align="center">제공항목</TableCell>
              <TableCell align="center">보유 및 이용기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">국내배송 상품 업체</TableCell>
              <TableCell align="left">
                판매자와 구매자 간 원활한 거래의 진행(구매자확인, 해피콜 등),
                고객상담 및 불만처리, 서비스 제공, 상품과 경품 배송(전송 및 설치
                등 포함)
              </TableCell>
              <TableCell align="left">
                수취인정보(이름, 휴대전화번호, 주소)
              </TableCell>
              <TableCell align="left">
                이용 목적 달성 시 까지 또는 관계법령에 따른 보존기간까지
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography>
        ③ 이용자는 제3자에 대한 개인정보 제공 동의를 거부할 수 있으며, 동의 거부
        시 해당 서비스 또는 이벤트 등의 이용에 제한이 있을 수 있습니다.
      </Typography>
      <Typography>
        ④ 이용자는 정보를 회사로부터 개인정보를 제공받은 제3자의 확인을 요청 수
        있으며 회사는 이용자의 요청이 접수되는 경우 개인정보를 제공받은 자의
        정보를 이용자에게 안내합니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        5. 개인정보의 처리위탁
      </Typography>
      <Typography>
        ① 회사는 원활한 서비스의 제공을 위하여 다음과 같이 개인정보 처리 업무를
        위탁하고 있습니다.
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">수탁업체</TableCell>
              <TableCell align="center">위탁 업무의 내용</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">토스페이먼츠(주)</TableCell>
              <TableCell align="left">
                신용카드, 체크카드, 결제승인/매입업무
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">㈜채널코퍼레이션</TableCell>
              <TableCell align="left">
                문자 발송, 카카오 알림톡 발송 업무
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">(주)카카오</TableCell>
              <TableCell align="left">카카오 알림톡 발송 업무</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography>
        ② 위탁 처리하는 정보는 서비스를 제공하기 위한 최소한의 정보에 국한되며,
        위탁 계약 체결 시 개인정보보호법에 제25조에 따라 위탁업무 수행목적 외
        개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한
        관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고,
        수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </Typography>
      <Typography>
        ③ 수탁자, 수탁 범위 등이 변경되는 경우에는 지체 없이 본
        개인정보처리방침을 통해 공지합니다.
      </Typography>

      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        6. 개인정보의 보유 및 이용기간
      </Typography>
      <Typography>
        ① 회사는 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 수집·이용
        목적이 달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지 해당 정보를
        보유합니다. 다만, 아래의 사유로 인하여{' '}
        <Typography variant="span" color="red">
          보관이 필요한 경우 외부와 차단된 별도의 DB 또는 테이블에 분리
          보관됩니다.
        </Typography>
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">대상</TableCell>
              <TableCell align="center">이용목적</TableCell>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">수집항목</TableCell>
              <TableCell align="center">보유기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" rowSpan={4}>
                회원
              </TableCell>
              <TableCell align="left">
                회원식별 및 상담, 부정이용 방지
              </TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                아이디, 이름, 휴대폰번호, 비밀번호, 이메일,{' '}
                <Typography variant="span" color="red">
                  범용고유식별정보(UUID)
                </Typography>
              </TableCell>
              <TableCell align="left">
                탈퇴 시 재가입 방지기간인 12개월
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">결제 및 배송, 현금영수증 발급</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                *결제 정보(카드번호, 유효기간, 비밀번호 앞 2 자리),
                주문/배송/거래내역, 주문자 정보(주문자명, 휴대폰), 배송지
                정보(배송지명, 수령자명, 연락처, 주소)
              </TableCell>
              <TableCell align="left">
                이용 목적 달성 시까지 또는 관계 법령에 의한 보존 필요 시까지
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">결제 시 본인 인증</TableCell>
              <TableCell align="left">필수</TableCell>
              <TableCell align="left">
                이름, 생년월일, 본인명의,{' '}
                <Typography variant="span" color="red">
                  중복가입확인정보(DI), 동일인식별정보(CI)
                </Typography>
              </TableCell>
              <TableCell align="left">
                이용 목적 달성 시까지 또는 관계 법령에 의한 보존 필요 시까지
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                각종 이벤트 등 마케팅 정보 활용 및 통계 분석
              </TableCell>
              <TableCell align="left">선택</TableCell>
              <TableCell align="left">
                휴대전화번호, 이메일주소,{' '}
                <Typography variant="span" color="red">
                  범용고유식별정보(UUID)
                </Typography>
              </TableCell>
              <TableCell align="left">
                이용 목적 달성 시까지 또는 관계 법령에 의한 보존 필요 시까지
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography color="red">
        * 결제정보(카드번호, 유효기간, 비밀번호 앞2자리)는 수집 후 즉시 암호화
        처리되어 PG사로 전송되는 방식으로 회사는 상세 내용을 확인할 수 없습니다.
      </Typography>
      <Typography mt={2.5}>② 관련 법령에 의한 개인정보 보유</Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">보존항목</TableCell>
              <TableCell align="center">근거법령</TableCell>
              <TableCell align="center">보존기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                계약 또는 청약철회 등에 관한 기록
              </TableCell>
              <TableCell align="left" rowSpan={4}>
                전자상거래 등에서의 소비자 보호에 관한 법률
              </TableCell>
              <TableCell align="left">5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                대금결제 및 재화 등의 공급에 관한 기록
              </TableCell>
              <TableCell align="left">5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                소비자의 불만 또는 분쟁처리에 관한 기록
              </TableCell>
              <TableCell align="left">5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">표시/광고에 관한 기록</TableCell>
              <TableCell align="left">6 개월</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                세법이 규정하는 모든 거래에 관한 장부 및 증빙서류
              </TableCell>
              <TableCell align="left">국세기본법</TableCell>
              <TableCell align="left">5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">전자금융 거래에 관한 기록</TableCell>
              <TableCell align="left">전자금융거래법</TableCell>
              <TableCell align="left">5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                서비스 방문 기록
                <br />
                (접속 IP 정보, Mac address, PC 방문 일시, 스마트기기 접속 IP
                정보, 스마트기기 Mac address, 스마트기기 방문 일시)
              </TableCell>
              <TableCell align="left">통신비밀보호법</TableCell>
              <TableCell align="left">3 개월</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
      <Typography>
        ③ 회사는 회원에서 탈퇴한 후 재가입, 임의 해지 등을 반복적으로 행하여
        "애즈워드"에서 제공하는 할인쿠폰, 이벤트 혜택 등으로 경제상의 이익을
        취하는 편법과 불법행위를 하는 회원을 차단하고자 탈퇴 회원에 대한 정보를
        12개월 동안 보관합니다.
      </Typography>
      <Typography>④ 개인정보 유효기간제(휴면계정 정책)</Typography>
      <Typography pl={1}>
        (1) 1년 이상 서비스 이용기록이 없는 이용자의 회원가입정보는
        개인정보보호법 제21조에 따라 휴면 계정으로 전화되며 일반 이용자의
        회원가입정보와 분리하여 별도로 저장 및 관리됩니다.
      </Typography>
      <Typography pl={1}>
        (2) 회사는 휴면계정으로 전환되는 시점 최소 30일 이전, 해당 내용에 대해
        이메일 등을 통해 이용자에게 사전 통지합니다.
      </Typography>
      <Typography pl={1}>
        (3) 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를
        제외하고 해당 개인정보를 이용·제공하지 않습니다.
      </Typography>
      <Typography pl={1}>
        (4) 주문 및 결제, CS 정보는 관련 법령에 의한 개인정보 보유기간 동안 보관
        후 삭제됩니다.
      </Typography>

      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        7. 개인정보 이용내역 통지
      </Typography>
      <Box>
        ① 회사는 개인정보처리방침을 기초로 하여 개인정보의 수집·이용,
        제공·위탁한 내역을 이용자에게 통지합니다.
        <Typography pl={1}>
          (1) 통지 목적
          <br /> 회사에서 수집한 개인정보 이용내역을 해당 이용자에게 주기적으로
          통지함으로써 이용자는 자신의 개인정보를 누구에게 어느 범위까지 알리고
          또 이용되도록 할 것인지를 스스로 결정할 수 있습니다.
        </Typography>
        <Typography pl={1}>
          (2) 통지 항목 <br />
          - 개인정보를 수집·이용한 목적 및 수집한 개인정보 항목 <br />
          - 개인정보를 제공 받은 자, 제공한 목적, 제공한 개인정보 항목 <br />-
          개인정보를 처리위탁 받은 자, 그 처리위탁 목적(업무 내용 등)
        </Typography>
        <Typography pl={1} color="grey.500" fontSize="0.8rem">
          ※ 통지 항목은 개인정보처리방침에 포함된 사항이므로 해당 내용을 기초로
          하여 개인정보의 수집·이용 목적 및 항목과 해당 목적 내에서 실제로
          제공·위탁한 내역을 통지하며, 개별적인 건별 이용내역은 통지항목에서
          제외됩니다.
        </Typography>
        <Typography pl={1}>(3) 통지 주기 : 연 1회</Typography>
        <Typography pl={1}>(4) 통지 방법 : 이메일</Typography>
        <Typography pl={1}>
          (5) 통지 예외 『통신비밀보호법』 제13조, 제13조의2, 제13조의4 및
          『전기통신사업법』 제83조제3항에 따라 제공한 정보, 개별 법률에서
          별도의 통지를 규정하고 있는 수사기관에 대한 정보 제공은 이용내역 통지
          대상에서 제외됩니다.
        </Typography>
      </Box>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        8. 개인정보 파기절차 및 방법
      </Typography>
      <Typography>
        ① 파기절차 및 기한
        <br />
        이용자의 개인정보는 원칙적으로 개인정보 보유기간의 경과 및 이용목적이
        달성되면 지체 없이 파기 합니다. 다만, 『6. 개인정보의 보유 및 이용기간』
        에서 명시한 다른 법령에 의해 보관해야 하는 경우{' '}
        <Typography variant="span" color="red">
          별도의 DB에 옮겨져
        </Typography>{' '}
        내부 규정 및 관련 법령을 준수하여 일정기간 동안 안전하게 보관된 후 지체
        없이 파기됩니다. 이때 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고
        다른 목적으로 이용하지 않습니다.
      </Typography>
      <Typography>
        ② 파기방법
        <br />
        전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여
        완전하게 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
        통하여 파기합니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        9. 정보주체와 법정대리인의 권리·의무 및 행사방법
      </Typography>
      <Typography>
        ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
        등의 권리를 행사 할 수 있습니다.
      </Typography>
      <Typography>
        ② 제1항에 따른 권리행사는 회사에 대해 개인정보보호법 시행령
        제41조제1항에 따라 서면, 전자 우편, FAX 등을 통하여 하실 수 있으며,
        회사는 이에 대해 지체 없이 조치를 취하고 그 사실을 해당 정보주체에게
        통지합니다.
      </Typography>
      <Typography>
        ③ 제1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등
        대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지
        제11호 서식에 따른 위임장을 제출 하셔야 합니다.
      </Typography>
      <Typography>
        ④ 정보주체 권리행사 시 요구를 한 자가 정보주체 본인이거나 정당한
        대리인임을 확인하며, 만 14세 미만의 아동의 경우, 법정대리인이 아동의
        개인정보를 조회하거나 수정 및 삭제, 처리정지, 수집 및 이용 동의를 철회할
        권리를 가집니다.
      </Typography>
      <Typography>
        ⑤ 정보주체 및 법정대리인이 개인정보의 오류에 대한 정정을 요청하신
        경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
        않습니다.
      </Typography>
      <Typography>
        ⑥ 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
        제3자에게 지체 없이 통지하여 개인정보의 정정 결과를 반영합니다.
      </Typography>
      <Typography>
        ⑦ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
      </Typography>
      <Typography>
        ⑧ 회사는 정보주체 혹은 법정대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 『6. 개인정보 의 보유 및 이용기간』에 명시된 바에 따라
        처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600} color="red">
        10. 개인정보보호를 위한 기술적 및 관리적 대책
      </Typography>
      <Typography>
        ① 회사는 이용자의 개인정보를 보호하기 위해 기술적 대책과 관리적 대책을
        마련하고 있으며, 이를 적용하고 있습니다.
      </Typography>
      <Typography>② 기술적 대책</Typography>
      <Typography pl={1}>
        (1) 회사는 이용자의 개인정보를 처리할 때 개인정보가 분실, 도난, 유출,
        변조 또는 훼손되지 않도록 다음과 같은 기술적 대책을 마련하여 개인정보의
        안전성을 확보하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (2) 이용자의 개인정보는 비밀번호(Password)로 보호되며 중요한 데이터는
        파일 및 전송데이터를 암호화하 거나 파일 잠금기능 (Lock)을 사용하여
        중요한 데이터는 별도의 보안기능으로 보호됩니다.
      </Typography>
      <Typography pl={1}>
        (3) 회사는 백신프로그램을 이용하여 컴퓨터 바이러스의 피해를 방지하는
        조치를 하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 바이러스가
        갑자기 출현하면 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는
        것을 방지하고 있습니다.
      </Typography>
      <Typography pl={1}>
        <Typography color="red" variant="span">
          (4)
        </Typography>{' '}
        회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할
        수 있는 보안장치(SSL)를 채택하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (5) 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템과 취약점
        분석시스템 등을 이용하여 보안에 최선을 다하고 있습니다.
      </Typography>
      <Typography>③ 관리적 대책</Typography>
      <Typography pl={1} mt={1}>
        (1) 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로
        제한하고 있으며 이에 해당하는 자는 다음과 같습니다.
        <br />
        - 이용자를 직접 상대로 하여 고객 민원처리 업무를 수행하는 자<br />
        - 개인정보보호책임자와 담당자 등 개인정보보호업무를 수행하는 자<br />-
        기타 업무상 개인정보의 처리가 불가피한 자
      </Typography>
      <Typography pl={1}>
        (2) 회사는 이용자의 개인정보에 대한 접근과 관리에 필요한 절차 등을
        마련하여 소속 직원으로 하여금 이를 숙지하고 지키도록 하고 있으며,
        개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인
        정보보호 의무 등에 관해 정기적인 사내 교육과 외부 위탁교육을 시행하고
        있습니다.
      </Typography>
      <Typography pl={1}>
        (3) 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서
        철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을
        명확히 하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (4) 회사는 컴퓨터를 이용하여 이용자의 개인정보를 처리할 때에는
        개인정보에 접근권한이 있는 담당자를 지정하여 담당자에게 식별부호(ID)와
        비밀번호(Password)를 부여하고, 해당 비밀번호(Password)를 정기적으로
        갱신하도록 하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (5) 회사는 신규로 채용된 직원에게 정보보호서약서 또는
        개인정보보호서약서에 서명하게 하여 직원에 의한 정보유출을 미리 방지하고
        있으며, 개인정보처리방침에 대한 이행사항과 직원의 준수여부를 감사하기
        위한 내부절차를 마련하여 지속적으로 시행하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (6) 회사는 직원 퇴직 시 비밀유지서약서에 서명함으로 이용자의 개인정보를
        처리하였던 자가 직무상 알게 된 개인정보를 훼손·침해 또는 누설하지 않도록
        하고 있습니다.
      </Typography>
      <Typography pl={1}>
        (7) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는
        일들에 대해 책임을 지지 않 습니다. 이용자는 본인의 개인정보를 보호하기
        위해서 자신의 아이디(ID) 와 비밀번호(Password)를 적절하게 관리하고 그에
        대한 책임을 져야 합니다.
      </Typography>
      <Typography>
        ④ 그 밖에 내부 관리자의 실수나 기술관리 상의 사고로 이용자의 개인정보가
        상실·유출·변조·훼 손되면 회사는 즉각 정보주체에게 해당 사실을 알리고
        적절한 대책과 보상을 마련합니다.
      </Typography>

      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        11. 아동의 개인정보보호
      </Typography>
      <Typography>
        회사는 만 14세 미만 아동의 개인정보를 보호하기 위하여 회원가입 및
        상품구매는 만 14세 이상에만 허용하여 아동의 개인정보를 수집하지
        않습니다.
      </Typography>

      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        12. 의견수렴 및 불만처리
      </Typography>
      <Typography>
        ① 회사는 이용자의 의견을 소중하게 생각하며, 원활한 소통을 위해
        고객센터를 운영하고 있습니다. 문의사항이 있을 경우 아래의 연락처로
        문의하시면 신속하게 답변을 드리겠습니다.
      </Typography>
      <ThemeProvider theme={tableTheme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">연락처</TableCell>
              <TableCell align="center">서비스시간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">고객센터</TableCell>
              <TableCell align="left">070-7954-4320</TableCell>
              <TableCell align="left" rowSpan={3}>
                전화 및 카톡상담은 평일 오전 9시30분부터 오후 6시까지 (토요일,
                일요일, 공휴일은 휴무) 우편접수는 24시간 가능
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">APP</TableCell>
              <TableCell align="left">
                <Typography variant="span" color="red">
                  MY페이지&gt;카톡문의
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">우편</TableCell>
              <TableCell align="left">
                서울시 중구 한강대로416 서울스퀘어 14층
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>

      <Typography>
        ※ 접수된 문의사항은 24시간 내에 성실하게 답변 드리겠습니다. 다만,
        근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로
        합니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        13. 개인정보보호 책임자
      </Typography>
      <Typography>
        ① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </Typography>
      <Typography pl={1} my={1}>
        ▶ 개인정보보호책임자 : 고영남
        <br />
        ▶ 전화번호 : 070-7954-4320
        <br />▶ 이메일 : info@adsward.co.kr
      </Typography>
      <Typography>
        ② 개인정보에 관한 불만처리 및 피해구제 등 상담이 필요한 경우에는 아래
        기관으로 문의하실 수 있습니다.
      </Typography>
      <Typography mt={2.5} mb={0.5} fontWeight={600}>
        14. 고지의 의무
      </Typography>
      <Typography>
        ① 현 개인정보처리방침은 2022년 06월 01일에 개정되었으며 정부의 정책 또는
        보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소
        7일 전부터 공지합니다.
      </Typography>
      <Typography align="right" pt={3} pb={1} fontSize="0.8rem">
        ▶ 개인정보처리방침 시행일자 : 2022년 06월 01일
      </Typography>
    </ThemeProvider>
  </Box>
);

export const terms = [
  {
    title: '이용약관',
    detail: <TermsOfService />,
  },
  {
    title: '개인정보보호정책',
    detail: <TermsOfProtectionOfPrivateInfo />,
  },
];

const typographyTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'Noto Sans KR', sans-serif",
          fontSize: '0.9rem',
          fontColor: 'common.black',
          lineHeight: '1.2rem',
        },
      },
    },
  },
});

const tableTheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: '24px',
          marginBottom: '24px',
          color: '#777',
          border: '1px solid',
          borderColor: '#777',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: '1px solid',

          borderColor: '#777',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px',
          fontSize: '0.8rem',
          border: '1px solid',
          borderColor: '#777',
        },
      },
    },
  },
});
