import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        fontFamily: "'Noto Sans KR', sans-serif",
      },
    },
  },
  palette: {
    background: {
      default: 'f5f5f5',
    },
    common: {
      black: '#131313',
      white: '#fff',
    },
    primary: {
      main: '#FF203B',
    },
  },
  grey: {
    50: '#fafafa',
    200: '#C5C5C5',
    300: '#202020',
    500: '9e9e9e',
    600: '#757575',
  },
  text: {
    // fontFamily: ["'Noto Sans KR', sans-serif"],
    // primary: '#FF203B',
    // secondary: '#767676',
    // third: '#8D1413',
    //     블랙 #131313
    // 챠콜 #393939
  },

  typography: {
    fontFamily: ["'Noto Sans KR', sans-serif"],
    grey: {
      50: '#fafafa',
      200: '#C5C5C5',
      300: '#202020',
      500: '9e9e9e',
      600: '#757575',
    },
  },
});

export default theme;
