import React, { useEffect, useState } from 'react';
import { fetchTNKCategoryList } from 'api/otherShop';
import {
  Box,
  createTheme,
  Grid,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import {
  getTNKListItems,
  getTNKListItemsByCategory,
  updateShopList,
  initShopList,
} from 'store/otherShop';

import PageLayout from 'components/layout/PageLayout';
import Divider from 'components/Divider';
import ScrollTop from 'components/ScrollTop';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ExtraProfit() {
  const dispatch = useDispatch();

  const TNKList = useSelector(state => state?.otherShop.TNKList);
  const shopList = useSelector(state => state?.otherShop.shopList);

  const [categories, setCategories] = useState();
  const [activeCategoryName, setActiveCategoryName] = useState('전체');
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(async () => {
    const data = await fetchTNKCategoryList();
    setCategories(data);

    dispatch(initShopList());
  }, []);

  useEffect(() => {
    setPage(1);

    if (activeCategoryName === '전체') {
      dispatch(getTNKListItems(1));
    } else {
      dispatch(
        getTNKListItemsByCategory({ category: activeCategoryName, page: 1 }),
      );
    }
  }, [activeCategoryName]);

  useEffect(() => {
    if (activeCategoryName === '전체') {
      dispatch(getTNKListItems(page));
    } else {
      dispatch(
        getTNKListItemsByCategory({ category: activeCategoryName, page: page }),
      );
    }
  }, [page]);

  useEffect(() => {
    addList(TNKList);
  }, [TNKList]);

  useEffect(() => {
    if (!categories) return;
    dispatch(getTNKListItems(1));
  }, [categories]);

  const handleChange = (e, value) => {
    setTabValue(value);
  };

  const handleOnClickCategory = name => {
    if (name === activeCategoryName) return;
    dispatch(initShopList());
    setActiveCategoryName(name);
    setPage(1);
  };
  const addList = items => {
    const same = items?.find(item => {
      const result = shopList?.find(data => data.appId === item.appId);
      return result;
    });
    if (same) return;

    const newList = [...shopList, ...items];
    dispatch(updateShopList(newList));
  };

  const changePage = () => {
    setPage(prev => prev + 1);
  };

  return (
    <PageLayout title="추가수익" sx={{ minHeight: 'calc(100vh - 200px)' }}>
      <ScrollTop />

      <ThemeProvider theme={theme}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          aria-label="category tab"
          sx={{ mb: 1 }}
        >
          {categories?.map((item, idx) => (
            <Tab
              key={idx}
              label={item}
              value={idx}
              onClick={() => {
                handleOnClickCategory(item);
              }}
            />
          ))}
        </Tabs>
      </ThemeProvider>
      <Box mb={3}>
        <InfiniteScroll
          dataLength={shopList?.length}
          next={changePage}
          hasMore={TNKList?.length !== 0}
          // loader={<Typography align="center">불러오는 중</Typography>}
        >
          {shopList?.map(item => (
            <React.Fragment key={item.appId}>
              <Box p={2}>
                <Grid
                  container
                  spacing={1.6}
                  py={1.8}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => (window.location.href = item.clickUrl)}
                >
                  <Grid item xs={3}>
                    <img
                      src={`data:image/*;base64,${item.thumbnail}`}
                      alt={item.appName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography fontWeight="600" fontSize="1.05rem">
                      {item.appName}
                    </Typography>
                    <Typography color="grey.500">
                      {item.appDescription}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        color="red"
                        fontWeight="600"
                        fontSize="0.9rem"
                        border="1px solid red"
                        borderRadius="3px"
                        px={0.65}
                        mr={1}
                        mt={0.3}
                      >
                        {item.adType}
                      </Typography>
                      <Typography color="red" fontWeight="900">
                        {item.pointAmount}P
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ borderColor: 'grey.300' }} />
            </React.Fragment>
          ))}
        </InfiniteScroll>
      </Box>
    </PageLayout>
  );
}
const theme = createTheme({
  components: {
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: {
          paddingLeft: '15px',
          backgroundColor: '#FAFBFD',
        },
        scroller: {
          display: 'flex',
        },
        flexContainer: {
          alignItems: 'center',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '10px 10px',
          font: 'normal normal medium 12px/18px Noto Sans CJK KR',
          color: '#191919',
          minWidth: '50px',
          minHeight: '0',
          borderRadius: 20,

          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#191919',
          },
        },
      },
    },
  },
});
