
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpenBox } from 'store/app';

import { Grid, IconButton, Typography, Button } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { parseStringToPrice } from 'utils';
import { useNavigate } from 'react-router';
import { setOrderDetail } from 'store/order';
import { setOrderCartItems } from 'store/cart';

export default function OrderSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth.user);
  const disableOrder = useSelector(state => state?.order.disableOrder);
  const textOptionValue = useSelector(state => state?.mall.textOptionValue);


  // currentProduct, product 두 개 같은건데 엮인게 많아서 다 고치기 힘들어서 일단 두 개로 해놓는다 추후에 고치자
  const currentProduct = useSelector(state => state?.coupon.coupon);
  const product = useSelector(state => state?.coupon.coupon);


  // 가격 계산
  const calculateAmount = () => {
    let additionalOptionAmount = 0;
    let otherAmount = 0;

    // 필수 옵션, 텍스트 옵션, 옵션 없음
    otherAmount = currentProduct.price;
    // 기타옵션

    const amountSum = additionalOptionAmount + otherAmount;
    return { amountSum, additionalOptionAmount };
  };


  const handleOrder = e => {
      dispatch(setOrderCartItems(false));
    if (disableOrder) {
      alert('옵션을확인해주세요.');
      return;
    }
    const { amountSum, additionalOptionAmount } = calculateAmount();

    const detail = {
      hasInfo: true,
      customerName: user.name,
      itemName: currentProduct.name,
      price: currentProduct.price,
      itemId: currentProduct.productId,
      itemCount: 1,
      amount: amountSum,
      usePoint: 0,
      textOption: textOptionValue,
      additionalFee: additionalOptionAmount,
      shippingFee: 0,
    };

    dispatch(setOrderDetail(detail));

    e.preventDefault();
    navigate(`/couponPayment`);
  };

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid
        item
        sx={{
          position: 'sticky',
          top: 0,
          height: '40px',
          width: '100%',
          textAlign: 'center',
          backgroundColor: 'common.white',
        }}
      >
        <IconButton
          size="small"
          onClick={e => {
            dispatch(
              setIsOpenBox({
                name: 'order',
                value: false,
              }),
            );
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Grid>
      {
        <>
          <Grid
            item
            p={2}
            sx={{
              bgcolor: '#f5f5f5',
              borderRadius: 2,
              border: '1px solid #e5e5e5',
              width: 1,
            }}
          >
            <Typography fontSize="15px" fontWeight={400}>
              {product.name}
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography fontSize="16px" fontWeight={700}>
                  {parseStringToPrice(product.price)}원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: 1 }} p={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography fontSize="14px" fontWeight={400}>
                  총 1개
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="span" fontSize="14px" fontWeight={400}>
                  총 금액{' '}
                </Typography>
                <Typography
                  component="span"
                  fontSize="21px"
                  fontWeight={700}
                  color="#FF203B"
                >
                  {parseStringToPrice(
                    product.price,
                  )}
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      <Grid container spacing={1}>


        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={disableOrder}
            variant="contained"
            sx={{ pt: 1, pb: 1, fontWeight: 700 }}
            onClick={handleOrder}
          >
            주문하기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
