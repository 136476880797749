import { Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { changeBottomTab, rememberPage } from 'store/app';

export default function Header({ title, noBackButton }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prevPage = useSelector(state => state.app.prevPage);

  const handleOnClick = () => {
    navigate(-1);
    // dispatch(changeBottomTab(prevPage));
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '48px',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2px',
        marginTop: '3px',
        padding: '0 15px',
      }}
    >
      {!noBackButton && (
        <Typography
          variant="span"
          sx={{
            marginRight: 'auto',
            padding: '12px 0',
          }}
        >
          <MdArrowBackIosNew
            className="icon"
            style={{ cursor: 'pointer' }}
            onClick={handleOnClick}
            // onChange={handleOnClick}
          />
        </Typography>
      )}
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          color: '#191919',

          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
