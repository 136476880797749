import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpenBox } from 'store/app';

import { Grid, IconButton, Typography, Button } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { CountController } from 'components/product';
import OptionSection from 'pages/product/OptionSection';
import { parseStringToPrice } from 'utils';
import { useNavigate } from 'react-router';
import { setOrderDetail } from 'store/order';
import { setProductOptionDetail, setTextOptionValue } from 'store/mall';
import { setOrderCartItems, addShopItemToBasketAPI } from 'store/cart';

export default function OrderSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth.user);
  const cartItems = useSelector(state => state?.cart.cartItems);
  const disableOrder = useSelector(state => state?.order.disableOrder);
  const textOptionValue = useSelector(state => state?.mall.textOptionValue);
  const productOptionDetail = useSelector(
    state => state?.mall.productOptionDetail,
  );

  // currentProduct, product 두 개 같은건데 엮인게 많아서 다 고치기 힘들어서 일단 두 개로 해놓는다 추후에 고치자
  const currentProduct = useSelector(state => state?.mall.productDetail);
  const product = useSelector(state => state?.mall.productDetail);

  useEffect(() => {
    dispatch(setTextOptionValue(''));

    if (
      currentProduct?.optionInfos?.length !== 0 &&
      !currentProduct?.chosenOption
    ) {
      dispatch(
        setProductOptionDetail([
          {
            type: 'noneAndAdditional',
            name: currentProduct.name,
            qty: 1,
            price: currentProduct.price,
          },
        ]),
      );
      return;
    }
  }, []);
  const addCartItem = async itemData => {
    dispatch(addShopItemToBasketAPI(itemData));
  };
  const addItemIntoCart = () => {
    if (disableOrder) {
      alert('옵션을 선택해주세요');
      return;
    }
    if (currentProduct.textOption && !textOptionValue) {
      alert('옵션을 선택해주세요');
      return;
    }
    if (cartItems?.length === 100) {
      alert(
        '최대 100개까지 장바구니에 담을 수 있습니다. 장바구니를 확인해주세요.',
      );
      return;
    }
    const itemData = {
      itemId: currentProduct.itemId,
      itemCount: calculateQty(),
      optionToBaskets: optionToOrderItems(),
      textOption: textOptionValue,
    };

    addCartItem(itemData);

    const ok = window.confirm(
      '상품이 장바구니에 담겼습니다. 지금 장바구니를 확인하시겠습니까?',
    );
    if (ok) navigate('/cart');
  };

  const calculateQty = () => {
    let productQty = 0;
    // 옵션 선택이 없는 경우 or 'Text option'인 경우
    if (productOptionDetail.length === 0) return;
    if (
      productOptionDetail[0].type === 'none' ||
      productOptionDetail[0].type === 'text'
    ) {
      productQty = productOptionDetail[0]?.qty;
    } else {
      const chosenOptions = productOptionDetail.filter(
        option => option.type === 'chosen',
      );
      const additionalOptions = productOptionDetail.filter(
        option => option.type === 'additional',
      );
      if (chosenOptions?.length === 0) {
        // 기타옵션만 있을 경우 기타옵션갯수로 상품의 갯수를 센다.
        // let qtySum = 0;
        // for (let i = 0; i < additionalOptions.length; i++) {
        //   qtySum += additionalOptions[i]?.qty;
        // }
        // productQty = qtySum;

        // 기타옵션만 있을 경우 상품 갯수 지정
        productQty = productOptionDetail[0]?.qty;
      } else {
        let qtySum = 0;
        for (let i = 0; i < chosenOptions.length; i++) {
          qtySum += chosenOptions[i]?.qty;
        }
        productQty = qtySum;
      }
    }

    return productQty;
  };
  const calculateShippingCost = () => {
    /**
     * 옵션 선택이 존재 && 배송비가 '수량별 부과'인 경우
     * 필수옵션 상품 갯수로 수량별 부과함
     */
    const shippingType = currentProduct?.shippingType;
    let shippingCost = 0;
    let packPivot = currentProduct.quantityPayQuantity;
    const productQty = calculateQty();

    if (shippingType === '무료') {
      shippingCost = 0;
    } else if (shippingType === '유료') {
      shippingCost = currentProduct.shippingCost;
    } else if (shippingType === '수량별부과') {
      shippingCost =
        Math.ceil(productQty / packPivot) * currentProduct.shippingCost;
    }
    return shippingCost;
  };

  // 가격 계산
  const calculateAmount = () => {
    const productQty = calculateQty();
    let additionalOptionAmount = 0;
    let otherAmount = 0;

    // 필수 옵션, 텍스트 옵션, 옵션 없음
    otherAmount = productQty * currentProduct.price;
    // 기타옵션
    const additionalOptions = productOptionDetail.filter(
      item => item.type === 'additional',
    );
    for (let i = 0; i < additionalOptions.length; i++) {
      additionalOptionAmount +=
        additionalOptions[i]?.qty * additionalOptions[i]?.price;
    }
    const amountSum = additionalOptionAmount + otherAmount;
    return { amountSum, additionalOptionAmount };
  };

  const optionToOrderItems = () => {
    const newArray = productOptionDetail.filter(option =>
      Boolean(option.optionId),
    );
    const optionInfo = newArray.map(item => ({
      optionId: item.optionId,
      optionCount: item.qty,
    }));
    return optionInfo;
  };

  const handleOrder = e => {
    dispatch(setOrderCartItems(false));
    if (disableOrder) {
      alert('옵션을확인해주세요.');
      return;
    }
    const productQty = calculateQty();
    const { amountSum, additionalOptionAmount } = calculateAmount();
    const shippingCost = calculateShippingCost();

    const detail = {
      hasInfo: true,
      customerName: user.name,
      itemName: currentProduct.name,
      price: currentProduct.price,
      itemId: currentProduct.itemId,
      itemCount: productQty,
      amount: amountSum,
      usePoint: 0,
      textOption: textOptionValue,
      additionalFee: additionalOptionAmount,
      shippingFee: shippingCost,
      optionToOrderItems: optionToOrderItems(),
    };

    dispatch(setOrderDetail(detail));

    e.preventDefault();
    navigate(`/payment`);
  };

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid
        item
        sx={{
          position: 'sticky',
          top: 0,
          height: '40px',
          width: '100%',
          textAlign: 'center',
          backgroundColor: 'common.white',
        }}
      >
        <IconButton
          size="small"
          onClick={e => {
            dispatch(
              setIsOpenBox({
                name: 'order',
                value: false,
              }),
            );

            if (
              currentProduct?.optionInfos?.length === 0 &&
              !currentProduct.textOption
            ) {
              dispatch(
                setProductOptionDetail([
                  {
                    type: 'none',
                    qty: 1,
                  },
                ]),
              );
            }
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Grid>
      {currentProduct?.optionInfos?.length !== 0 ||
      currentProduct?.textOption ? (
        <>
          {currentProduct?.optionInfos?.length !== 0 &&
            !currentProduct?.chosenOption && (
              <>
                <Grid
                  item
                  p={2}
                  sx={{
                    bgcolor: '#f5f5f5',
                    borderRadius: 2,
                    border: '1px solid #e5e5e5',
                    width: 1,
                  }}
                >
                  <Typography fontSize="15px" fontWeight={400}>
                    {product.name}
                  </Typography>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography fontSize="16px" fontWeight={700}>
                        {parseStringToPrice(product.price)}원
                      </Typography>
                    </Grid>
                    <Grid item mt={2}>
                      <CountController
                        p={0.5}
                        currentOption={productOptionDetail[0]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ width: 1 }} p={2} pb={0}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography fontSize="14px" fontWeight={400}>
                        총 {productOptionDetail[0]?.qty}개
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component="span"
                        fontSize="14px"
                        fontWeight={400}
                      >
                        총 금액{' '}
                      </Typography>
                      <Typography
                        component="span"
                        fontSize="21px"
                        fontWeight={700}
                        color="#FF203B"
                      >
                        {parseStringToPrice(
                          product.price * productOptionDetail[0]?.qty,
                        )}
                        원
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

          <OptionSection />
        </>
      ) : (
        <>
          <Grid
            item
            p={2}
            sx={{
              bgcolor: '#f5f5f5',
              borderRadius: 2,
              border: '1px solid #e5e5e5',
              width: 1,
            }}
          >
            <Typography fontSize="15px" fontWeight={400}>
              {product.name}
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography fontSize="16px" fontWeight={700}>
                  {parseStringToPrice(product.price)}원
                </Typography>
              </Grid>
              <Grid item mt={2}>
                <CountController
                  p={0.5}
                  currentOption={productOptionDetail[0]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: 1 }} p={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography fontSize="14px" fontWeight={400}>
                  총 {productOptionDetail[0]?.qty}개
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="span" fontSize="14px" fontWeight={400}>
                  총 금액{' '}
                </Typography>
                <Typography
                  component="span"
                  fontSize="21px"
                  fontWeight={700}
                  color="#FF203B"
                >
                  {parseStringToPrice(
                    product.price * productOptionDetail[0]?.qty,
                  )}
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ pt: 1, pb: 1 }}
            onClick={addItemIntoCart}
          >
            장바구니
          </Button>
        </Grid>

        <Grid item xs={8}>
          <Button
            fullWidth
            disabled={disableOrder}
            variant="contained"
            sx={{ pt: 1, pb: 1, fontWeight: 700 }}
            onClick={handleOrder}
          >
            주문하기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
