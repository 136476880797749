import React, { useState, useEffect , createRef, useRef} from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Rating,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';

import Divider from 'components/Divider';
import { fetchAddReview } from 'api/review';
import { useSelector } from 'react-redux';
import Header from 'components/layout/Header';
import { parseStringToPrice } from 'utils';

export default function WriteReview({ embedded, confirmedItem }) {
  const navigate = useNavigate();
  const { orderId, orderItemId } = useParams();

  const paidOrderList = useSelector(
    state => state?.order.paidOrderList.orderInfos,
  );

  const [value, setValue] = useState(5);
  const [textValue, setTextValue] = useState('');
  const [fileValue, setFileValue] = useState('');
  const [attachment, setAttachment] = useState('');
  const [item, setItem] = useState();
  const [list, setList] = useState();

  useEffect(() => {
    if (paidOrderList?.length === 0 && !confirmedItem) {
      alert('후기를 작성할 상품을 선택해주세요');
      navigate('/profile');
    }
  }, []);

  useEffect(() => {
    if (paidOrderList?.length === 0) return;
    const filtered = paidOrderList?.find(
      item => Number(orderId) === item.orderInfoId,
    );

    const newArr = filtered?.orderItems?.find(
      item => Number(orderItemId) === item.orderItemId,
    );

    setItem(newArr);
  }, [paidOrderList]);

  useEffect(() => {
    if (!item) {
      setList(confirmedItem);
      return;
    }
    if (item) setList(item);

    if (item?.reviewWrite) {
      alert('이미 리뷰를 작성하였습니다');
      navigate('/profile');
    }
    if (confirmedItem?.reviewWrite) {
      alert('이미 리뷰를 작성하였습니다');
      navigate('/profile');
    }
  }, [item, confirmedItem]);

  const onRatingChange = (e, rating) => {
    if (rating === null) return;
    setValue(rating);
  };

  const onTextChange = e => {
    const { value } = e.target;
    if (value.length > 5000) {
      alert('5000자 이내로 입력해주세요');
      return;
    }
    setTextValue(value);
  };

  const onFileChange = e => {
    const { files } = e.target;
    const theFile = files[0];
    setFileValue(theFile);
    const reader = new FileReader();
    reader.onloadend = eventDone => {
      const { result } = eventDone.currentTarget;
      setAttachment(result);
    };
    reader.readAsDataURL(theFile);
  };

  const handleOnClickFileInput = e => {
    e.target.value = '';

       window.postMessage("pickFile","*");

  };

  const onClearAttachment = () => {
    setFileValue('');
    setAttachment('');
  };
  const limitFileSize = number => {
    if (number >= 1048576 * 30) return false;
    return true;
  };

  const createReview = async () => {
    if (textValue?.length < 3) {
      alert('3자 이상 입력해주세요');
      return;
    }

    if (!limitFileSize(fileValue?.size)) {
      alert('사진은 30 MB를 초과할 수 없습니다.');
      return;
    }

    const formData = new FormData();

    const writeData = {
      itemId: list.shopItemId,
      title: textValue.substring(0, 20),
      content: textValue,
      shopOrderItemId: Number(orderItemId),
      score: value,
    };

    formData.append(
      'writeData',
      new Blob([JSON.stringify(writeData)], {
        type: 'application/json',
      }),
    );
    formData.append('files', fileValue);

    const res = await fetchAddReview(formData);
    if (res?.error === 'none') {
      alert('리뷰가 작성되었습니다');
      navigate(-1);
    }
  };
  let inputFileRef = createRef();
  return (
    <>
      {list && (
        <>
          {embedded || <Header title="후기작성" />}
          <Box p={3}>
            <Grid
              container
              gap={1}
              key={list.orderItemId}
              sx={{
                py: 2,
                borderBottom: '1px solid',
                borderColor: 'grey.100',
              }}
            >
              <Grid item xs={3.5}>
                <img src={list.thumbnail} alt={list.itemName} width="100%" />
              </Grid>
              <Grid item xs={7.5} sx={{ position: 'relative' }}>
                <Typography mb={1}>{list.itemName}</Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" fontWeight="bold" align="center" my={4}>
              구매한 상품은 어떠셨나요?
            </Typography>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <Rating value={value} size="large" onChange={onRatingChange} />
            </Grid>
          </Box>
          <Divider thickness="13px" />
          <Box p={3} pb={2}>
            <Typography fontSize="1.1rem" fontWeight="550">
              상세한 후기를 써주세요
            </Typography>
          </Box>

          <Box sx={{ p: 3, pt: 0 }}>
            <textarea
              aria-label="review"
              minLength={10}
              value={textValue}
              required
              onChange={onTextChange}
              style={{
                resize: 'none',
                width: '100%',
                height: '31vh',
                padding: '20px',
                borderColor: '#eee',
                borderRadius: '5px',
                fontFamily: "'Noto Sans KR', sans-serif",
                fontSize: '1rem',
                lineHeight: '1.5rem',
              }}
              placeholder="구매하신 아이템의 후기를 남겨주시면 다른 구매자들에게도 도움이 됩니다"
            />
            <Typography
              width={1}
              fontSize="0.8rem"
              color="grey.500"
              align="right"
            >
              {parseStringToPrice(textValue?.length)} / 5,000
            </Typography>
            <Typography fontSize="1.1rem" fontWeight="550" my={2}>
              사진 첨부{' '}
              <Typography variant="span" color="grey.300">
                {' '}
                (선택)
              </Typography>
            </Typography>
            <Box sx={{ padding: 0, textAlign: 'left' }}>
              <Box sx={{ position: 'relative' }}>
                <Button variant="outlined" >
                  <label htmlFor={"noticeFile"} >파일선택</label>
                </Button>
                <input
                  type="file"
                  id="noticeFile"

                //  ref={inputFileRef}
                  accept="image/*;capture=camera"
                  onClick={handleOnClickFileInput}
                  onChange={onFileChange}
                  style={{
                    position: 'absolute',
                    clip: 'rect(0rem, 100rem, 2rem, 15rem)',
                    transform: 'translateX(-4.8rem)',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Box>

            {/*
            <input
              type="file"
              multiple
              accept="image/*;capture=camera"
              onChange={onFileChange}
            /> */}
            {attachment && (
              <Box mt={1}>
                <img
                  src={attachment}
                  width="46px"
                  height="46px"
                  style={{ borderRadius: '4px' }}
                />
                <IconButton onClick={onClearAttachment}>
                  <CloseIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Box>
            )}
            <Typography fontSize="0.8rem" color="grey.500" mt={2}>
              사진첨부 시 개인정보가 노출되지 않도록 유의해주세요
            </Typography>
          </Box>

          <Box
            sx={{
              p: 3,
              height: 1,
              backgroundColor: 'grey.50',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{ height: '45px', mb: 1, fontSize: '1rem' }}
              onClick={createReview}
            >
              작성 완료
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{ height: '45px', fontSize: '1rem' }}
              onClick={() => navigate('/profile')}
            >
              다음에 작성하기
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
