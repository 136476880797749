import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, CircularProgress} from '@mui/material';

import { getCouponCategory } from 'store/coupon';
import { CategoryShapeItem } from 'components/coupon/container';
import UserPointSection from "./StorePanel/UserPointSection";


export default function CouponPanel() {

    const dispatch = useDispatch();

    const status = useSelector(state => state?.coupon.status);
    const couponCategoryList = useSelector(state => state?.coupon.couponCategoryList);

    useEffect(() => {
        ( () => {
             dispatch(getCouponCategory())
        })();
    }, []);

    return (
       <Box>
          <Box sx={{width:"100%"}}>
              <UserPointSection/>
          </Box>
           <Box ml={2} mr={2} mb={2} mt={0} sx={{minHeight:"720px"}} >
               <>
                   {(status === 'loading' || status === 'idle')&& (
                       <Box
                           sx={{
                               pt: 10,
                               display: 'flex',
                               justifyContent: 'center',
                               width:"100%"
                           }}
                       >
                           <CircularProgress />
                       </Box>
                   )}
                   <Box
                       justifyItems={"center"}
                       sx={{
                           display: 'grid',
                           gridTemplateColumns: 'repeat(3, 1fr)',
                           gap: 0,
                           width:"100%"
                       }}
                       px={2}
                       pt={3}

                   >
                       {couponCategoryList?.map((category, index) => {
                           return   <CategoryShapeItem category={category} key={category.id} index={index}  />;
                       })}
                   </Box>
               </>
           </Box>
       </Box>
    );
}

