import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AdProfitIcon } from 'assets/icons/home.svg';
import { ReactComponent as ShoppingProfitIcon } from 'assets/icons/shop.svg';
import { ReactComponent as UserStepProfitIcon } from 'assets/icons/stepping_profit.svg';
import { ReactComponent as CouponProfitIcon } from 'assets/icons/coupon.svg';
import { ReactComponent as ExtraProfitIcon } from 'assets/icons/extra_profit.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/people.svg';
import { ReactComponent as BootIcon } from 'assets/icons/categories/boot.svg';
import { ReactComponent as DressIcon } from 'assets/icons/categories/dress.svg';
import { ReactComponent as NecklaceIcon } from 'assets/icons/categories/necklace.svg';
import { ReactComponent as Necklace2Icon } from 'assets/icons/categories/necklace2.svg';
import { ReactComponent as PshirtIcon } from 'assets/icons/categories/pshirt.svg';
import { ReactComponent as ShoeIcon } from 'assets/icons/categories/shoe.svg';
import { ReactComponent as SkirtIcon } from 'assets/icons/categories/skirt.svg';
import { ReactComponent as SuitIcon } from 'assets/icons/categories/suit.svg';
import { ReactComponent as TshirtIcon } from 'assets/icons/categories/tshirt.svg';
import { ReactComponent as VestIcon } from 'assets/icons/categories/vest.svg';

import Product7 from 'assets/img/product/7.png';
import Product8 from 'assets/img/product/8.png';
import Product9 from 'assets/img/product/9.png';
import Product10 from 'assets/img/product/10.png';
import Product11 from 'assets/img/product/11.png';
import Product12 from 'assets/img/product/12.png';

import BannerImage1 from 'assets/img/banner/1.png';
import BannerImage2 from 'assets/img/banner/2.png';
import BannerImage3 from 'assets/img/banner/3.png';
import BannerImage4 from 'assets/img/banner/4.png';

export const SCORES = [
  {
    id: 'score5Count',
    text: '5점',
  },
  {
    id: 'score4Count',
    text: '4점',
  },
  {
    id: 'score3Count',
    text: '3점',
  },
  {
    id: 'score2Count',
    text: '2점',
  },
  {
    id: 'score1Count',
    text: '1점',
  },
];

export const SHIPPING_REQUESTS = [
  // {
  //   id: '0',
  //   text: '배송 요청사항을 선택해주세요.',
  // },
  {
    id: '0',
    text: '배송전, 연락바랍니다.',
  },
  {
    id: '1',
    text: '부재시, 경비실에 맡겨주세요.',
  },
  {
    id: '2',
    text: '부재시, 전화 또는 문자 연락 주세요.',
  },
  {
    id: '3',
    text: '택배함에 넣어주세요.',
  },
  {
    id: '4',
    text: '파손위험이 있는 상품이니 조심히 다뤄주세요.',
  },
  {
    id: '5',
    text: '문 앞에 놓아주세요.',
  },
  {
    id: '6',
    text: '직접입력',
  },
];

export const SORT_BUTTON = {
  BEST: [
    { name: '오늘의 베스트', value: 'DAY' },
    { name: '주간 베스트', value: 'WEEK' },
  ],
  SAVINGS: [
    { name: '수익금순', value: 'margin' },
    { name: '수익률순', value: 'yield' },
    { name: '신제품순', value: 'update' },
  ],
};

export const PRODUCT_TAB_NAMES = [
  {
    id: 0,
    name: '상품정보',
  },
  {
    id: 1,
    name: '상품후기',
  },
  {
    id: 2,
    name: '배송정책',
  },
];

export const COUPON_TAB_NAMES = [
  {
    id: 0,
    name: '상품정보',
  },
];

export const HOME_TAB_NAMES = [
  {
    id: 0,
    name: '스토어홈',
  },
  {
    id: 1,
    name: '베스트',
  },
  {
    id: 2,
    name: '알뜰적립',
  },
  {
    id: 3,
    name: '쿠폰선물',
  },
  {
    id: 4,
    name: '제휴쇼핑',
  },
];
export const PROFILE_TAB_NAMES = [
  {
    id: 0,
    name: '구매내역',
  },
  {
    id: 1,
    name: '내 쿠폰함',
  },
];

export const EVENT_PAGE_BANNERS = [
  {
    image: BannerImage1,
    point: '1포인트',
  },
  {
    image: BannerImage2,
    point: '2포인트',
  },
  {
    image: BannerImage3,
    point: '3포인트',
  },
  {
    image: BannerImage4,
    point: '4포인트',
  },
];

export const DETAILS_PAGE_PRODUCT_IMAGES = [
  Product7,
  Product8,
  Product9,
  Product10,
  Product11,
  Product12,
];

export const CATEGORIES = [
  { name: '패션의류', icon: <BootIcon /> },
  { name: '패션잡화', icon: <DressIcon /> },
  { name: '뷰티', icon: <NecklaceIcon /> },
  { name: '생활용품', icon: <Necklace2Icon /> },
  { name: '가전/디지털', icon: <PshirtIcon /> },
  { name: '축산물', icon: <ShoeIcon /> },
  { name: '수산물', icon: <SkirtIcon /> },
  { name: '농산물', icon: <SuitIcon /> },
  { name: '생수/음료', icon: <TshirtIcon /> },
  { name: '간편식', icon: <VestIcon /> },
];

export const NEW_CATEGORIES = [
  { code: 'all', name: '전체' },
  { code: '01', name: '생활·홈데코' },
  { code: '02', name: '가전·디지털' },
  { code: '03', name: '주방' },
  { code: '04', name: '뷰티' },
  { code: '05', name: '자동차·운동' },
  { code: '06', name: '유아용' },
  { code: '07', name: '여행' },
  { code: '08', name: '패션·잡화' },
  { code: '09', name: '건강·헬스' },
  { code: '0a', name: '레저' },
  { code: '0b', name: '식품' },
  { code: '010a', name: '반려용품' },
  { code: '0207', name: '미용가전' },
  { code: '0505', name: '골프' },
  { code: '0802', name: '시계·주얼리' },
  { code: '0902', name: '건강식품' },
  { code: '0b06', name: '수산물' },
  { code: '0b07', name: '축산물' },
];

export const BOTTOM_NAVIGATION = [
  {
    label: '광고수익',
    value: 'advertisement',
    icon: <AdProfitIcon />,
    to: null,
  },
  {
    label: '쇼핑수익',
    value: 'point-shop',
    icon: <ShoppingProfitIcon />,
    to: '/',
    component: Link,
  },
  {
    label: '걷기수익',
    value: 'step',
    icon: <UserStepProfitIcon />,
    to: '/step',
    component: Link,
  },
  {
    label: '추가수익',
    value: 'coupon',
    icon: <ExtraProfitIcon />,
    to: '/extra',
    component: Link,
  },
  {
    label: '마이페이지',
    value: 'profile',
    icon: <ProfileIcon />,
    to: '/profile',
    component: Link,
  },
];

export const BOTTOM_NAVIGATION_NEW = [
  {
    label: '포인트적립',
    value: 'advertisement',
    icon: <AdProfitIcon />,
    to: null,
  },
  {
    label: '쇼핑',
    value: 'point-shop',
    icon: <ShoppingProfitIcon />,
    to: '/',
    component: Link,
  },
  {
    label: '쿠폰',
    value: 'coupon',
    icon: <CouponProfitIcon />,
    to: '/coupon',
    component: Link,
  },
  {
    label: '마이페이지',
    value: 'profile',
    icon: <ProfileIcon />,
    to: '/profile',
    component: Link,
  },
];
