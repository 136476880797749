import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import { fetchShippingInfo } from 'api/order';

import PageLayout from 'components/layout/PageLayout';
import Modal from 'components/Modal2';
import Divider from 'components/Divider';

export default function DeliveryStatus({ open, setOpen, info }) {
  const dispatch = useDispatch();

  const [deliveryInfo, setDeliveryInfo] = useState([]);

  useEffect(() => {
    setDeliveryInfo([]);

    for (let i = 0; i < info?.orderItems.length; i++) {
      fetchShippingInfo(info?.orderItems[i].orderItemId).then(res =>
        setDeliveryInfo(prev => {
          const same = prev?.find(
            item => item.id === info.orderItems[i].orderItemId,
          );
          if (same) {
            return [...prev];
          }
          return [
            ...prev,
            { id: info?.orderItems[i].orderItemId, url: res.url },
          ];
        }),
      );
    }
  }, [info]);

  const handleOnClickCheckShipping = async id => {
    const current = deliveryInfo?.find(item => id === item.id);

    if (current.url) {
      window.location.href = current.url;
    } else {
      alert('아직 배송이 시작되지 않았거나 배송정보가 없습니다');
    }
  };

  return (
    <Modal open={open} closeButton onClose={() => setOpen(false)}>
      <PageLayout title="배송현황" noBackButton footerOff>
        <Divider thickness="13px" />

        <Box p={2} mt={1.5}>
          {info?.orderItems?.map(item => {
            return (
              <Grid
                container
                gap={1}
                key={item.orderItemId}
                sx={{
                  py: 2,
                  borderBottom: '1px solid',
                  borderColor: 'grey.100',
                }}
              >
                <Grid item xs={3.5}>
                  <img src={item.thumbnail} alt={item.itemName} width="100%" />
                </Grid>
                <Grid item xs={7.5} sx={{ position: 'relative' }}>
                  <Box mb={0.5}>{item.itemName}</Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'end',
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ display: 'block', ml: 'auto' }}
                      onClick={() =>
                        handleOnClickCheckShipping(item.orderItemId)
                      }
                    >
                      배송정보
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </PageLayout>
    </Modal>
  );
}

export const TableCustom = ({ children }) => (
  <table
    style={{
      width: '100%',
      marginBottom: '12px',
      border: '1px solid #eee',
      borderCollapse: 'collapse',
    }}
  >
    <tbody>{children}</tbody>
  </table>
);
export const TrCustom = ({ children }) => (
  <tr style={{ border: '1px solid #eee' }}>{children}</tr>
);
export const TdCustom = ({ w, bg, text }) => (
  <td
    style={{
      width: w,
      padding: '10px',
      backgroundColor: bg,
      textIndent: '6px',
      fontSize: '0.8rem',
    }}
  >
    {text}
  </td>
);
