import API from 'api';
export const fetchAddReview = async data => {
  let response = '';
  try {
    response = await API.post('v1/shopItemReview/post', data);

    return response.data;
  } catch (error) {
    return error;
  }
};
export const fetchUserReviews = async page => {
  try {
    const response = await API.post('v1/shopItemReview/users', {
      page: page,
      count: 20,
      condition: 'date',
      direction: 'DESC',
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
export const fetchDeleteReviews = async id => {
  try {
    const response = await API.post('v1/shopItemReview/delete', {
      reviewId: id,
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
