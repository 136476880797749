import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export default function TabPanel({ children, name, tabIndex, ...rest }) {
  const currentTab = useSelector(state => state?.app.activeTab[name]);

  return (
    <Box
      role="tabpanel"
      hidden={tabIndex !== currentTab}
      {...tabPanelA11yProps(tabIndex)}
      {...rest}
    >
      {tabIndex === currentTab && children}
    </Box>
  );
}

const tabPanelA11yProps = index => ({
  id: `tabpanel-${index}`,
  'aria-labelledby': `tab-${index}`,
});
