import React from 'react';
import { styled } from '@mui/material/styles';

import { Box, Divider } from '@mui/material';

import { useSelector } from 'react-redux';

import parse from 'html-react-parser';

export default function DetailsSection() {
  const product = useSelector(state => state?.mall.productDetail);
  const html = product?.explainTag;

  return (
    <React.Fragment>
      <ProductDetailContainer ml={2} mr={2} mt={4}>
        {html && parse(html)}

        <Divider
          style={{
            marginTop: '13px',
            borderColor: '#F1F1F5',
            borderBottomWidth: 'thin',
          }}
        />

        {/* <Typography mt={3} mb={7} align="center">
          수작업 측정으로 1~3cm 오차가 있을 수 있습니다.
        </Typography> */}
      </ProductDetailContainer>
      <Divider style={{ borderColor: '#F1F1F5', borderBottomWidth: 'thick' }} />
    </React.Fragment>
  );
}

const ProductDetailContainer = styled(Box)({
  img: {
    width: '100%',
  },

  iframe: {
    width: '100%',
  },
});
