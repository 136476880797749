import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';

import {
  setCurrentCategory,
  initProductList,
  initProductListForInfiniteScroll,
} from 'store/mall';
import { changeActiveTab, setCurrentSortBy } from 'store/app';
import { getCategoriesByParentCode, getThirdLevelCategoriesByParentCode } from 'store/categorySort';
import Dialog from "@mui/material/Dialog";

export default function TabBar({
  sideBar,
  name,
  type,
  dataset,
  activeTab,
  ...rest
}) {
  const dispatch = useDispatch();

  const categories = useSelector(state => state?.mall.categories);
  const isOpenDrawer = useSelector(state => state?.app.isOpenDrawer);
const [open, setOpen] = useState(false);
  const newCategories = categories?.filter(
    item => item.code !== 'all' && item.rank === 1,
  );

  useEffect(() => {
    const resetSortTab = (name, value) => {
      dispatch(
        setCurrentSortBy({
          name: name,
          value: value,
        }),
      );
    };

    resetSortTab('best', 'DAY');
    resetSortTab('savingsPoint', 'margin');
  }, []);

  const filterCategoryByRank = async item => {
    await dispatch(getCategoriesByParentCode(item.code));
    await dispatch(getThirdLevelCategoriesByParentCode(item.code + "01"));
  };

  const handleOnTab = (event, nextTabIdx) => {
    if(event.currentTarget.textContent.indexOf("쿠4폰") === -1){
      resetValues(nextTabIdx);
      dispatch(initProductListForInfiniteScroll());
      dispatch(initProductList());
      dispatch(changeActiveTab({ name, nextTabIdx }));

      if (type === 'category') {
        dispatch(setCurrentCategory(event.currentTarget.dataset.code));
      }
    }
    else{
        setOpen(true);
        setInterval(() => {
            setOpen(false);
        }, 3000)
    }
  };

  const resetCurrentTab = name => {
    dispatch(
      changeActiveTab({
        name: name,
        nextTabIdx: 0,
      }),
    );
  };

  const resetValues = nextTabIdx => {
    // if (name === 'best') resetCurrentTab('best');
    // if (name === 'savingsPoint') resetCurrentTab('savingsPoint');
    if (name === 'home' && nextTabIdx === 1) {
      resetCurrentTab('best');
      dispatch(setCurrentCategory('all'));
    }
    if (name === 'home' && nextTabIdx === 2) {
      resetCurrentTab('savingsPoint');
      dispatch(setCurrentCategory('all'));
    }

    // dispatch(setCurrentCategory('all'));
    dispatch(
      setCurrentSortBy({
        name: name,
        value: name === 'best' ? 'DAY' : 'margin',
      }),
    );
  };

  return (
    <React.Fragment>
      <Tabs
        value={activeTab}
        onChange={(e, next) => {
          handleOnTab(e, next);
          if (isOpenDrawer.menu) filterCategoryByRank(newCategories[next]);
        }}
        aria-label="tabs"
        sx={{
          ...(type === 'home' &&
            !sideBar && {
              position: 'absolute',
              zIndex: 100,
              maxWidth: 1,
              backgroundColor: '#fff',

            }),
        }}
        {...rest}
      >
        {dataset.map((category, index) => (
          <Tab
            data-code={category.code}
            key={category.id}
            label={type === 'category' ? category.name_2 : category.name}
            {...tabA11yProps(index)}
            sx={
              sideBar
                ? {
                    fontSize: '0.8rem',
                    minHeight: '0px',
                    height: '38px',
                    backgroundColor: '#fff',
                    color: 'red',
                    '&.Mui-selected': {
                      color: 'red',
                      // backgroundColor: 'grey.100',
                    },
                  }
                : {
                    fontSize: '0.9rem',
                    fontWeight: '900',
                    color: '#191919',
                    '&.Mui-selected': {
                      fontSize: '0.9rem',
                    },
                    '&.MuiButtonBase-root': {
                      padding: '12px 14px',
                    },
                  }
            }
          />
        ))}
      </Tabs>
        <Dialog open={open} maxWidth={"xs"}
                PaperProps={{ sx: { borderRadius: "15px" } }}
                slotProps={{ backdrop: { sx: { background: 'rgba(255, 255, 255, 0)'} } }}>
            <div style={{backgroundColor: "#757575", color:"#FFFFFF", fontSize:"15px", padding:"8px", borderRadius:"15px" }}>
                개발 예정입니다!
            </div>
        </Dialog>
    </React.Fragment>
  );
}

const tabA11yProps = index => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});
