import React from 'react';
import { Typography, Box } from '@mui/material';
import { parseStringToPrice } from 'utils';

export default function TextSet({ product }) {

  return (
    <Box>
      {/* <Typography
        style={{ fontSize: '10px', fontWeight: 'bold', color: 'red' }}
      >
        구매시 추가 적립 혜택
      </Typography> */}
        <Typography

            sx={{

                fontSize: '0.8rem',
                color: "grey.600",
                fontWeight:"normal",
                overflow: 'hidden',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
            }}
        >
            {product?.organization.replace(" >", "")}
        </Typography>
      <Typography
        sx={{
          minHeight: '22px',
          fontSize: '0.85rem',
          color: '#191919',
          fontWeight:"bold",
          overflow: 'hidden',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
        }}
      >
        {product?.itemOriginName || product?.name}
      </Typography>
      <Typography fontSize="0.85rem" fontWeight="bold" color="#131313">
        {parseStringToPrice(product?.price)}원{' '}
      </Typography>
        <Typography fontSize="0.7rem" fontWeight="bold" color="#F61F39">
            수익금  : {parseStringToPrice(product?.couponPoint)} P
        </Typography>
        {/*<Typography fontSize="0.7rem" fontWeight="normal" color="#131313">*/}
        {/*    유효기간: {product?.expireDate}일*/}
        {/*</Typography>*/}
    </Box>
  );
}
