import { useSelector } from 'react-redux';

import HomePanel from 'pages/home/StorePanel/HomePanel';
import AssortedPanel from 'pages/home/StorePanel/AssortedPanel';

import { SORT_BUTTON } from 'contants';
import ScrollTop from 'components/ScrollTop';
import { Box } from '@mui/material';

export default function StorePanel() {
  const currentTab = useSelector(state => state?.app.activeTab.home);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <ScrollTop />

      {currentTab === 0 && <HomePanel />}
      {currentTab === 1 && (
        <AssortedPanel name="best" buttonsArr={SORT_BUTTON.BEST} />
      )}
      {currentTab === 2 && (
        <AssortedPanel name="savingsPoint" buttonsArr={SORT_BUTTON.SAVINGS} />
      )}
    </Box>
  );
}
