import { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { CardMedia, Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { setIsOpenModal, setCurrentImageViewerUrl } from 'store/app';

import DefaultImage from 'assets/img/default.png';

const SHOW_IMAGE_COUNT_PIVOT = 8;

export default function PhotoReview({ images }) {
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [willShowImageCount, setWillShowImageCount] = useState(
    SHOW_IMAGE_COUNT_PIVOT,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (images.length < willShowImageCount) return;

    setPage(Math.floor(images.length / SHOW_IMAGE_COUNT_PIVOT));
  }, [images]);

  useEffect(() => {
    setWillShowImageCount(currentPage * SHOW_IMAGE_COUNT_PIVOT);
  }, [currentPage]);

  const handleOpenImageReviewModal = e => {
    setCurrentImage(e.target.src);

    dispatch(
      setIsOpenModal({
        name: 'imageReview',
        value: true,
      }),
    );
  };

  const setCurrentImage = src => {
    dispatch(setCurrentImageViewerUrl(src));
  };

  const handleAddShowImage = () => {
    if (currentPage + 1 > page) {
      const leftImageLength = images.length - willShowImageCount;

      setWillShowImageCount(willShowImageCount + leftImageLength);

      return;
    }

    setCurrentPage(currentPage + 1);
  };

  return images?.slice(0, willShowImageCount).map((image, index) => (
    <Fragment key={image.reviewId}>
      <Grid item xs={3} p={0.3} sx={{ position: 'relative' }}>
        {index === willShowImageCount - 1 ? (
          images.length === willShowImageCount ? null : (
            <LastImageCover onClick={handleAddShowImage} />
          )
        ) : null}
        {image.imageUrls && (
          <CardMedia
            component="img"
            src={image.imageUrls[0]}
            onClick={handleOpenImageReviewModal}
            onError={e => (e.target.src = DefaultImage)}
          />
        )}
      </Grid>
    </Fragment>
  ));
}

const LastImageCover = ({ onClick }) => (
  <Box
    item
    sx={{
      width: 1,
      height: 1,
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.5)',
    }}
    onClick={onClick}
  >
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: 1 }}
    >
      <AddIcon sx={{ color: '#ffffff' }} />
      <Typography color="#ffffff" fontSize="12px" align="center">
        더보기
      </Typography>
    </Grid>
  </Box>
);
