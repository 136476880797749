import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, SvgIcon } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import Divider from 'components/Divider';
import Banner from 'components/Banner';

import { ReactComponent as AdswordLogo } from 'assets/icons/adsward_r.svg';

export default function BannerSection() {
  const dispatch = useDispatch();

  const banners = useSelector(state => state?.mall.banners);

  const handleOnClick = banner => {
    let to = '';

    if (banner.moveUrl === null) to = `event/${banner.id}`;
    if (banner.moveUrl !== null) to = banner.moveUrl;

    window.location.href = to;
  };
  return (
    <>
     <Swiper
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        {banners?.map(banner => (
          <SwiperSlide key={banner.id}>
            <Banner
              component="img"
              src={banner.imageUrl}
              alt={`${banner.name} banner`}
              onClick={() => handleOnClick(banner)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <SubBanner />
    </>
  );
}

const SubBanner = () => (
  <Box m={2} p={2} sx={{ borderRadius: 2, boxShadow: 1 }}>
    <Grid container justifyContent="center">
      <Grid item>
        <SvgIcon
          component={AdswordLogo}
          inheritViewBox
          style={{ width: '86px' }}
        />
      </Grid>
      <Grid item>
        <Typography
          component="span"
          sx={{
            font: 'normal normal bold 16px/24px Noto Sans CJK KR',
            color: '#4A4A4A',
          }}
        >
          포인트로 돈 안쓰고 쇼핑하자!
        </Typography>
      </Grid>
    </Grid>

    <Typography
      align="center"
      sx={{
        font: 'normal normal bold 12px/18px Noto Sans CJK KR',
        color: '#767676',
        mt: 0.5,
      }}
    >
      포인트로 쇼핑해도 수익나눔 혜택까지!
      <br />돈 버는 쇼핑하세요~
    </Typography>
  </Box>
);
