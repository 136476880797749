import { useSelector } from 'react-redux';

import { Grid, CardMedia } from '@mui/material';

export default function ImageViewer() {
  const currentImage = useSelector(state => state.app.currentImageViewerUrl);

  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      sx={{ height: 1, bgcolor: 'common.black' }}
    >
      <CardMedia
        component="img"
        src={currentImage}
        sx={{ bgcolor: 'common.white' }}
      />
    </Grid>
  );
}
