import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';

import Container from 'components/atom/Container';
import Divider from 'components/Divider';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  getItemsBySearch,
  getSearchKeywords,
  removeSearchKeywords,
  setCurrentKeyword,
  initSearchProduct,
} from 'store/mall';
import { setScrollTop } from 'store/app';
import { CardShapeItem, ListShapeItem } from 'components/Items/container';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollTop from 'components/ScrollTop';

export default function Search() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { keyword } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const bestProducts = useSelector(state => state?.mall.productsBest);
  const [search, setSearch] = useState(keyword ? true : false);
  const keywordsFromDB = useSelector(state => state?.mall.searchKeywords);
  const currentKeyword = useSelector(state => state?.mall.currentKeyword);
  const searchProducts = useSelector(state => state?.mall.productSearch);
  const [page, setPage] = useState(1);
  const [productList, setProductList] = useState([]);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // URL로 접근시
    if (!keyword) return;
    searchWithKeyword(keyword, 1);
  }, []);

  useEffect(() => {
    dispatch(setScrollTop(false));
    dispatch(initSearchProduct());
    setProductList([]);
  }, []);

  useEffect(() => {
    return () => dispatch(setScrollTop(true));
  }, []);

  useEffect(() => {
    dispatch(getSearchKeywords());
  }, [dispatch, searchProducts, currentKeyword]);

  useEffect(() => {
    // page가 바뀔 때마다 키워드로 검색한다.
    // if (!search) return;
    if (!keyword) return;
    if (!scrolling) return;

    searchWithKeyword(keyword, page);
  }, [page]);

  useEffect(() => {
    addProductIntoList(searchProducts);
  }, [searchProducts]);

  const addProductIntoList = items => {
    const same = items.find(item => {
      const result = productList?.find(data => data.itemId === item.itemId);
      return result;
    });

    if (same) return;

    // setProductList(prev => [...prev, ...items]);

    const newList = [...productList, ...items];
    setProductList(newList);
  };

  const handleSearchInput = e => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const searchWithKeyword = async (keyword, p) => {
    const data = {
      categoryCode: null,
      page: p,
      count: 20,
      viewStandard: 'update',
      viewDirection: 'DESC',
      searchContent: keyword,
    };

    dispatch(getItemsBySearch(data));
    setSearch(true);
    navigate(`/search/${keyword}`);
  };
  const deleteSearchKeyword = async keyword => {
    dispatch(removeSearchKeywords(keyword)).then(() =>
      dispatch(setCurrentKeyword(keyword)),
    );
  };
  const changePage = () => {
    setPage(prev => prev + 1);
  };

  return (
    <Container sx={{ minHeight: 'calc(100vh - 118px)' }}>
      <Grid
        container
        gap={2.5}
        sx={{
          padding: '2px 0',
          borderBottom: 1,
          position: 'sticky',
          top: '48px',
          zIndex: 10,
          backgroundColor: '#fff',
        }}
      >
        <Grid item xs={1}>
          <IconButton
            name="search"
            onClick={e => {
              navigate(-1);
              setSearch(false);
            }}
          >
            <ArrowBackIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Grid>
        <Grid item xs={9.8}>
          <TextField
            autoFocus
            focused
            value={searchValue}
            onChange={handleSearchInput}
            placeholder="상품을 검색해보세요"
            variant="standard"
            fullWidth
            style={{
              WebkitImeMode: 'active',
              imeMode: 'active',
            }}
            sx={{
              border: '0px solid !important',
              bgcolor: '#fff',
              borderRadius: 'none',
              '& .MuiInputBase-root:before': {
                borderBottom: '0px solid',
              },
              '& .MuiInputBase-root:hover:before': {
                borderBottom: '0px solid',
              },
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setScrolling(false);
                searchWithKeyword(searchValue, 1);
                setPage(1);
                setProductList([]);
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  {searchValue && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      variant="contained"
                      sx={{
                        padding: '2.2px',
                        mr: 0.8,
                        bgcolor: 'grey.300',
                        ':hover': {
                          backgroundColor: 'grey.300',
                        },
                      }}
                      onClick={() => setSearchValue('')}
                    >
                      <CloseIcon sx={{ color: '#fff', fontSize: '0.9rem' }} />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setScrolling(false);
                      searchWithKeyword(searchValue, 1);
                      setPage(1);
                      setProductList([]);
                    }}
                  >
                    <SearchIcon
                      sx={{ fontSize: '2rem', color: 'primary.main' }}
                    />
                  </IconButton>
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box>
        {search && searchProducts?.length === 0 && !scrolling && (
          <Box>
            <Typography textAlign="center" p={5}>
              검색결과가 없습니다.
            </Typography>
            <Divider thickness="7px" />
            <Typography p={2} pt={2.5} fontSize="1.2rem" fontWeight="bold">
              오늘의 베스트
            </Typography>
            <Box p={2}>
              {bestProducts?.map(item => (
                <Box pt={0.5} key={item.itemId}>
                  <ListShapeItem key={item.itemId} product={item} />
                  <Divider sx={{ borderColor: 'grey.50' }} />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {search ? (
          <>
            {productList?.length !== 0 && (
              <>
                <Typography p={2} pb={0} fontSize="0.8rem" textAlign="right">
                  최신순
                </Typography>
                <InfiniteScroll
                  dataLength={productList?.length}
                  next={() => {
                    setScrolling(true);
                    changePage();
                  }}
                  hasMore={searchProducts?.length !== 0}
                  endMessage={
                    <Typography align="center">목록의 마지막입니다</Typography>
                  }
                  loader={
                    <Box
                      sx={{
                        pt: 3,
                        pb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 1,
                      p: 2,
                    }}
                  >
                    {productList?.map(item => (
                      <CardShapeItem key={item.itemId} product={item} />
                    ))}
                  </Box>
                </InfiniteScroll>
              </>
            )}
          </>
        ) : (
          <>
            <Typography
              sx={{
                padding: 1.8,
                paddingLeft: '16px',
                color: 'common.black',
                font: 'normal normal bold 1.1rem/24px Noto Sans CJK KR',
              }}
            >
              최근 검색어
            </Typography>
            <Divider thickness="8px" />
            <List sx={{ padding: 2 }}>
              {keywordsFromDB &&
                keywordsFromDB?.map((keyword, i) => (
                  <ListItem
                    key={i}
                    sx={{
                      padding: 0,
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        variant="contained"
                        sx={{
                          padding: '2.2px',
                          bgcolor: 'grey.300',
                          ':hover': {
                            backgroundColor: 'grey.300',
                          },
                        }}
                        onClick={() => deleteSearchKeyword(keyword)}
                      >
                        <CloseIcon sx={{ color: '#fff', fontSize: '0.9rem' }} />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={keyword}
                      onClick={() => {
                        setProductList([]);
                        setSearchValue(keyword);
                        searchWithKeyword(keyword, 1);
                        setPage(1);
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </>
        )}
        <ScrollTop />
      </Box>
    </Container>
  );
}
