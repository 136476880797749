import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Grid, Box, Typography, IconButton, CardMedia } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { parseStringToPrice } from 'utils';
import Divider from 'components/Divider';
export default function ItemDisplayForPayment() {
  const orderDetail = useSelector(state => state?.order.orderDetail);
  const productOptionDetail = useSelector(
    state => state?.mall.productOptionDetail,
  );
  const user = useSelector(state => state?.auth.user);
  const orderCartItems = useSelector(state => state?.cart.orderCartItems);
  const orderItemsInfo = useSelector(state => state?.cart.orderItemsInfo);
  const orderItemsInfoForDisplay = useSelector(
    state => state?.cart.orderItemsInfoForDisplay,
  );

  const dispatch = useDispatch();

  const couponDetail = useSelector(state => state?.coupon.coupon);

  const [productInfoBoxOpen, setProductInfoBoxOpen] = useState(true);

  let navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: '#ffffff',
        border: '1px solid #e5e5e5',
        borderRadius: 2,
      }}
      m={2}
      p={2}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography fontSize="16px" fontWeight={700}>
            주문상품
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <IconButton onClick={() => setProductInfoBoxOpen(prev => !prev)}>
              {productInfoBoxOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      { productInfoBoxOpen && (
        <Grid container spacing={1.5} mt={0.5}>
          {productOptionDetail?.map((option, idx) => (
              <React.Fragment key={couponDetail?.itemId + idx}>
                <Grid item xs={2.5}>
                  <CardMedia component="img" src={couponDetail?.imageUrl} />
                </Grid>
                <Grid item xs={9.5}>
                  {/* 옵션없음, textOption */}
                  {productOptionDetail?.length === 1 &&
                      (option.type === 'none' || option.type === 'text') && (
                          <>
                            <Typography mb={1}>{orderDetail.itemName}</Typography>
                            <Typography component="span" pr={1}>
                              {parseStringToPrice(couponDetail.price)}원
                            </Typography>
                            <Typography
                                component="span"
                                pl={1}
                                sx={{ borderLeft: '1px solid #e5e5e5' }}
                            >
                              {orderCartItems ? (
                                  option.qty
                              ) : (
                                  <>
                                    {Boolean(couponDetail.chosenOption)
                                        ? option.qty
                                        : orderDetail.itemCount}
                                  </>
                              )}
                              개
                            </Typography>
                          </>
                      )}
                </Grid>
              </React.Fragment>
          ))}
        </Grid>

      )}

    </Box>
  );
}
