import {createAsyncThunk, createSelector, createSlice,} from '@reduxjs/toolkit';
import {
  fetchEventItemById,
  fetchMainBestItems,

  fetchProductDetailById,
  fetchProductReviews,
  fetchProductsByCategory,
  fetchLatestItems,
} from 'api/product';
import {fetchCategories} from 'api/category';
import {fetchBanners, fetchDailyBestItemBanner, fetchSavingsItemsBanner, fetchWeeklyBestItemBanner,} from 'api/banner';
import {getItemsByKeyword, getRecentList, removeRecentList} from 'api/search';
import {NEW_CATEGORIES} from 'contants';
import {fetchEventList} from 'api/event';


const initialState = {
  productListForInfiniteScroll: [],
  status: 'idle',
  productStatus: 'idle',
  currentKeyword: '',
  searchKeywords: '',
  searchUpdated: '',
  productSearch: [],
  productOptionDetail: [],
  textOptionValue: '',
  categoryRank: 1,
  categories: [],
  categoryByParentCode: [],
  currentCategory: '01', // should be init category code
  banners: [],
  mallBanners: {
    day: {
      imgUrl: '',
    },
    week: {
      imgUrl: '',
    },
    margin: {
      imgUrl: '',
    },
    yield: {
      imgUrl: '',
    },
    update: {
      imgUrl: '',
    },
  },
  product: {},
  productReviews: [],
  productDetail: {},
  productsByCategory: [],
  productsLatest: [],
  productsBest: [],
  productsEvent: [],
  eventList: null,
  bestProductsByPeriod: [],
  cart: [],
  error: null,
};

// Get Items By Search
export const getEventList = createAsyncThunk('mall/getEventList', async () => {
  const res = await fetchEventList();
  return res.data;
});
// Get Items By Search
export const getItemsBySearch = createAsyncThunk(
  'mall/getItemsBySearch',
  async keyword => {
    const products = await getItemsByKeyword(keyword);
    return products.data;
  },
);
// Get Keyword Searched
export const getSearchKeywords = createAsyncThunk(
  'mall/getSearchKeywords',
  async () => {
    const products = await getRecentList();
    const keywords = products.keywords;

    return keywords;
  },
);
// Remove Keyword Searched
export const removeSearchKeywords = createAsyncThunk(
  'mall/removeSearchKeywords',
  async keyword => {
    await removeRecentList(keyword);
  },
);

// Event Items
export const getEventItemById = createAsyncThunk(
  'mall/getEventItemById',
  async ({ id, page }) => {
    const res = await fetchEventItemById({ id, page });

    return res;
  },
);

// Main Savings Items
export const getLatestMainItems = createAsyncThunk(
  'mall/getLatestMainItems',
  async data => {
    const products = await fetchLatestItems(data);

    return products.data;
  },
);
// Main Best Items
export const getMainBestItems = createAsyncThunk(
  'mall/getMainBestItems',
  async data => {
    const products = await fetchMainBestItems(data);

    return products.data;
  },
);


export const getProductReviews = createAsyncThunk(
  'mall/getProductReviews',
  async data => {
    const reviews = await fetchProductReviews(data);
    /*
      {
        page: Number,
        infos: [{
          reviewId: Number,
          userId: Number,
          userMail: String,
          userName: String,
          title: String,
          score: Number,
          isBlind: Boolean,
          createdDate: String,
          contentUrls: Array --> 뭐에 쓰는 자료인지?
          content: String
        }, ...]
      }
    */

    return reviews.data;
  },
);

export const getProductsByCategory = createAsyncThunk(
  'mall/getProductsByCategory',
  async data => {
    const products = await fetchProductsByCategory(data);

    return products.data;
  },
);

export const getAllCategories = createAsyncThunk(
  'mall/getAllCategories',
  async (rank, notAll) => {
    const categories = await fetchCategories();
    const categoriesData = categories.data;
    // "전체"를 category 목록에 넣는다.
    const allAddedCategories = [
      {
        id: 0,
        code: 'all',
        name: '전체',
        name_2: '전체',
        rank: 0,
      },
      ...categoriesData,
    ];

    const newCategories = allAddedCategories.map(category => {
      let name = '';
      NEW_CATEGORIES.forEach(item => {
        if (category.code === item.code) {
          name = item.name;
        }
      });
      return { ...category, name_2: name };
    });

    return newCategories;
  },
);

export const getSavingsPointBanners = createAsyncThunk(
  'mall/getSavingsPointBanners',
  async sort => {
    const banners = await fetchSavingsItemsBanner(sort);
    return banners;
  },
);


export const getDailyBestItemBanners = createAsyncThunk(
  'mall/fetchDailyBestItemBanner',
  async () => {
    const banners = await fetchDailyBestItemBanner();

    return banners;
  },
);
export const getWeeklyBestItemBanners = createAsyncThunk(
  'mall/getWeeklyBestItemBanners',
  async () => {
    const banners = await fetchWeeklyBestItemBanner();

    return banners;
  },
);
export const getBanners = createAsyncThunk('mall/getBanner', async () => {
  const banners = await fetchBanners();

  return banners.data;
});

export const getProductDetailById = createAsyncThunk(
  'mall/getProductDetailById',
  async data => {
    const productDetail = await fetchProductDetailById(data);

    productDetail.data.error = productDetail.error;
    return productDetail.data;
  },
);

export const mallSlice = createSlice({
  name: 'mall',
  initialState,
  reducers: {
    initEventList: (state, action) => {
      state.eventList = null;
    },
    setProductListForInfiniteScroll: (state, action) => {
      state.productListForInfiniteScroll = action.payload;
    },
    initProductListForInfiniteScroll: (state, action) => {
      state.productListForInfiniteScroll = [];
    },
    initSearchProduct: (state, action) => {
      state.productSearch = [];
    },
    initProductList: (state, action) => {
      state.productsBest = [];
      state.productsLatest = [];
    },
    setCurrentKeyword: (state, action) => {
      state.currentKeyword = action.payload;
    },
    setTextOptionValue: (state, action) => {
      state.textOptionValue = action.payload;
    },
    setProductOptionDetail: (state, action) => {
      state.productOptionDetail = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setOrderProductCount: (state, action) => {
      state.productDetail.orderCount = action.payload;
    },
    setProductToCart: (state, action) => {
      state.cart.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      // get Event List
      .addCase(getEventList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEventList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.eventList = action.payload;
      })
      .addCase(getEventList.rejected, (state, action) => {
        state.error = action?.error.message;
      })
      // get Banners
      .addCase(getBanners.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.banners = action.payload;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.error = action?.error.message;
      })
      // get Best Item Daily Banners
      .addCase(getDailyBestItemBanners.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDailyBestItemBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mallBanners.day.imgUrl =
          action.payload?.bestItemDayInfo?.imageUrl;
      })
      .addCase(getDailyBestItemBanners.rejected, (state, action) => {
        state.error = action?.error.message;
      })
      // get Best Item Weekly Banners
      .addCase(getWeeklyBestItemBanners.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getWeeklyBestItemBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mallBanners.week.imgUrl =
          action.payload?.bestItemWeekInfo?.imageUrl;
      })
      .addCase(getWeeklyBestItemBanners.rejected, (state, action) => {
        state.error = action?.error.message;
      })
      // get SavingsPoint Banners
      .addCase(getSavingsPointBanners.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSavingsPointBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload?.frugalMarginItemInfo) {
          state.mallBanners.margin.imgUrl =
            action.payload?.frugalMarginItemInfo?.imageUrl;
        }
        if (action.payload?.frugalYieldItemInfo) {
          state.mallBanners.yield.imgUrl =
            action.payload?.frugalYieldItemInfo?.imageUrl;
        }
        if (action.payload?.frugalNewItemInfo) {
          state.mallBanners.update.imgUrl =
            action.payload?.frugalNewItemInfo?.imageUrl;
        }
      })
      .addCase(getSavingsPointBanners.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // get all category
      .addCase(getAllCategories.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // get products by category
      .addCase(getProductsByCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProductsByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsByCategory = action.payload;
      })
      .addCase(getProductsByCategory.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // get main best products
      .addCase(getMainBestItems.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getMainBestItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsBest = action.payload;
      })
      .addCase(getMainBestItems.rejected, (state, action) => {
        state.error = action?.error.message;
      })


      // get product detail by id
      .addCase(getProductDetailById.pending, (state, action) => {
        state.productStatus = 'loading';
      })
      .addCase(getProductDetailById.fulfilled, (state, action) => {
        state.productStatus = 'succeeded';
        state.productDetail = action.payload;
      })
      .addCase(getProductDetailById.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // get product reviews
      .addCase(getProductReviews.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProductReviews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productReviews = action.payload.infos;
      })
      .addCase(getProductReviews.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // Get Event Items
      .addCase(getEventItemById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEventItemById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsEvent = action.payload;
      })
      .addCase(getEventItemById.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // get products savings point
      .addCase(getLatestMainItems.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLatestMainItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsLatest = action.payload;
      })
      .addCase(getLatestMainItems.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // Get Items By Search
      .addCase(getItemsBySearch.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getItemsBySearch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productSearch = action.payload;
      })
      .addCase(getItemsBySearch.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // Get Keywords Searched
      .addCase(getSearchKeywords.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSearchKeywords.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.searchKeywords = action.payload;
      })
      .addCase(getSearchKeywords.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // Remove Keywords Searched
      .addCase(removeSearchKeywords.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removeSearchKeywords.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(removeSearchKeywords.rejected, (state, action) => {
        state.error = action?.error.message;
      });
  },
});

export const getProductOptions = state => {
  const { chosenOption, additionalOption, textOption, optionInfos } =
    state.mall.productDetail;

  return { chosenOption, additionalOption, textOption, optionInfos };
};

export const OptionsTypeSelector = createSelector(
  getProductOptions,
  options => {
    const { optionInfos } = options;

    const chosenOptions = optionInfos.filter(
      option => option.type === 'chosen',
    );
    const additionalOptions = optionInfos.filter(
      option => option.type === 'additional',
    );

    return {
      chosenOptions,
      additionalOptions,
    };
  },
);

export const {
  initEventList,
  setProductListForInfiniteScroll,
  initProductListForInfiniteScroll,
  initSearchProduct,
  initProductList,
  setProduct,
  setCurrentCategory,
  setOrderProductCount,
  setProductToCart,
  setProductOptionDetail,
  setTextOptionValue,
  setCurrentKeyword,
} = mallSlice.actions;
export default mallSlice.reducer;
