import {createAsyncThunk, createSelector, createSlice,} from '@reduxjs/toolkit';
import {
  fetchCouponData,
  fetchMainCoupon,
  fetchCouponCategory,
  fetchCouponBrandList
} from 'api/coupon';



const initialState = {

  status: 'idle',
  error: null,
  coupons: [],
  couponList: [],
  coupon:{},
  couponCategoryList: [],
  couponBrandList:[],
  currentCouponCategory :{},
  currentCouponBrand:{},
};

// coupon
export const getMainCoupon = createAsyncThunk(
  'coupon/list',
  async data => {
    const coupons = await fetchMainCoupon(data);

    return coupons;
  },
);

export const getCouponData = createAsyncThunk(
    'coupon/detail',
    async data => {
      return await fetchCouponData(data);
    },
);

export const getCouponCategory = createAsyncThunk(
    'coupon/category',
    async data => {
      const couponCategoryList = await fetchCouponCategory(data);

      return couponCategoryList;
    },
);

export const getCouponBrandList = createAsyncThunk(
    'coupon/brand',
    async data => {
      const couponBrandList = await fetchCouponBrandList(data);

      return couponBrandList;
    },
);



export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCouponList: (state, action) => {
      state.couponList = action.payload;
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    setCouponBrandList: (state, action) => {
      state.couponBrandList = action.payload;
    },

    setCurrentCouponCategory: (state, action) => {
      state.currentCouponCategory = action.payload;
    },
    setCurrentCouponBrand: (state, action) => {
      state.currentCouponBrand = action.payload;
    }
  },
  extraReducers: builder => {
    builder

    // get coupon list
    .addCase(getMainCoupon.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getMainCoupon.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.coupons = action.payload;
    })
    .addCase(getMainCoupon.rejected, (state, action) => {
      state.error = action?.error.message;
    })

    // get coupon data
    .addCase(getCouponData.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getCouponData.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.coupon = action.payload;
    })
    .addCase(getCouponData.rejected, (state, action) => {
      state.error = action?.error.message;
    })
    // get coupon category
    .addCase(getCouponCategory.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getCouponCategory.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.couponCategoryList = action.payload;
    })
    .addCase(getCouponCategory.rejected, (state, action) => {
      state.error = action?.error.message;
    })
      //get coupon brand
    .addCase(getCouponBrandList.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getCouponBrandList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.couponBrandList = action.payload;
    })
    .addCase(getCouponBrandList.rejected, (state, action) => {
      state.error = action?.error.message;
    })
  },
});

export const {
  setCurrentCouponBrand,
  setCurrentCouponCategory,
  setCouponList,
  setCoupon,
  setCouponBrandList,
} = couponSlice.actions;
export default couponSlice.reducer;
