import API from 'api';

export const fetchBanners = async () => {
  try {
    const response = await API.get('/v1/shopBanner/all');

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDailyBestItemBanner = async () => {
  try {
    const response = await API.get('/v1/shopBanner/dailyBestItem');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchWeeklyBestItemBanner = async () => {
  try {
    const response = await API.get('/v1/shopBanner/weeklyBestItem');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchSavingsItemsBanner = async sort => {
  try {
    const response = await API.get(`/v1/shopBanner/frugal${sort}Item`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
