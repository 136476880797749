import { Box, Grid, Typography } from '@mui/material';

import PageLayout from 'components/layout/PageLayout';
import Modal from 'components/Modal2';
import Divider from 'components/Divider';
import { parseStringToPrice, parseDate } from 'utils';
import { useSelector } from 'react-redux';
import DeliveryStatus from '../DeliveryStatus';

export default function OrderDetailInfo({
  open,
  setOpen,
  info,
  openDelivery,
  setOpenDelivery,
}) {
  const user = useSelector(state => state?.auth.user);

  return (
    <Modal open={open} closeButton onClose={() => setOpen(false)}>
      <DeliveryStatus
        open={openDelivery}
        setOpen={setOpenDelivery}
        info={info}
      />
      {info && (
        <PageLayout title="주문 상세정보" noBackButton={true}>
          <Divider thickness="13px" />
          <Box minHeight="calc(100vh - 208px)" px={3} pb={4}>
            <Box py={1.5}>
              <Typography fontWeight="bold" py={1}>
                주문정보
              </Typography>
              <TableCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="주문자명" />
                  <TdCustom w="70%" text={user.name} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="주문번호" />
                  <TdCustom w="70%" text={info.orderInfoId} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="주문일자" />
                  <TdCustom w="70%" text={parseDate(info.orderDate)} />
                </TrCustom>
              </TableCustom>
            </Box>
            <Divider thickness="5px" />
            <Box py={1.5}>
              <Typography fontWeight="bold" py={1}>
                주문상품
              </Typography>
              {info?.orderItems?.map(item => (
                <Grid container key={item.orderItemId} spacing={1} mb={2}>
                  <Grid item xs={3}>
                    <img
                      src={item.thumbnail}
                      alt={item.itemName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TableCustom>
                      <TrCustom>
                        <TdCustom
                          w="100%"
                          text={`상품제목 : ${item.itemName}`}
                        />
                      </TrCustom>
                      <TrCustom>
                        <TdCustom w="100%" text={`수량 : ${item.itemCount}`} />
                      </TrCustom>
                      <TrCustom>
                        <TdCustom
                          w="100%"
                          text={`옵션 : ${item?.orderItemOptions?.map(
                            option => option.optionName,
                          )}`}
                        />
                      </TrCustom>
                      <TrCustom>
                        <TdCustom
                          w="100%"
                          text={`가격 : ${parseStringToPrice(
                            item.itemPrice,
                          )}원`}
                        />
                      </TrCustom>
                    </TableCustom>
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Divider thickness="5px" />
            <Box py={1.5}>
              <Typography fontWeight="bold" py={1}>
                결제정보
              </Typography>
              <TableCustom>
                {/*   
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="총 상품수" />
                  <TdCustom w="70%" text={`${info.orderItems?.length}건`} />
                </TrCustom>
           
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="총 상품금액" />
                  <TdCustom w="70%" text={`${}`} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="배송비" />
                  <TdCustom w="70%" text={`info.`} />
                </TrCustom> */}
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="사용포인트" />
                  <TdCustom w="70%" text={`${info.totalUsePoint}`} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="최종 결제금액" />
                  <TdCustom
                    w="70%"
                    text={parseStringToPrice(info.totalPayment)}
                  />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="최종 결제수단" />
                  <TdCustom w="70%" text={info.paymentMethod} />
                </TrCustom>
              </TableCustom>

              <Typography fontWeight="bold" py={1}>
                수익금 적립내역
              </Typography>
              <TableCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="수익금 적립" />
                  <TdCustom
                    w="70%"
                    text={parseStringToPrice(info.totalGetPoint)}
                  />
                </TrCustom>
              </TableCustom>
              <Typography fontWeight="bold" py={1}>
                배송지 정보
              </Typography>
              <TableCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="받으시는 분" />
                  <TdCustom w="70%" text={info.recipientName} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="연락처" />
                  <TdCustom w="70%" text={info.recipientPhone} />
                </TrCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="주소" />
                  <TdCustom
                    w="70%"
                    text={`[${info.zipCode}] ${info.address} ${info.detailAddress}`}
                  />
                </TrCustom>
                {/* <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="배송메세지" />
                  <TdCustom w="70%" text={info} />
                </TrCustom> */}
              </TableCustom>
              <Typography fontWeight="bold" py={1}>
                배송 정보
              </Typography>
              <TableCustom>
                <TrCustom>
                  <TdCustom w="30%" bg="#eee" text="배송정보" />
                  <TdCustom
                    w="70%"
                    text="확인하기"
                    onClick={() => setOpenDelivery(true)}
                  />
                </TrCustom>
              </TableCustom>
            </Box>
          </Box>
        </PageLayout>
      )}
    </Modal>
  );
}

export const TableCustom = ({ children }) => (
  <table
    style={{
      width: '100%',
      marginBottom: '12px',
      border: '1px solid #eee',
      borderCollapse: 'collapse',
    }}
  >
    <tbody>{children}</tbody>
  </table>
);
export const TrCustom = ({ children }) => (
  <tr style={{ border: '1px solid #eee' }}>{children}</tr>
);
export const TdCustom = ({ w, bg, text, ...rest }) => (
  <td
    style={{
      width: w,
      padding: '10px',
      backgroundColor: bg,
      textIndent: '6px',
      fontSize: '0.8rem',
    }}
    {...rest}
  >
    {text}
  </td>
);
