import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  Grid,
  Pagination,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { parseStringToPrice, parseDate } from 'utils';

import Divider from 'components/Divider';
import CouponDetailInfo from './coupon/CouponDetailInfo';
import { fetchPaidOrderList , fetchPaidCouponOrderList} from 'api/order';
import { getPaidOrderList , getPaidCouponOrderList} from 'store/order';


// import Modal from 'components/Modal';

export default function Coupons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paidOrderTotalCount = useSelector(
    state => state?.order.paidOrderList.orderInfoCount,
  );

  const [paidOrderList, setPaidOrderList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [detailInfo, setDetailInfo] = useState();

  useEffect(async () => {
      const res = await fetchPaidCouponOrderList(1);
    //  dispatch(getPaidCouponOrderList(1));
     setPaidOrderList(res?.data.orderInfos);

  }, []);


  const countTotalPage = () => {
    if (typeof paidOrderTotalCount !== 'number') return;

    return Math.ceil(paidOrderTotalCount / 10);
  };

  const handleChangePage = async page => {
    const res = await fetchPaidOrderList(page);

    //dispatch(getPaidOrderList(page));

    setPaidOrderList(res.data.orderInfos);
  };

  return (
    <Box p={2}>
      <CouponDetailInfo
        open={open}
        setOpen={setOpen}
        info={detailInfo}
        openDelivery={openDelivery}
        setOpenDelivery={setOpenDelivery}
      />
      {(paidOrderList?.length === 0 || !paidOrderList) && (
        <Typography fontSize="0.9rem" sx={{ p: 4, textAlign: 'center' }}>
          쿠폰이 없습니다
        </Typography>
      )}
      {paidOrderList?.map(data => {

        return (
          <React.Fragment key={data.orderInfoId}>
            <Card sx={{ pt: 2, px: 2, mb: 1 }}>
              <Box
                mb={1}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography fontSize="0.9rem" color="grey.500">
                  {parseDate(data.orderDate)} 쿠폰
                </Typography>
                <Typography
                  fontSize="0.9rem"
                  color="grey.600"
                  onClick={() => {
                    setOpen(true);
                    setDetailInfo(data);
                  }}
                >
                  쿠폰상세 &gt;
                </Typography>
              </Box>
              <Grid container mb={1.2} gap={1}>
                <Grid
                  item
                  xs={3.5}
                >
                  <img
                    src={data.couponItems[0].goodsImg}
                    alt={data.couponItems[0].goodsName}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={7.5}>
                  <Typography>
                    {data.couponItems[0].goodsName}{' '}
                    <Typography
                      variant="span"
                      fontWeight="bold"
                      color="grey.600"
                    >
                      {data.couponItems?.length > 1 &&
                        ` 외 ${data.couponItems?.length - 1}건`}
                    </Typography>
                  </Typography>
                  <Typography>
                    <Typography variant="span" fontWeight="bold">
                      {' '}
                      {parseStringToPrice(data?.totalPayment)}
                    </Typography>
                    원
                  </Typography>
                  <Typography fontSize="0.8rem" fontWeight="bold" color="#FF203B">
                    수익금 {parseStringToPrice(data?.totalGetPoint)}P
                  </Typography>
                </Grid>
              </Grid>

              {/*<Divider sx={{ borderColor: 'grey.100' }} />*/}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                  mt: 1,
                }}
              >
              </Box>
            </Card>
          </React.Fragment>
        );
      })}
      {paidOrderList && paidOrderList?.length !== 0 && (
        <Stack
          spacing={2}
          sx={{ py: 3, ul: { display: 'flex', justifyContent: 'center' } }}
        >
          <Pagination
            count={countTotalPage()}
            shape="rounded"
            onChange={(e, currentPage) => handleChangePage(currentPage)}
          />
        </Stack>
      )}
    </Box>
  );
}
