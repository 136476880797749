import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export default function ScoreReview({
  scoreCounts = [0, 0, 0, 0, 0],
  totalReviewCount,
}) {
  const shallowScoreCounts = [...scoreCounts];
  const reversedScoreCounts = shallowScoreCounts.reverse();

  const calcPercentage = scoreCount =>
    Math.floor((scoreCount / totalReviewCount) * 100) + '%';

  return reversedScoreCounts.map((scoreCount, index) => (
    <Grid key={index} item>
      <ScoreContainer>
        <Score percent={calcPercentage(scoreCount)} />
      </ScoreContainer>
      <ScoreBarLabel>{reversedScoreCounts.length - index}점</ScoreBarLabel>
    </Grid>
  ));
}

const ScoreBarLabel = styled('dt')({
  marginTop: '3px',
  fontSize: '13px',
  fontWeight: 400,
  whiteSpace: 'nowrap',
});

const ScoreContainer = styled('dd')({
  position: 'relative',
  display: 'block',
  height: '45px',
  width: '6px',
  borderRadius: '6px',
  backgroundColor: '#f0f0f0',
  margin: '0 auto',
});

const Score = styled('span')(({ percent }) => ({
  backgroundColor: '#ffad0b',
  height: percent,
  position: 'absolute',
  right: 0,
  bottom: 0,
  left: 0,
  display: 'block',
  width: '6px',
  borderRadius: '6px',

  // animation
  transitionProperty: 'height',
  transitionDuration: '1s',
  transitionTimingFunction: 'ease',
  transitionDelay: '0s',
}));
