import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, CardMedia, CircularProgress, Divider, Grid, Typography} from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';
import { getMainCoupon, setCouponList, setCurrentCouponBrand} from 'store/coupon';
import PageLayout from 'components/layout/PageLayout';
import ItemsName from 'components/Items/ItemsName';
import { CardShapeItem } from 'components/coupon/container';
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar, Grid as Grid2} from "swiper";
import {useNavigate} from "react-router-dom";

import imageAll from 'assets/img/coupon/all.png';
import imageAllActive from 'assets/img/coupon/all-active.png';

export default function CouponListPanel() {

    let name = "";
    let shape = "col";
    let xs = 6;
    let tabIndex = 1;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const status = useSelector(state => state?.coupon.status);
    const coupones = useSelector(state => state?.coupon.coupons);
    const couponList = useSelector(state => state?.coupon.couponList);
    const couponBrandList = useSelector(state => state?.coupon.couponBrandList);
    const currentCouponCategory = useSelector(state => state?.coupon.currentCouponCategory);
    const [currenBrand, setCurrenBrand] = useState({name:"전체", value:"", image:""});
    const [page, setPage]  = useState(1);

    useEffect(() => {
        ( () => {
            if(currentCouponCategory?.name) {
                setPage(1)
                dispatch(setCouponList([]));
            }
            else
                navigate("/coupon")
        })();
    }, []);


    useEffect(() => {
        ( () => {
            setPage(1)
            dispatch(setCouponList([]));
            getCouponByPage(currentCouponCategory, currenBrand, page);
        })();
    }, [currenBrand]);



    useEffect(() => {
        ( () => {
            getCouponByPage(currentCouponCategory, currenBrand, page);
        })();
    }, [page]);


    useEffect(() => {
        ( () => {
            if(coupones?.length > 0 )
            addCouponIntoList(coupones);
        })();
    }, [coupones]);

    const addCouponIntoList = items => {
        const same = items.find(item => {
            const result = couponList?.find(data => data.itemId === item.itemId);
            return result;
        });
        if (same) return;

        const newList = [...couponList, ...items];
        dispatch(setCouponList(newList));
    };

    const getCouponByPage = (currentCategory,currentCouponBrand, p) => {
        const data = {
            category: currentCategory.value,
            brand: currentCouponBrand.value,
            page: p,
            count: 21
        };
        dispatch(getMainCoupon(data))
    };


    const handleClickBrand = (brand, index) => {
        dispatch(setCurrentCouponBrand(brand));
        setCurrenBrand(brand)
        console.log(currentCouponCategory)
        console.log(brand)
    };

    const changePage = () => {
        setPage(prev => prev + 1);
    };


    return (
        <PageLayout title={currentCouponCategory.name} noBackButton={false} footerOff>
            <Box sx={{ py: 0, px:1 }}>
                <Swiper
                    grid={{
                        rows: 1
                    }}
                    scrollbar={{
                        hide: false,
                        // dragSize: 10,
                        enabled: true,
                    }}
                    slidesPerView={4}
                    modules={[Grid2, Scrollbar]}
                    style={{ height: '150px'}}
                >
                    {couponBrandList?.map((brand, index) => {

                        return (
                            <SwiperSlide
                                key={brand.value}
                                data-code={brand.value}

                                onClick={() => {
                                    handleClickBrand(brand, index);
                                }}
                            >
                                <Box
                                    width={110}
                                    height={110}
                                >

                                    {brand.image &&
                                        <Box
                                        height={80}
                                        width={80}
                                        justifyContent={"center"}
                                        textAlign={"center"}
                                        m={"auto"}
                                        pt={0}
                                        border={"solid"}
                                        borderColor={currenBrand.name === brand.name? "#FF203B":"#E5E5E5"}
                                        borderRadius={"50%"}
                                        overflow={"hidden"}
                                        >
                                        <CardMedia component="img" src={brand.image}   />
                                    </Box> }

                                    {!brand.image &&
                                        <Box
                                            height={80}
                                            width={80}
                                            justifyContent={"center"}
                                            textAlign={"center"}
                                            m={"auto"}
                                            pt={0}
                                            border={"solid"}
                                            borderColor={currenBrand.name === brand.name? "#FF203B":"#E5E5E5"}
                                            borderRadius={"50%"}
                                            overflow={"hidden"}
                                        >
                                            <CardMedia component="img"
                                                       src={currenBrand.name === brand.name? imageAllActive:imageAll}
                                            />
                                        </Box> }

                                     <Typography
                                         fontSize={currenBrand.name === brand.name? "14px" : "12px"}
                                        align="center"
                                        pt={1}
                                        color={currenBrand.name === brand.name? "#FF203B":"#757575"}
                                        value={brand.value}
                                        fontWeight={currenBrand.name === brand.name? "bold":"normal"}
                                    >
                                        {brand.name}
                                    </Typography>
                                </Box>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
            <Divider />
            <Box ml={2} mr={2} mb={2} mt={0} >
                <>
                    {status === 'loading' && (
                        <Box
                            sx={{
                                pt: 10,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    <InfiniteScroll
                        dataLength={couponList?.length}
                        next={changePage}

                        hasMore={coupones?.length !== 0}
                        loader={""}
                        endMessage={
                            status === 'succeeded' && (
                                ""
                            )
                        }
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gap: 1,
                                p: 2,
                            }}
                        >
                            {couponList?.map(item => (
                                <CardShapeItem product={item} key={item.productId} />
                            ))}

                        </Box>
                    </InfiniteScroll>
                </>
            </Box>
        </PageLayout>
    );
}

