import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Pagination, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import { parseDate, parseStringToPrice } from 'utils';
import { fetchUserPoint } from 'api/user';

import Divider from 'components/Divider';
import PageLayout from 'components/layout/PageLayout';

export default function PointHistory() {
  const user = useSelector(state => state?.auth.user);

  const [pointHistory, setPointHistory] = useState([]);

  useEffect(() => {
    getUserPointHistory(1);
  }, []);

  const getUserPointHistory = async page => {
    const res = await fetchUserPoint({
      page: page,
      size: 20,
      sign: null,
    });
    setPointHistory(res);
  };
  const handleChangePage = async page => {
    const res = await fetchUserPoint({
      page: page,
      size: 20,
      sign: null,
    });

    setPointHistory(res);
  };

  return (
    <PageLayout
      title="포인트 적립내역"
      sx={{
        backgroundColor: '#f2f3f5',
      }}
    >
      <Box
        variant="h6"
        sx={{
          width: 1,
          py: 1.8,
          borderBottom: '2.5px solid',
          borderColor: 'grey.200',
          textAlign: 'center',
          color: '#222',
          fontSize: '15px',
          fontWeight: '600',
        }}
      >
        포인트 합계
        <Typography mb={1.5} fontWeight="600" color="#FFC94A" fontSize="30px">
          {parseStringToPrice(user.point)}{' '}
          <span style={{ fontSize: '23px' }}>P</span>
        </Typography>
        <Typography fontSize="0.8rem" color="grey.400">
          광고적립과 쇼핑적립 내역이 합산된 포인트입니다
        </Typography>
      </Box>
      <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
        {pointHistory?.page?.length === 0 ? (
          <Typography
            sx={{ width: 1, p: 4, textAlign: 'center', fontSize: '0.9rem', paddingTop:"24vh" , paddingBottom:"24vh" }}
          >
            내용이 없습니다
          </Typography>
        ) : (
          <>
            <Box sx={{ minHeight: '450px' }}>
              {pointHistory?.page?.map((item, idx) => (
                <React.Fragment key={idx}>
                  {item.point === 0 || (
                    <>
                      <Grid
                        container
                        px={2}
                        py={2.5}
                        justifyContent="space-between"
                      >
                        <Grid item xs={9}>
                          <Typography fontSize="0.9rem" color="grey.400">
                            {item?.accumulationDateTime &&
                              parseDate(item?.accumulationDateTime)}
                          </Typography>
                          <Typography fontSize="0.8rem">
                            {item.activity}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                          }}
                        >
                          <Typography
                            variant="span"
                            fontSize="0.9rem"
                            color={
                              item.sign === 'PLUS'
                                ? 'common.black'
                                : 'primary.main'
                            }
                          >
                            {item.sign === 'MINUS' ? '-' : '+'}{' '}
                            {parseStringToPrice(item.point)}{' '}
                            <Typography variant="span" fontSize="0.8rem">
                              P
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  )}
                </React.Fragment>
              ))}
            </Box>
            <Stack
              spacing={2}
              sx={{ py: 3, ul: { display: 'flex', justifyContent: 'center' } }}
            >
              <Pagination
                shape="rounded"
                count={pointHistory?.totalPage}
                onChange={(e, currentPage) => handleChangePage(currentPage)}
              />
            </Stack>
          </>
        )}
      </Box>
    </PageLayout>
  );
}
