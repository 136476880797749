import { useEffect, useState } from 'react';
import { Collapse, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';

export default function AgreeTermsDetail({ termsTitle, termsDetail }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, []);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem component="li" sx={{ display: 'flex', py: '4px' }}>
        <ListItemText
          sx={{
            span: {
              fontSize: '0.875rem',
              color: '#000',
            },
          }}
          primary={termsTitle}
          onClick={handleClick}
        />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout={0} unmountOnExit>
        <Box pt={3} pb={3} px={2} bgcolor="#eee" sx={{ fontSize: '0.8rem' }}>
          {termsDetail}
        </Box>
      </Collapse>
    </>
  );
}
