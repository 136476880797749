import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
} from '@mui/material';
import TextSet from 'components/Items/TextSet';
import { changeActiveTab } from 'store/app';
import { useDispatch } from 'react-redux';

export default function CardShapeItem({ product, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClickItem = e => {
    dispatch(changeActiveTab({ name: 'product', nextTabIdx: 0 }));
    navigate('/product/' + e.currentTarget.id);
  };
  return (
    <Card
      sx={{
        mb: 3,
      }}
      {...rest}
    >
      <CardActionArea onClick={handleOnClickItem} id={product.itemId}>
        <CardMedia
          component="img"
          src={product.thumbNail}
          height="180"
          // width="100%"
          sx={{
            objectFit: 'cover',
            aspectRatio: '1 / 2',
          }}
        />
        <CardContent>
          <TextSet product={product} />
          <Grid container justifyContent="end">
            {/* Todo: Need to be Button */}
            {/* <Typography fontSize="14px" fontWeight="bold" color="#FF203B">
          구매하기
        </Typography> */}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
