import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { initProductList, initProductListForInfiniteScroll } from 'store/mall';
import { setCurrentSortBy } from 'store/app';

export default function SortButton({ children, name, value, ...rest }) {
  const dispatch = useDispatch();
  const sortBy = useSelector(state => state?.app.sortBy);
  const currentSortBy = useSelector(state => state?.app.sortBy[name]);
  const styles = getStyles(currentSortBy, value);

  const handleOnClickButton = async e => {
    const { value } = e.target;

    if (sortBy[name] === value) return;

    dispatch(initProductList());
    dispatch(setCurrentSortBy({ name, value }));
    dispatch(initProductListForInfiniteScroll());
  };

  return (
    <Button sx={styles} value={value} onClick={handleOnClickButton} {...rest}>
      {children}
    </Button>
  );
}

const getStyles = (currentSortBy, sortBy) => {
  return {
    height: '40px',

    '&.MuiButton-text': {
      color: currentSortBy === sortBy ? '#191919' : '#999999',
      font:
        currentSortBy === sortBy
          ? 'normal normal bold 14px/20px Noto Sans CJK KR'
          : 'normal normal bold 14px/20px Noto Sans CJK KR',
    },
  };
};
