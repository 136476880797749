import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { HOME_TAB_NAMES } from 'contants';

import TabBar from 'components/layout/TabBar';
import TabPanel from 'components/layout/TabPanel';
import Footer from 'components/layout/Footer';

import StorePanel from './StorePanel/index';
import CouponPanel from './CouponPanel';
import AllianceShoppingPanel from './AllianceShoppingPanel';

import { getEventItemById } from 'store/mall';

export default function Home() {
  const user = useSelector(state => state?.auth.user);
  const dispatch = useDispatch();
  const currentTab = useSelector(state => state?.app.activeTab.home);

  useEffect(() => {
    dispatch(getEventItemById({ id: 2, page: 1 }));
  }, []);

  return (
    <React.Fragment>
      <StorePanel />
      <Footer />
    </React.Fragment>
  );
}

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          maxWidth: '100%',
          justifyContent: 'space-between',
        },
        indicator: {
          backgroundColor: '#191919',
          height: '3px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          minWidth: 0,
          '&.Mui-selected': {
            font: 'normal normal bold 14px/20px Noto Sans CJK KR',
            color: '#191919',
          },
        },
      },
    },
  },
});
