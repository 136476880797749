import { Typography } from '@mui/material';

import PageLayout from 'components/layout/PageLayout';

export default function PaymentFailure() {
  return (
    <PageLayout noBackButton>
      <Typography
        variant="h5"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        결제에 실패하였습니다.
      </Typography>
    </PageLayout>
  );
}
