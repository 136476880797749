import { useState } from 'react';
import { Typography, Box } from '@mui/material';

import Modal from 'components/Modal2';
import { terms } from 'contants/terms';
import Header from 'components/layout/Header';

export default function Footer() {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  return (
    <Box bgcolor="#f7f7f7" >
      <Modal open={open} onClose={setOpen} closeButton>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
          }}
        >
          <Header
            noBackButton
            title={content === '이용약관' ? '이용약관' : '개인정보보호정책'}
          />
        </Box>
        <Box p={2}></Box>
        {content === '이용약관' ? terms[0].detail : terms[1].detail}
      </Modal>
      <Box p={2} fontSize="0.8rem" color="#a1a1a1">
        <Typography mb={1} fontSize="0.8rem" color="#131313" fontWeight="bold">
          애즈워드(주)
        </Typography>
        <Typography fontSize="0.8rem">
          사업자등록번호 : 772-86-01506
          <br />
          통신판매업신고번호 : 제 2022-서울중구-0746호
          <br />
          주소 : 서울특별시 중구 한강대로 416, 서울스퀘어 14층 125-15호(남대문로
          5가)
        </Typography>
        <Typography
          variant="span"
          pr={2}
          sx={{ borderRight: '2px solid #eee' }}
        >
          대표자 : 고영남
        </Typography>
        <Typography variant="span" pl={2} pr={3}>
          대표번호 : 070-7954-4320
        </Typography>
        <Box mt={1}>
          <Typography
            variant="span"
            color="grey.600"
            fontWeight="600"
            pr={1}
            sx={{ borderRight: '2px solid #eee' }}
            onClick={() => {
              setContent('이용약관');
              setOpen(true);
            }}
          >
            이용약관
          </Typography>
          <Typography
            variant="span"
            color="grey.600"
            fontWeight="600"
            pl={1}
            onClick={() => {
              setContent('개인정보보호정책');
              setOpen(true);
            }}
          >
            개인정보보호정책
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
