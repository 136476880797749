import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Modal({
  children,
  open,
  onClose,
  closeButton,
  ...rest
}) {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      {closeButton && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            color: 'common.white',
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      {children}
    </Dialog>
  );
}
