import API from 'api';

export const fetchPaidOrderList = async page => {
  try {
    const response = await API.post('v1/shopOrder/paidOrderList', {
      page: page,
      size: 10,
      viewDirection: 'DESC',
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPaidCouponOrderList = async page => {
  try {
    const response = await API.post('v1/shopOrder/paidCouponOrderList', {
      page: page,
      size: 10,
      viewDirection: 'DESC',
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetachOrderPurchaseConfirmation = async id => {
  try {
    const response = await API.post('v1/shopOrder/orderPurchaseConfirmation', {
      orderInfoId: id,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    if (error === "this order info's purchase confirmation is already true")
      alert('이미 구매확정한 주문입니다');
  }
};

export const fetchOrderByOrderItems = async data => {
  try {
    const response = await API.post('v1/shopOrder/orderByOrderItems', data);

    return response.data;
  } catch (error) {
    console.error(error);
    const pointErrMsg =
      'To use points, you can use points as much as the payment amount, or only use points less than half of the payment amount';
    const phoneNumberErrMsg = 'phone number validation check error';

    if (error.response.data.error === pointErrMsg) {
      alert(
        '배송비 포함한 결제금액중 50%까지만 포인트 차감 가능합니다. (단, 결제금액 100% 포인트 차감으로 공짜구매 가능합니다.)',
      );
      return;
    }
    if (error.response.data.error === phoneNumberErrMsg) {
      alert('휴대폰 번호를 확인해주세요)');
      return;
    }
    alert('오류가 발생했습니다');
  }
};

export const fetchOrderOneItem = async data => {
  try {
      console.log(data);
    const response = await API.post('v1/shopOrder/order', data);

    return response.data;
  } catch (error) {
    console.error(error);
    const pointErrMsg =
      'To use points, you can use points as much as the payment amount, or only use points less than half of the payment amount';
    const phoneNumberErrMsg = 'phone number validation check error';

    if (error.response.data.error === pointErrMsg) {
      alert(
        '배송비 포함한 결제금액중 50%까지만 포인트 차감 가능합니다. (단, 결제금액 100% 포인트 차감으로 공짜구매 가능합니다.)',
      );
      return;
    }
    if (error.response.data.error === phoneNumberErrMsg) {
      alert('휴대폰 번호를 확인해주세요)');
      return;
    }
    alert('오류가 발생했습니다');
  }
};

export const fetchShippingInfo = async id => {
  try {
    const response = await API.get(`v1/shopOrder/shippingInfo/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPaymentByPoint = async id => {
  try {
    const response = await API.post(`v1/shopOrder/paymentByPoint`, {
      orderInfoId: id,
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
