import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar as _AppBar, Grid, IconButton } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawer } from 'store/app';

import { ReactComponent as MenuIcon } from 'assets/icons/hamburger.svg';
import { ReactComponent as AdswordLogoW } from 'assets/icons/adsward_w.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as Cart } from 'assets/icons/cart_header.svg';

import Drawer from 'components/Drawer';
import Menu from './Menu';
import Search from 'pages/Search';

import { changeActiveTab } from 'store/app';

export default function AppBar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpenDrawerMenu = useSelector(state => state?.app.isOpenDrawer.menu);
  const isOpenDrawerSearch = useSelector(
    state => state?.app.isOpenDrawer.search,
  );
  const handleToggleMenu = e => {
    let name = '';
    if (
      e.currentTarget.name === 'menu' ||
      e.currentTarget.getAttribute('name') === '마이페이지' ||
      e.currentTarget.getAttribute('name') === 'FAQ'
    )
      name = 'menu';
    dispatch(
      toggleDrawer({
        name: e.currentTarget.name === 'search' ? e.currentTarget.name : name,
        isOpenDrawer: name === 'menu' ? !isOpenDrawerMenu : !isOpenDrawerSearch,
      }),
    );
  };

  return (
    <React.Fragment>
      <Drawer name="menu">
        <Menu onClick={handleToggleMenu} />
      </Drawer>

      <Drawer name="search" anchor="right">
        <Search onClick={handleToggleMenu} />
      </Drawer>

      <_AppBar position="sticky" style={{ backgroundColor: '#FF203B' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          pt={0.5}
          pb={0.5}
          pl={1}
          pr={2}
        >
          <Grid item>
            <IconButton name="menu" onClick={handleToggleMenu}>
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid item ml={4}>
            <IconButton
              onClick={() => {
                navigate('/');
                dispatch(changeActiveTab({ name: 'home', nextTabIdx: 0 }));
              }}
            >
              <AdswordLogoW />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton edge="start" onClick={() => navigate('/cart')}>
              <Cart />
            </IconButton>

            <IconButton
              edge="end"
              name="search"
              onClick={() => navigate('/search')}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </_AppBar>
    </React.Fragment>
  );
}
