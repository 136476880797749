import { Button } from '@mui/material';

export default function OrderButton({ children, ...rest }) {
  return (
    <Button
      fullWidth
      sx={{ borderRadius: 2, fontSize: '16px', fontWeight: 900 }}
      {...rest}
    >
      {children}
    </Button>
  );
}
