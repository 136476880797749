import { Container as _Container } from '@mui/material';

export default function Container({ children, ...rest }) {
  return (
    <_Container component="main" maxWidth="xs" disableGutters {...rest}>
      {/* Todo: figure out when exactly use main tag!!! */}
      {children}
    </_Container>
  );
}
