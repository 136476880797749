import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Grid, Box, Typography, IconButton, CardMedia } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { parseStringToPrice } from 'utils';
import Divider from 'components/Divider';
export default function ItemDisplayForPayment() {
  const orderDetail = useSelector(state => state?.order.orderDetail);
  const productOptionDetail = useSelector(
    state => state?.mall.productOptionDetail,
  );
  const user = useSelector(state => state?.auth.user);
  const orderCartItems = useSelector(state => state?.cart.orderCartItems);
  const orderItemsInfo = useSelector(state => state?.cart.orderItemsInfo);
  const orderItemsInfoForDisplay = useSelector(
    state => state?.cart.orderItemsInfoForDisplay,
  );

  const dispatch = useDispatch();

  const productDetail = useSelector(state => state?.mall.productDetail);

  const [productInfoBoxOpen, setProductInfoBoxOpen] = useState(true);

  let navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: '#ffffff',
        border: '1px solid #e5e5e5',
        borderRadius: 2,
      }}
      m={2}
      p={2}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography fontSize="16px" fontWeight={700}>
            주문상품
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <IconButton onClick={() => setProductInfoBoxOpen(prev => !prev)}>
              {productInfoBoxOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {productInfoBoxOpen &&
        (orderItemsInfoForDisplay?.length === 1 || !orderCartItems) && (
          <>
            <Grid container spacing={1.5} mt={0.5}>
              {productOptionDetail?.map((option, idx) => (
                <React.Fragment key={productDetail?.itemId + idx}>
                  <Grid item xs={2.5}>
                    <CardMedia component="img" src={productDetail?.thumbNail} />
                  </Grid>
                  <Grid item xs={9.5}>
                    {/* 옵션없음, textOption */}
                    {productOptionDetail?.length === 1 &&
                      (option.type === 'none' || option.type === 'text') && (
                        <>
                          <Typography mb={1}>{orderDetail.itemName}</Typography>
                          <Typography component="span" pr={1}>
                            {parseStringToPrice(productDetail.price)}원
                          </Typography>
                          <Typography
                            component="span"
                            pl={1}
                            sx={{ borderLeft: '1px solid #e5e5e5' }}
                          >
                            {orderCartItems ? (
                              option.qty
                            ) : (
                              <>
                                {Boolean(productDetail.chosenOption)
                                  ? option.qty
                                  : orderDetail.itemCount}
                              </>
                            )}
                            개
                          </Typography>
                        </>
                      )}
                    {/* 기타옵션만 있을때 상품명 노출(옵션명 노출은 아래 따로있음) */}
                    {productOptionDetail?.length !== 0 &&
                      option.type === 'noneAndAdditional' && (
                        <>
                          <Typography mb={1}>{orderDetail.itemName}</Typography>
                          <Typography component="span" pr={1}>
                            {parseStringToPrice(productDetail.price)}원
                          </Typography>
                          <Typography
                            component="span"
                            pl={1}
                            sx={{ borderLeft: '1px solid #e5e5e5' }}
                          >
                            {orderCartItems ? (
                              option.qty
                            ) : (
                              <>
                                {Boolean(productDetail.chosenOption)
                                  ? option.qty
                                  : orderDetail.itemCount}
                              </>
                            )}
                            개
                          </Typography>
                        </>
                      )}
                    {/* 필수옵션이 있고, (기타 옵션이 있고), 기타옵션 이름이 '추가옵션'으로 시작하지 않음 */}

                    {productDetail.chosenOption &&
                      !option?.name?.includes('추가옵션') && (
                        <>
                          <Typography mb={1}>{productDetail.name}</Typography>
                          <Typography component="span" pr={1}>
                            {parseStringToPrice(productDetail.price)}원
                          </Typography>
                          {productDetail.chosenOption && (
                            <Typography
                              component="span"
                              pl={1}
                              pr={1}
                              sx={{ borderLeft: '1px solid #e5e5e5' }}
                            >
                              {productDetail.chosenOption}:{option.name}
                            </Typography>
                          )}
                          <Typography
                            component="span"
                            pl={1}
                            sx={{ borderLeft: '1px solid #e5e5e5' }}
                          >
                            {orderCartItems ? (
                              option.qty
                            ) : (
                              <>
                                {Boolean(productDetail.chosenOption)
                                  ? option.qty
                                  : orderDetail.itemCount}
                              </>
                            )}
                            개
                          </Typography>
                        </>
                      )}
                    {/* 기타옵션 */}
                    {option.type === 'additional' && (
                      <Box sx={{ pt: 1 }}>
                        {option.name} / {option.qty}개 /{' '}
                        {parseStringToPrice(option.price)}원 :{' '}
                        {parseStringToPrice(option.price * option.qty) + '원'}
                      </Box>
                    )}
                    {/* 텍스트옵션이 있음 */}
                    {option.type === 'text' && (
                      <Box>옵션 : {option.textOption}</Box>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
              {/* <Grid item xs={2.5}>
                <CardMedia component="img" src={productDetail.thumbNail} />
              </Grid>

              <Grid item xs={9.5}>
                <Typography mb={1}>{productDetail.name}</Typography>
                <Typography component="span" pr={1}>
                  {parseStringToPrice(productDetail.price)}원
                </Typography>

                <Typography
                  component="span"
                  pl={1}
                  sx={{ borderLeft: '1px solid #e5e5e5' }}
                >
                  {orderDetail.itemCount}개
                </Typography>
              </Grid> */}
            </Grid>
          </>
        )}

      {/* 장바구니 아이템 여러 개 주문 */}
      {productInfoBoxOpen &&
        orderCartItems &&
        orderItemsInfoForDisplay?.length !== 1 && (
          <>
            <Grid container spacing={1.5} mt={0.5}>
              {orderItemsInfoForDisplay?.map((orderItem, idx) => (
                <React.Fragment key={orderItem.itemId + idx}>
                  <Grid item>
                    {/* 옵션없음 */}
                    {orderItem.itemOptionInfo?.length === 0 && (
                      <Grid
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 4fr',
                          gap: 1.2,
                        }}
                      >
                        <Grid>
                          <CardMedia
                            component="img"
                            width="100%"
                            src={orderItem.thumbNail}
                            alt={orderItem.itemName}
                          />
                        </Grid>
                        <Grid>
                          <Typography mb={1}>{orderItem.itemName}</Typography>
                          <Typography component="span" pr={1}>
                            {parseStringToPrice(orderItem.itemPricePerCount)}원
                          </Typography>
                          <Typography
                            component="span"
                            pl={1}
                            sx={{ borderLeft: '1px solid #e5e5e5' }}
                          >
                            {orderItem.itemCount}개
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {/* 필수옵션이 있고, (기타 옵션이 있고), 기타옵션 이름이 '추가옵션'으로 시작하지 않음 */}
                    {orderItem.itemOptionInfo?.map((option, idx) => (
                      <Grid
                        key={option.optionId + idx}
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 4fr',
                          gap: 1.2,
                        }}
                      >
                        <Grid>
                          <CardMedia
                            component="img"
                            width="100%"
                            src={orderItem.thumbNail}
                            alt={orderItem.itemName}
                          />
                        </Grid>
                        {option.optionType === 'chosen' &&
                          !option?.name?.includes('추가옵션') && (
                            <Grid>
                              <Typography mb={1}>
                                {orderItem.itemName}
                              </Typography>
                              <Typography component="span" pr={1}>
                                {parseStringToPrice(
                                  orderItem.itemPricePerCount,
                                )}
                                원
                              </Typography>

                              {option?.optionName && (
                                <Typography
                                  component="span"
                                  pl={1}
                                  pr={1}
                                  sx={{ borderLeft: '1px solid #e5e5e5' }}
                                >
                                  {option.optionName}
                                </Typography>
                              )}

                              <Typography
                                component="span"
                                pl={1}
                                sx={{ borderLeft: '1px solid #e5e5e5' }}
                              >
                                {option.optionCount}개
                              </Typography>
                            </Grid>
                          )}

                        {/* 기타옵션이 있음 */}
                        {option.optionType === 'additional' && (
                          <>
                            <Grid>
                              <Typography mb={1}>
                                {orderItem.itemName}
                              </Typography>
                              <Typography component="span" pr={1}>
                                {parseStringToPrice(
                                  orderItem.itemPricePerCount,
                                )}
                                원
                              </Typography>

                              <Typography
                                component="span"
                                pl={1}
                                sx={{ borderLeft: '1px solid #e5e5e5' }}
                              >
                                {orderItem.itemCount}개
                              </Typography>
                              <Typography>
                                {option.optionName} / {option.optionCount}개 /{' '}
                                {parseStringToPrice(option.optionPrice)}원 :{' '}
                                {parseStringToPrice(
                                  option.optionPrice * option.optionCount,
                                ) + '원'}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {/* 텍스트옵션이 있음 */}
                        {option.type === 'text' && (
                          <Typography variant="span">
                            옵션 : {option.textOption}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ))}
              {/* <Grid item xs={2.5}>
                <CardMedia component="img" src={productDetail.thumbNail} />
              </Grid>

              <Grid item xs={9.5}>
                <Typography mb={1}>{productDetail.name}</Typography>
                <Typography component="span" pr={1}>
                  {parseStringToPrice(productDetail.price)}원
                </Typography>

                <Typography
                  component="span"
                  pl={1}
                  sx={{ borderLeft: '1px solid #e5e5e5' }}
                >
                  {orderDetail.itemCount}개
                </Typography>
              </Grid> */}
            </Grid>
          </>
        )}
    </Box>
  );
}
