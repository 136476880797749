import { Box, Button, IconButton } from '@mui/material';

import { useState, useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';

export default function Modal2({
  children,
  open,
  onClose,
  closeButton,
  ...rest
}) {
  const top = useRef(null);

  useEffect(() => {
    top.current.scrollIntoView();

    if (!open) return;

    const body = document.body;
    body.style.overflow = 'hidden';

    return () => {
      body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <Box
      open={open}
      {...rest}
      sx={{
        overflow: 'auto',
        display: open ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1300,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#fff',
      }}
    >
      {closeButton && (
        <IconButton
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: '1400',
            color: 'common.white',
          }}
          onClick={() => onClose()}
        >
          <CloseIcon
            sx={{ fontWeight: 300, fontSize: '2.2rem', color: 'common.black' }}
          />
        </IconButton>
      )}
      <div ref={top}></div>
      {children}
    </Box>
  );
}
