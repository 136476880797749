import React from 'react';

import StepLayout from "../../components/layout/StepLayout";
import BottomNavigation from '../../components/layout/BottomNavigation';

export default function Profile() {

  return (
      <React.Fragment>
        <StepLayout title="Stepper" sx={{ minHeight: 'calc(165vh + 100px)', maxHeight: 'calc(200vh + 100px)', padding:"20px"}} >
        </StepLayout>
        <BottomNavigation />
      </React.Fragment>

  );
}

