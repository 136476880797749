import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { CardMedia, IconButton, Box, LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Cart } from 'assets/icons/cart_header.svg';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import OrderSection from 'pages/product/OrderSection';

import { PRODUCT_TAB_NAMES } from 'contants';

import Container from 'components/atom/Container';
import ScrollTop from 'components/ScrollTop';

import TextSet from 'components/Items/TextSet';
import TabBar from 'components/layout/TabBar';

import TabPanel from 'components/layout/TabPanel';
import Divider from 'components/Divider';
import OrderButton from 'components/product/OrderButton';

import AccordionSection from 'pages/product/AccordionSection';
import DetailsSection from 'pages/product/DetailsSection';
import Review from 'pages/product/Review';
import { getProductDetailById, setProductOptionDetail } from 'store/mall';
import { changeActiveTab, setIsOpenBox } from 'store/app';
import DeliveryPolicy from './DeliveryPolicy';
import Footer from 'components/layout/Footer';
import { Search } from '@mui/icons-material';

export default function Product() {
  const params = useParams();
  const currentTab = useSelector(state => state?.app.activeTab.product);
  const isOpenBox = useSelector(state => state?.app.isOpenBox.order);
  const product = useSelector(state => state?.mall.productDetail);
  const status = useSelector(state => state?.mall.productStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsOpenBox({ name: 'order', value: false }));
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getProductDetailById({ itemId: params.productId }));
    })();
  }, []);

  useEffect(() => {
    addNoOptionProductInfo();
  }, [product]);

  useEffect(() => {
    if (status === 'loading') {
      return <LinearProgress />;
    }
  }, [status]);

  function addNoOptionProductInfo() {
    if (status === 'idle' || status === 'loading') return;
    if (
      product.optionInfos?.length === 0 ||
      product.optionInfos[0].name.includes('추가옵션')
    ) {
      dispatch(
        setProductOptionDetail([
          {
            type: 'none',
            qty: 1,
          },
        ]),
      );
      return;
    }
  }

  const copyUrl = () => {
    const currentUrl = window.location.href;

    var input = document.createElement('input');
    document.body.appendChild(input);
    input.value = currentUrl;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    alert('주소가 복사되었습니다');

    // const currentUrl = window.location.href;
    // navigator.clipboard
    //   .writeText(currentUrl)
    //   .then(() => alert('주소가 복사되었습니다.'));
  };

  return (
    <>
      <Container
        sx={{
          position: 'fixed',
          '&::before': {
            content: `""`,
            opacity: '0.27',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            display: 'block',
            height: '80px',
            background: 'linear-gradient(#000 0%, rgba(0, 0, 0, 0) 100%)',
          },
        }}
      >
        {status === 'succeeded' && (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', pr: 1.2 }}
          >
            <Box>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ p: 0, pl: '12px', py: '12px', color: '#fff' }}
                aria-label="go back"
                size="large"
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  navigate('/');
                  dispatch(changeActiveTab({ name: 'home', nextTabIdx: 0 }));
                }}
                sx={{ p: 0, py: '12px', color: '#fff' }}
                aria-label="go home"
                size="large"
              >
                <HomeOutlinedIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton onClick={() => navigate('/cart')}>
                <Cart />
              </IconButton>
              <IconButton onClick={() => navigate('/search')}>
                <Search sx={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Container>
      {status === 'loading' && <LinearProgress />}
      <CardMedia
        // ref={scrollTopAnchorRef}
        component="img"
        image={status === 'succeeded' ? product.thumbNail : ''}
        sx={{ height: '400px', p: 2 }}
      />

      <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {status === 'succeeded' && (
          <>
            <TextSet product={product} />
            <IconButton aria-label="share" onClick={copyUrl}>
              <ShareOutlinedIcon />
            </IconButton>
          </>
        )}
      </Box>

      <Divider color="#F1F1F5" thickness="thick" />

      <ThemeProvider theme={theme}>
        <TabBar
          name="product"
          dataset={PRODUCT_TAB_NAMES}
          activeTab={currentTab}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1200,
            backgroundColor: '#fff',
          }}
        />
      </ThemeProvider>

      {status === 'succeeded' && (
        <>
          {PRODUCT_TAB_NAMES.map((name, index) => {
            return (
              <TabPanel key={name + index} name="product" tabIndex={index}>
                {index === 0 && <DetailsSection />}
                {index === 1 && <Review />}
                {index === 2 && <DeliveryPolicy />}
              </TabPanel>
            );
          })}

          <Divider color="#F1F1F5" thickness="thick" />

          {/* <Items
        name="비슷한 상품 더보기"
        shape="col"
        productImages={DETAILS_PAGE_PRODUCT_IMAGES}
        xs={6}
      />

      <AccordionSection name="상품 정보 공시">
        <Typography>상품 정보 공시 내용</Typography>
      </AccordionSection>
      <AccordionSection name="배송/교환/환불">
        <Typography>배송/교환/환불 내용</Typography>
      </AccordionSection> */}

          <Divider color="#F1F1F5" thickness="thick" />

          <StickyBox open={isOpenBox}>
            {!isOpenBox && (
              <OrderButton
                variant="contained"
                name="order"
                onClick={e => {
                    if(product.error === product.itemId + "-soldOut")
                    {
                        alert("제품품절");
                        navigate(-1);
                    }
                    else{
                        dispatch(
                            setIsOpenBox({
                                name: e.target.name,
                                value: true,
                            }),
                        );
                    }

                }}
              >
                주문하기
              </OrderButton>
            )}

            {isOpenBox && <OrderSection />}
          </StickyBox>
        </>
      )}

      <ScrollTop />
      <Footer />
    </>
  );
}

const StickyBox = ({ children, open }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 1200,
        backgroundColor: '#ffffff',
        bottom: 0,
        p: 1.5,
        borderRadius: open && '30px 30px 0 0',
        boxShadow: '0px -3px 6px rgb(0 0 0 / 10%)',
      }}
    >
      {children}
    </Box>
  );
};

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: 'space-evenly',
        },
        indicator: {
          backgroundColor: '#191919',
          height: '3px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          minWidth: 0,
          '&.Mui-selected': {
            font: 'normal normal bold 14px/20px Noto Sans CJK KR',
            color: '#191919',
          },
        },
      },
    },
  },
});
