import React, { useEffect } from 'react';
import {
  fetchLinkPriceCategoryList,
  fetchLinkPriceCategoryListItems,
  fetchLinkPriceItemsBySearch,
} from 'api/otherShop';
import { useState } from 'react';

import {
  Box,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import ScrollTop from 'components/ScrollTop';
import Modal from 'components/Modal2';
import UserPointSection from "./StorePanel/UserPointSection";

export default function AllianceShoppingPanel() {
  const [linkPriceList, setLinkPriceList] = useState();
  const [linkPriceCategories, setLinkPriceCategories] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [tabValue, setTabValue] = useState(1);
  const [searched, setSearched] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningContent, setWarningContent] = useState('');

  useEffect(async () => {
    const data = await fetchLinkPriceCategoryList();

    setLinkPriceCategories(data);
  }, []);

  useEffect(async () => {
    if (!linkPriceCategories) return;
    const listItemsData = await fetchLinkPriceCategoryListItems('');

    setLinkPriceList(listItemsData);
  }, [linkPriceCategories]);

  const handleSearchInput = e => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleChange = (e, value) => {
    setTabValue(value);
  };
  const handleOnClickCategory = async name => {
    let itemsByCategory = [];
    if (name === '전체') {
      itemsByCategory = await fetchLinkPriceCategoryListItems('');
    } else {
      itemsByCategory = await fetchLinkPriceCategoryListItems(name);
    }

    setLinkPriceList(itemsByCategory);
  };

  const searchWithKeyword = async (keyword, category) => {
    const data = await fetchLinkPriceItemsBySearch(keyword, category, 1);

    setLinkPriceList(data);
    setTabValue(0);
    setSearched(true);
  };

  const checkContent = e => {
    if (e.currentTarget.id === 'domestic') setWarningContent('DOMESTIC');
    if (e.currentTarget.id !== 'domestic') setWarningContent('');
  };

  const handleOnClickWarning = e => {
    checkContent(e);
    setOpenWarningModal(true);
  };

  return (
    <>
        <UserPointSection/>
      <Modal closeButton open={openWarningModal} onClose={setOpenWarningModal}>
        {warningContent === 'DOMESTIC' ? <DomesticFaq /> : <GlobFaq />}
      </Modal>
      <Box sx={{ minHeight: 'calc(100vh - 200px)' }}>
        <ScrollTop />
        <Box px={2}>
          <TextField
            type="text"
            value={searchValue}
            onChange={handleSearchInput}
            placeholder="머천트를 검색해 보세요"
            variant="standard"
            fullWidth
            style={{
              WebkitImeMode: 'active',
              imeMode: 'active',
            }}
            sx={{
              border: '0px solid !important',
              bgcolor: '#fff',
              borderRadius: 'none',
              '& .MuiInputBase-root:before': {
                borderBottom: '0px solid',
              },
              '& .MuiInputBase-root:hover:before': {
                borderBottom: '0px solid',
              },
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                searchWithKeyword(searchValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  {searchValue && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      variant="contained"
                      sx={{
                        padding: '2.2px',
                        mr: 0.8,
                        bgcolor: 'grey.300',
                        ':hover': {
                          backgroundColor: 'grey.300',
                        },
                      }}
                      onClick={() => setSearchValue('')}
                    >
                      <CloseIcon sx={{ color: '#fff', fontSize: '0.9rem' }} />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    onClick={() => searchWithKeyword(searchValue)}
                  >
                    <SearchIcon
                      sx={{ fontSize: '2rem', color: 'primary.main' }}
                    />
                  </IconButton>
                </>
              ),
            }}
          />
        </Box>
        <Typography
          sx={{
            padding: '16px',
            marginTop: '12px',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
          }}
        >
          <Typography
            variant="span"
            sx={{
              marginRight: '12px',
            }}
          >
            적립시 유의사항{' '}
          </Typography>
          <Typography
            variant="span"
            sx={{
              marginRight: '8px',
              padding: '2px 8px',
              borderRadius: '10px',
              color: '#fff',
              backgroundColor: 'black',
            }}
            id="domestic"
            onClick={handleOnClickWarning}
          >
            국내몰
          </Typography>
          <Typography
            variant="span"
            sx={{
              marginRight: '8px',
              padding: '2px 8px',
              borderRadius: '10px',
              color: '#fff',
              backgroundColor: 'black',
            }}
            id="international"
            onClick={handleOnClickWarning}
          >
            해외몰
          </Typography>
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          aria-label="category tab"
          sx={{ mb: 2 }}
        >
          {linkPriceCategories?.map((item, idx) => (
            <Tab
              key={idx}
              label={item}
              value={idx + 1}
              onClick={() => {
                handleOnClickCategory(item);
              }}
            />
          ))}
        </Tabs>
        <Container sx={{ mb: 3 }}>
          {!linkPriceList || linkPriceList?.merchants?.length === 0 ? (
            <Typography color="grey.500" textAlign="center" width={1}>
              해당하는 제휴쇼핑몰이 없습니다
            </Typography>
          ) : (
            <Grid
              container
              sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              {linkPriceList?.merchants.map(item => (
                <Grid
                  item
                  sx={{
                    p: 4.5,
                    border: '1px solid',
                    borderColor: 'grey.200',
                  }}
                  key={item.merchant_id}
                  onClick={() => (window.location.href = item.click_url)}
                >
                  <img
                    src={item.merchant_logo}
                    alt={item.merchant_name}
                    width="100%"
                  />
                  <Typography
                    color="red"
                    fontSize="0.9rem"
                    fontWeight="550"
                    align="center"
                  >
                    최대{' '}
                    {/* {(item.max_commission_mobile?.split('%')[0] * 0.8).toFixed(1)} */}
                    {item.max_commission_mobile}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
}

const DomesticFaq = () => {
  return (
    <div style={{ padding: 15 }}>
      <Typography variant="h5">
        <b>제휴쇼핑 국내몰</b>
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>포인트 적립시 유의사항</b>
      </Typography>

      <Typography align="left">
        <b>&gt; 제휴쇼핑 적립금 확인</b>
      </Typography>
      <Typography align="left" fontSize="14px" gutterBottom>
        이번달 구매/숙박/발권/이륙 완료시,
        <Typography
          color="red"
          fontWeight={700}
          fontStyle={'italic'}
          fontSize="14px"
        >
          ‘익익월 10일자까지 적립 확정’{' '}
        </Typography>
        (예를들어서 8월달 완료시, 10월 10일자까지 적립) <br />* 적립 확정일이
        '익익월'이 아닌 경우는 해당 머천트에서 별도 표기
      </Typography>
      <p></p>
      <Typography align="left">
        <b>&gt; 적립 제외 상품 및 유의사항</b>
      </Typography>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 공통 유의사항
        </Typography>
        <Typography ml={2} color="red" fontSize="14px">
          - 할인금액, 쿠폰, 부가세, 배송비는 제외한 상품금액에 대해 포인트 적립{' '}
          <br />- 애즈워드를 통해 공지된 할인코드와 프로모션 외에는 적립금 지급
          불가 <br />
          - 머천트 카테고리에 따라 다른 적립율 적용 <br />- 구매/숙박/발권/이륙
          완료된 예약에만 포인트 적립
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 하이마트쇼핑몰
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 링크 클릭하여 앱으로 접속시 앱 직방문 전용 혜택은 제공 불가
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 쿠팡
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 정기배송 실적은 예약 후 1일 이내 결제 건만 인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ G마켓
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 장바구니에 담을 때에도 애즈워드를 경유해야 인정 <br />
          - 실적 미인정 카테고리 (쥬얼리/시계, 상품권, 할인쿠폰, 컴퓨터
          사업자몰) <br />- 스마일페이 사용 시 실적 인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 11번가
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          .
          여행/숙박/항공(항공권,여행수수료),시계/쥬얼리(순금/골드바/돌반지/아동용쥬얼리),브랜드
          시계/쥬얼리(골드바/실버가/금은제품), e쿠폰/상품권(디지털이용권/컨텐츠,
          모바일 상품권,게임 아이템/상품권, 외식상품권, 지류상품권)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 유의사항
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 대량구매 또는 리셀러라고 판단되는 구매자의 주문건은 실적 미인정{' '}
          <br />. 일부 IOS 환경에서 기본 설정 브라우저와 구매 시 브라우저가
          상이할 경우, 실적 전송 불가
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 예스이십사(YES24)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - e-book 상품 구매 실적 인정 <br />- 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 티켓, 영화 예매
          <br />. 타 포인트로 결제 (ex. 해피머니 포인트로, 예스머니로 결제 등)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 홈플러스 온라인 마트
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 카테고리
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 휴대폰/e쿠폰/서비스
          (국내여행/해외패키지/자유여행/항공권/별별호텔/통신.금육/인터넷.TV/모바일/중고폰
          매입/장기렌터카)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 옥션
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 장바구니에 담을 때에도 애즈워드를 통해야 실적 인정
          <br />
          - 할인쿠폰 사용금액은 실적 미인정 <br />
          - 스마일페이 사용 시에도 실적 인정 <br />- 실적 미인정 카테고리 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 순금/골드바/돌반지, 백화점/제화상품권, 특판상품
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 위메프
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 적립금 미인정
          <br />
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 여행, 항공권, ,E쿠폰 및 상품권,(해피머니, 컬처랜드 온라인문화상품권,
          도서문화상품권, 아이템베이 등) 공연티켓, 골드바/돌반지/순금/ 미아방지
          상품 중 순금 관련 상품 등 금 관련 모든 상품
          <br />. 포인트 선결제로 구입 / 무료 이벤트 ,댓글 달기 이벤트를 통해
          쌓은 포인트로 구입
          <br />. 제휴사를 통해 위메프 상품 구입시 적립 이벤트{' '}
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 이마트인터넷쇼핑몰(emart)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 트레이더스, 새벽배송 실적 인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 롯데홈쇼핑(woori)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 유의사항
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 가전제품(30만원) 상품권(1만원) 1일 기준 1ID 당 동일 상품 수량 1개로
          제한
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 인터넷교보문고(kbbook)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 중고도서 / 중고상품 / SAM 디바이스 및 결합상품 <br />. 교보문고
          쿠폰, 포인트, 교환권 사용
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Hmall (현대Hmall)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 상품권 / 기프트콘 / 선불카드 / e슈퍼 / 폐쇄몰 / ASP 매장 매출 / 렌탈
          상품 (렌터카 등)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 더블유컨셉코리아 (wconcept)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 네이버페이 결제시 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ #풀무원 (pulmuone)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품 및 기준
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 베이비밀, 디자인밀, 녹즙 브랜드 상품 <br />
          . 정기배달/정기배송/일일배달/일일배송 등 1회성 출고가 아닌 상품 <br />
          . 임직원 할인 혜택 받은 실적
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 무툰 (mootoon)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 무료 회원가입당 적립금 560원 지급
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 1일 1 ip당 실적 전송 1회로 제한 <br />. 취소 조건 : 허위정보입력인
          경우, 동일인 중복가입, 20일 이내 탈퇴자
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 하프클럽
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적인정 조건
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 애즈워드 제휴링크를 통해 하프클럽에서 구매 시 적립금 사용 불가{' '}
          <br />. 장바구니쿠폰 사용 불가 / 상품쿠폰,중복쿠폰 사용 가능
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 바로방문 (PC / 모바일 web / 모바일 app 동일)
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 바로방문OFF 시&gt; 실적 인정 <br />. 바로방문 버튼 ON으로 변경 시
          &gt; 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 한국정보인증 (signgate)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 법인/사업자카테고리 중 : 서버(공공iPIN),korchambill전용, 서버(OCSP)
          , 서버(일반),관세청 통관포탈용 ,원산지증명용, 샵메일전용,비축물자용,
          전자세금계산서용,코참빌용, 보건복지전용
          <br />. 개인 카테고리 중 : 보건복지전용, 국세청 홈텍스용(개인) <br />
          . 부가상품 카테고리 전체 상품 <br />. 특적 목적용 상품으로 특정 연결된
          업체와 협약으로 판매 되는 상품 상품 또는 자사상품이 아닌 아웃소싱
          상품은 실적 미인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ cj온스타일 (cjonstyle)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 순금/렌탈/모바일
          교환권/상품권/영화/공연/보험/금융/여행/항공권/투어/분유/각종
          서비스(배달, 출장,메이크업/헤어 등)
          <br />. 카메라/캠코더
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 인터파크 도서
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 국내도서 카테고리 내 (예약판매, 프리미엄북 카테고리 상품) <br />
          . 외국도서 카테고리 내 (예약판매, 원서vs번역서 카테고리 상품)
          <br />
          . 중고 카테고리 내 (균일가몰, 판매자로그인 카테고리 상품) <br />. 음반
          카테고리 내 (예약판매, 스타샵, 아티스트파일, lp 카테고리 상품) <br />
          . 굿즈 카테고리 전체
          <br />
          . 타 제휴사를 통해 장바구니에 담긴 실적
          <br />. 환금성(상품권류) 실적
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 보리보리 (boribori)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 애즈워드 제휴링크를 통해 보리보리에서 구매 시 장바구니쿠폰 사용
          불가, 상품쿠폰/중복쿠폰 사용 가능
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 바로방문 (PC / 모바일 web / 모바일 app 동일)
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 바로방문OFF 시&gt; 실적 인정 <br />. 바로방문 버튼 ON으로 변경 시
          &gt; 실적 미인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 앱스토리몰 (cappstory)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 결제 / 구매가능한 상품만 실적 인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 예약상품 / 삼성, LG, 소니 상품
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 바로빌 (barobill)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - CPS / 모바일 웹에서는 가상계좌 결제만 가능
        </Typography>
        <Typography ml={2} fontSize="14px">
          - CPA / 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 현대백화점몰 (thehyundai)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 : 기프티콘 / 상품권 / 렌탈 / 티켓 상품 판매
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 로또리치
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 월정액은 매월 자동 결제되나 첫1개월분에 대해서만 실적 인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 박씨상방
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트/판촉용품 카테고리)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 바보사랑
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 네이버 페이 구매 실적 미인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 가방팝
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 네이버 페이 구매 실적 미인정
        </Typography>
      </Typography>
      <p></p>
    </div>
  );
};

const GlobFaq = () => {
  return (
    <div style={{ padding: 15 }}>
      <Typography variant="h5">
        <b>제휴쇼핑 해외</b>
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>포인트 적립시 유의사항</b>
      </Typography>

      <Typography align="left">
        <b>&gt; 제휴쇼핑 적립금 확인</b>
      </Typography>
      <Typography align="left" fontSize="14px" gutterBottom>
        이번달 구매/숙박/발권/이륙 완료시,
        <Typography
          color="red"
          fontWeight={700}
          fontStyle={'italic'}
          fontSize="14px"
        >
          ‘익익월 10일자까지 적립 확정’{' '}
        </Typography>
        (예를들어서 8월달 완료시, 10월 10일자까지 적립) <br />* 적립 확정일이
        '익익월'이 아닌 경우는 해당 머천트에서 별도 표기
      </Typography>
      <p></p>
      <Typography align="left">
        <b>&gt; 적립 제외 상품 및 유의사항</b>
      </Typography>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 공통 유의사항
        </Typography>
        <Typography ml={2} color="red" fontSize="14px">
          - 할인금액, 쿠폰, 부가세, 배송비는 제외한 상품금액에 대해 포인트 적립{' '}
          <br />- 애즈워드를 통해 공지된 할인코드와 프로모션 외에는 적립금 지급
          불가 <br />
          - 머천트 카테고리에 따라 다른 적립율 적용 <br />- 구매/숙박/발권/이륙
          완료된 예약에만 포인트 적립
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 호텔스닷컴
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 온라인 결제 건은 (숙박 완료 월 +2)월, 현장 결제 건은 (숙박 완료 월
          +4)월에 적립 <br />
          예시)1월 예약, 온라인 결제 -&gt; 5/3 숙박 완료 -&gt; 7월 적립금 확인
          가능 / 1월 예약, 현장 결제 -&gt; 5/3 숙박 완료 -&gt; 9월 적립금 확인
          가능{' '}
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 호텔스닷컴 내부 할인코드, 기타 은행, 카드사 할인코드
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 아고다
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 아고다에서 스팟성 다이렉트 마케팅용으로 발행하는 할인코드 예약{' '}
          <br />
          . 아고다 '오늘의 혜택' 에서 제공 중인 쿠폰 예약
          <br />. 숙소 외 상품 예약 건 (항공, 패키지(항공+숙소), 액티비티,
          렌터카 등)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 정산 방식
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 온라인 결제 건은 (숙박 완료 월 +2)월, 현장 결제 건은 (숙박 완료 월
          +3)월에 적립 <br />
          예시) 1월 예약, 온라인 결제 -&gt; 5/3 숙박 완료 -&gt; 7월 확인 / 1월
          예약, 현장 결제 -&gt; 5/3 숙박 완료 -&gt; 8월 확인{' '}
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 트립닷컴
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 정산 방식
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 할인금액, 트립코인 사용금액 등 제외한, 실제 고객으로부터 지불된 금액
          기준으로 적립 <br />
          . 왕복 항공편에서 고객이 귀항편 취소 후 환불 완료시,적립금은 편도
          항공권 금액 기준
          <br />. 호텔, 항공권, 액티비티 예약 실적만 인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 중국 내 이동 항공권 <br />. 트립닷컴 프로모션
          페이지(https://kr.trip.com/sale/deals/)에 공개되지 않은 프로모션{' '}
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 익스피디아
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 10%이상 카드 할인 쿠폰 및 익스피디아 자체 스팟성 할인쿠폰(ex.신년
          할인쿠폰, 블랙프라이데이 할인쿠폰 등) <br />
          . KR 페이지 외 다른 국가 페이지에서 예약
          <br />. 렌터카 예약
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 알리익스프레스 (Aliexpress)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          .링크 페이지에서 상품 주소 입력 시 해당 상품의 커미션 인정/미인정 여부
          확인 가능합니다.
          <a href="https://portals.aliexpress.com/linkchecker.htm">
            [조회 하러 가기]
          </a>
        </Typography>
        <Typography ml={3} fontSize="14px"></Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 파페치 (farfetch)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 파페치 영문명은 대문자로 작성 해야합니다. (예시- faretch(X),
          Farfetch(X). FARFETCH(O))
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 파페치에서 자체발행한 쿠폰 이외의 할인코드로 발생한 실적은 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 월마트 USA
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 주문 완료 6개월 후 적립
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 미인정 실적
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 적립금 지급 카테고리에 포함되지 않은 상품들은 모두 미지급 됩니다.{' '}
          <br />
          . 적립금 지급 카테고리 : MEDIA AND GAMING ELECTRONICS, CAMERAS AND
          SUPPLIES,WIRELESS, TOYS, SPORTING GOODS, PATIO AND GARDEN,BOOKS AND
          MAGAZINES, PHOTO SERVICES, STATIONERY, AUTOMOTIVE, HARDWARE AND TOOLS
          DO IT YOURSELF, HORTICULTURE, PERSONAL CARE, PETS AND SUPPLIES,
          BEAUTY, Baby hardlines L , MENS APPAREL, BABY AND TODDLER APPAREL,
          BOYS APPAREL, GIRLS APPAREL, SHOES, INTIMATE APPAREL, WOMENS APPAREL,
          ACCESSORIES,JEWELRY,SEASONAL, CELEBRATION
          <br />. 적립금 미인정 주요 카테고리 : 애플(Apple) 제품 / 타이어 /
          귀금속 / 기프트 카드 / 음식 / 가정 용품(Household) / 유아 용품 /
          비디오게임 하드웨어 제품 등<br />
          (Apple Device, AUTOMOTIVE TIRES, PRECIOUS METALS ECOMM, FOOD, VIDEO
          GAME HARDWARE, Baby consumables)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 스탁엑스 (stockx)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트카드)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 유의사항
        </Typography>
        <Typography ml={3} fontSize="14px">
          . Internet Explorer 브라우저에서 구매 불가 <br />. 입찰(Bid)을 통한
          구매의 경우, 셀러(Seller)가 15일 내에 입찰을 수락한 경우에만 인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 센스닷컴 (ssense)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 유의사항
        </Typography>
        <Typography ml={3} fontSize="14px">
          . SSENSE에서 자체발행한 쿠폰 이외의 할인코드로 발생한 실적은 미인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 아이허브 (iherb)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . iHerb Rewards program을 통해 판매 완료된 상품 (타 추천인 코드, 특정
          매체에 제공된 할인코드 입력시 적립금 지급 불가)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 매치스패션 (matches1)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 매치스패션이 자체발행한 쿠폰 이외의 할인코드로 발생한 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 24s
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 주문 완료 기준, 4개월 후 적립
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (루이비통 /셀린느/디올 브랜드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 클룩 (klook)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 예약 완료 후 4개월 뒤 최종 확인
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ THE OUTNET
        </Typography>
        <Typography ml={2} fontSize="14px">
          - APAC 지역 외에서 발생한 구매건은 미인정 (청구지 주소/배송지 기준)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 아웃넷에서 자체발행한 쿠폰 이외의 할인코드로 발생한 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 블루밍데일즈 (blooming)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 블루밍데일즈가 자체발행한 쿠폰 이외의 할인코드로 발생한 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 헤롯백화점(harrods)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트 카드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 메리어트(marriott)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 타 제휴사 할인코드 사용 <br />
          . 비공개 멤버 전용 프로모션을 통해 예약
          <br />
          . 특별요금 통해 예약
          <br />
          . 메리어트 포인트를 통해 예약 및 업그레이드
          <br />
          . 브랜드: RitzCarlton Destination Club, Design Hotels, Bulgari
          <br />. 호텔: Atlantis, Autograph Collection, Bulgari Hotel, London,
          Joshua G.E. Medical Corp. Conference Center, Jesse Jones Rotary
          Conference Center, Marriott Ranch Protea Hotel Amani Beach, Protea
          Hotel Mbweni Ruins, The Ritz-Carlton London, U.S. Postal Service
          Conference Center, Four Points by Sheraton Havana, The Cosmopolitan
          Las Vegas, Autograph Collection{' '}
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ dyson
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 리퍼브 상품(refurbished product), 예비 상품(spares), 액세서리
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 다이슨 미국 공식 사이트(https://www.dyson.com/en.html)에 한해서만
          인정, KR 사이트 주문건은 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 코세라(coursera)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 월별 결제 강좌는 첫 달 결제에 대한 적립금만 지급
        </Typography>
        <Typography ml={3} fontSize="14px">
          - 실적 미인정 강좌
        </Typography>
        <Typography ml={2} fontSize="14px">
          . 무료 강좌(Free Trial)
          <br />. 온라인 학위(https://www.coursera.org/degrees/)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 스니커즈앤스터프(snstuff)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (리미티드 에디션 상품)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 조마샵(jomashop)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 20일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(20일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 메이시스(macys)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 상품권, 재주문 상품, 법인 상품권, 매트리스, 가구 주문 건은 실적
          미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 루이자비아로마(luisavr)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 구매 확정 후 4,5개월 후 적립
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (구찌 브랜드 상품)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ HBX
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (아카이브 상품)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ ln-cc
        </Typography>
        <Typography ml={2} fontSize="14px">
          - LN-CC에서 자체발행한 쿠폰 이외의 할인코드로 발생한 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ shopworn
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 20일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(20일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ CyberLink
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={2} fontSize="14px">
          . Charge for Web Convenience Store
          <br />
          . Extended Download Service
          <br />
          . Backup Disc
          <br />
          . PowerDirector 19 / 365 Tutorial Vol.1 (Germany)
          <br />
          . PowerDirector 19 / 365 Tutorial Vol.2 (Germany)
          <br />
          . PhotoDirector 12 Tutorial (Germany) Vol.2
          <br />. PhotoDirector 12 Tutorial (Germany) Vol.1
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 라코스테(lacoste)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 13일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(14일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트 카드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Shopbop (샵밥)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트 카드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Finish Line (피니쉬 라인)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 피니쉬라인에서 자체발행한 할인코드 일부와 타 제휴사 할인코드로
          발생한 실적 미인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 기프트카드, Men's Air Jordan Retro, Nike Air, Lunarglide 6, Jordan,
          Kobe, Durant, Under Armour Curry, Lebron, Yeezy, Adidas NMD, Adidas
          Ultra Boost, Puma Fenty Men's adidas NMD Runner Casual Shoes
          <br />. GET30AFF, GET40AFF, GET50AFF, EXTRA70, BIGFLEX50, NEWDRIP,
          MARCHGLADNESS, TREATYOSELF 등 할인코드 사용 실적{' '}
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Joe's New Balance Outlet (조스 뉴발란스)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 쿠폰 (CS, DBX, EXC 로 시작하는 쿠폰)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 트립어드바이저(tripadviso)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 적립금은 트립어드바이저가 각 제휴 여행사들로부터 받는 수수료의 22.4%
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 조셉(joseph)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트 카드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 퓨리탄프라이드(puritans)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 퓨리탄 프라이드가 자체발행한 할인코드 이외의 할인코드로 발생한 실적
          미인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 대량구매 또는 리셀러라고 판단되는 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 핏플랍(fitflop)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (클리어런스 세일 제품 / 기프트 카드)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Carter's (카터스)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 카터스 사이트로 방문시, 오시코시 제품 구매 실적도 인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ VITACOST
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 20일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(20일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 비타코스트가 자체발행한 할인코드 이외의 할인코드로 발생한 실적
          미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ OshKosh B'gosh (오시코시 비고시)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 오시코시 사이트로 방문시, 카터스 제품 구매도 실적 인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 오시코시 자체 발행 쿠폰 이외의 타 할인코드로 발생한 실적 미인정 (ex.
          할인코드 모음 사이트 등에서 발급된 할인코드)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - ORDER25 할인코드 사용 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 비타민월드(vitaminwd)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 커미션 미인정 상품 (VWR0620 할인코드)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 모다 오페란디(operandi)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 적립금은 (주문 완료 월 + 4 or +5)월에 적립
          <br />- 실적 미인정 (클리어런스 세일 제품 / 기프트 카드)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 힐튼 호텔(hilton)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 예약
        </Typography>
        <Typography ml={3} fontSize="14px">
          . HHonors (Hilton Worldwide loyalty program) 사용 <br />
          . HHpoint, 기프트카드 사용
          <br />. 특별요금(Special rates) 예약 : 그룹투어(단체예약), 법인
          예약(Corporate rate, government rates, employee ,industry rate),
          여행사 통한 예약(Travel agent rates, or airline rates )
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 적립금 미지급 상품 리스트
        </Typography>
        <Typography ml={3} fontSize="14px">
          . Buena Vista Palace Resort & Spa, FL,Embassy Suites by Hilton
          Caracas,El Pardo DoubleTree by Hilton,Waldorf Astoria Arizona Biltmore
          Real Estate,Waldorf Astoria La Quinta Resort & Spa,Hilton Buenos
          Aires, AR,Hilton Curacao,Hilton Princess Managua,British Colonial
          Hilton Nassau,Hilton Barbados Resort,Hilton Trinidad & Conference
          Centre,Hilton Princess San Pedro Sula,Hilton Sao Paulo Morumbi,Hilton
          Richmond Downtown,Hilton Panama,DoubleTree by Hilton Panama City - El
          Carmen,Hilton Curacao Apartments,Hyatt Regency Pier Sixty-Six,
          FL,Embassy Suites by Hilton Valencia-Downtown, Venezuela,Hilton Garden
          Inn Panama,Waldorf Astoria Waldorf-Astoria Golf Club Bonnet Creek,
          Fl,Hilton Lima MiraFlores,Waldorf Astoria Panama,Embassy Suites by
          Hilton Santo Domingo,Hilton Garden Inn Tucuman, Argentina,Hampton by
          Hilton Panama,Cedar Rapids Convention Center, IA,Cedar Rapids Arena,
          IA,Hilton Grand Vacations HGVC Trump International Hotel Las Ve,Hilton
          Garden Inn Monterrey Airport, Nuevo Leon, Mexico,Hampton Inn
          Villahermosa, Tabasco, Mexico,Hampton by Hilton Valledupar,
          Colombia,Hampton Inn Bucaramanga, Colombia,Hampton Inn Irapuato,
          MX,Hilton Grand Vacations HGVC Myrtle Beach, SC,Hilton Grand Vacations
          Las Vegas Badura,Hilton Grand Vacations Alamoana,Hilton Grand
          Vacations Waikele OPC,Hilton Grand Vacations Queens OPC,Hilton Grand
          Vacations Royal Hawaiian,Hilton Grand Vacations Time Square,Hilton
          Grand Vacations WDW Hilton,Hilton Grand Vacations Oahu DFS,Hilton
          Garden Inn Puebla Angel?olis, MX,Hampton Inn & Suites by Hilton
          Salamanca Bajio,Hilton San Luis Potosi,Hampton Inn Piedras Negras,
          COA,Hilton Aruba Caribbean Resort & Casino,Doubletree by Hilton Bogota
          Calle 100,DoubleTree by Hilton Bogota Parque 93,Hampton Inn Santa
          Cruz/Equipetrol, Bolivia,Hilton Garden Inn Santiago de Surco Lima,
          Peru,Hampton Inn Tampico Zona Dorado, TAM,Hampton Inn Cancun Cumbres,
          Mexico,INDURA BEACH & GOLF RESORT CURIO COLLECTION BY HILTON,Hilton
          Garden Inn Belo Horizonte, MG,Homewood Suites by Hilton Jacksonville
          Deerwood Park,Doubletree by Hilton Mexico City Santa Fe,Hilton Hilton
          Waikoloa Tower,South Seas Island Resort, Captiva Island,Waldorf
          Astoria Napua Tower at Grand Wailea, HI*타 제휴사 할인코드 사용 예약
          건
          <br />. 코로나 바이러스 이슈로 3/23부터 발생한
          한국/중국/이태리/일본/사우디아라비아 호텔 예약건은 적립금 지급 불가
          <br />. hilton.com에서 발생한 실적만 인정되며, 한국 사이트
          (hilton.co.kr)에서 발생한 실적 미인정{' '}
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ LightInTheBox(lightb)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 20일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(20일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ woot
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 미국 내 배송만 지원하며, 아마존 계정으로 로그인 및 구매가 가능
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 적립금 미인정 상품 (Wine, Gourmet, Biss 카테고리)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 라이프 익스텐션(lifeext)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 대량구매 또는 리셀러라고 판단되는 실적 미인정
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 라이프익스텐션이 자체발행한 할인코드 이외의 할인코드로 발생한 실적
          미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ Glamood
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 20일 이전 주문건에 한 해, 주문 후 익익익월에 최종 확인(20일 이후
          발생 주문건은 한 달 더 늦게 확인 가능)
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 케이스티파이 (casetify)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - Internet Explorer 브라우저에서 구매 불가
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 마이크로소프트 스토어 (mskorea)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 기프트카드 / Xbox Game Pass Trials (체험판) / Office 365 Trials
          (체험판) / MRVR / Windows / Other Software / Other / Unshipped
          Preorders
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ &Otherstories (stories)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정 (기프트카드)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 주문 완료 익익익월에 적립
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 아코르 호텔 (accor)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 미인정
        </Typography>
        <Typography ml={3} fontSize="14px">
          .타 제휴사 할인코드 사용 . 실적 미인정 호텔 : Coralia, Thalassa 호텔
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ ARKET (아르켓)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 구매 완료 익익익월에 최종 확인
          <br />
          - 기프트카드 구매건 실적 미인정
          <br />- 타 제휴채널에서 발급한 쿠폰 사용건 실적 미인정
        </Typography>
      </Typography>
      <p></p>

      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ crocs
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 임직원 세일 및 할인코드 사용 실적은 미인정
        </Typography>
      </Typography>
      <p></p>
      <Typography align="left" gutterBottom>
        <Typography ml={1} backgroundColor="yellow" fontSize="15px">
          ㅇ 레노버코리아 (lenovo)
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 실적 인정 상품
        </Typography>
        <Typography ml={3} fontSize="14px">
          . 레노버 코리아 공식 홈페이지의 일반 스토어 및 Pro Store(법인 스토어)
          실적
        </Typography>
        <Typography ml={2} fontSize="14px">
          - 유의사항
        </Typography>
        <Typography ml={3} fontSize="14px">
          . Internet Explorer 브라우저에서 구매 불가
          <br />
          . 레노버 코리아 내부 할인 프로모션 쿠폰 및 애즈워드 제공 쿠폰 이외에
          타 제휴사에 발급된 할인쿠폰 사용 시 실적 미인정
          <br />. 상담원 할인쿠폰 사용시 실적 미인정
        </Typography>
      </Typography>
      <p></p>
    </div>
  );
};
