import API from 'api';

export const getFAQ = async page => {
  try {
    const response = await API.post('v1/faq/all', {
      page: page,
      size: 10,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
