import { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  useHref,
  Outlet,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import QueryString from 'qs';

import { LinearProgress } from '@mui/material';

import { getValidUser } from 'store/auth';
import {
  getAllCategories,
  getBanners,
  getSavingsPointBanners,
  getWeeklyBestItemBanners,
  getDailyBestItemBanners,
} from 'store/mall';
import { setIsOpenModal, changeBottomTab } from 'store/app';

import { Container } from 'components/atom/index';
import Layout from 'components/layout';

import Home from 'pages/home/index';
import Profile from 'pages/profile';
import Step from 'pages/step';
import ProductDetail from 'pages/product';
import CouponDetail from 'pages/coupon';
import Payment from 'pages/payment';
import CouponPayment from 'pages/couponPayment';
import { encodeToken, redirect } from 'utils';
import FAQ from 'pages/others/FAQ';
import Cart from 'pages/cart';
import Search from 'pages/Search';
import AgreeTerms from 'pages/others/AgreeTerms';
import ExtraProfit from 'pages/others/ExtraProfit';
import { ConfirmOrder } from 'pages/profile/order';
import WriteReview from 'pages/profile/order/WriteReview';
import PointHistory from 'pages/profile/PointHistory';
import CategoryProduct from 'pages/category/CategoryProduct';
import Event from 'pages/home/EventPanel';
import Coupon from 'pages/home/CouponPanel';
import CouponList from 'pages/home/CouponListPanel';
import AllianceShoppingPanel from "./pages/home/AllianceShoppingPanel";



const storage = sessionStorage;

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const user = useSelector(state => state?.auth.user);
  const appLoad = useSelector(state => state?.auth.appLoad);
  const status = useSelector(state => state?.auth.status);

  let token;

  useEffect(() => {
    if (Object.keys(params).includes('ALC')) {
      token = encodeToken({ alc: params.ALC });
    } else {
      token = encodeToken({
        refreshToken: storage.getItem('REFRESH_AUTH_TOKEN'),
      });
    }

    dispatch(getValidUser(token));
  }, []);

  useEffect(() => {
    if (!appLoad) return;
    if (!user) redirect('http://adsward.co.kr/main');
    if (!user) redirect('http://adsward.co.kr/member/login');

  }, [appLoad]);

  useEffect(() => {
    // if (user && !Object.keys(params).includes('ALC')) navigate('/');

    if (user) {
      dispatch(getBanners());
      dispatch(getAllCategories());
      dispatch(getWeeklyBestItemBanners());
      dispatch(getDailyBestItemBanners());
      dispatch(getSavingsPointBanners('Margin'));
      dispatch(getSavingsPointBanners('Yield'));
      dispatch(getSavingsPointBanners('New'));
    }
  }, [user]);

  useEffect(() => {
    if (!user?.terms[0] || !user?.terms[1]) {
      dispatch(
        setIsOpenModal({
          name: 'terms',
          value: true,
        }),
      );
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname.split('/')[1];
    if (
      pathname === 'profile' ||
      pathname === 'pointhistory' ||
      pathname === 'orderhistory' ||
      pathname === 'FAQ'
    ) {
      dispatch(changeBottomTab('profile'));
    } else if (location.pathname.includes("coupon")) {
      dispatch(changeBottomTab('coupon'));
    }
    else if (location.pathname.includes("merchantShopping")) {
      dispatch(changeBottomTab('advertisement'));
    }
    else if (location.pathname === '/')  {
      dispatch(changeBottomTab('point-shop'));
    }
  else  {
      dispatch(changeBottomTab('point-shop'));
    }

  });

  if (status === 'loading') {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Routes>
        {appLoad && user && (
          <Route element={<RequiredAgreement />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="profile" element={<Profile />} />
              <Route path="event">
                <Route path=":eventId" element={<Event />} />
              </Route>
              <Route path="coupon" element={<Coupon />} />
              <Route path="couponList" element={<CouponList />} />
              <Route path="coupon" >
                <Route path=":id" element={<CouponDetail />} />
              </Route>
              <Route path="merchantShopping" element={<AllianceShoppingPanel />} />
              <Route path="orderhistory/:orderId" element={<ConfirmOrder />} />
              <Route
                path="orderhistory/:orderId/reviewlist"
                element={<ConfirmOrder />}
              />
              <Route
                path="orderhistory/:orderId/review/:orderItemId"
                element={<WriteReview />}
              />
              <Route path="pointhistory" element={<PointHistory />} />
              <Route path="FAQ" element={<FAQ />} />
              <Route path="extra" element={<ExtraProfit />} />
              <Route path="attendance" element={<ExtraProfit />} />
              <Route path="search" element={<Search />}>
                <Route path=":keyword" element={<Search />} />
              </Route>
              <Route path="cart" element={<Cart />} />
              <Route path="category" element={<CategoryProduct />} />
              <Route path="product">
                <Route path=":productId" element={<ProductDetail />} />
              </Route>
              <Route
                  path="couponPayment"
                  index
                  element={<CouponPayment status="ready" />}
              />
              <Route
                  path="couponPayment/:orderInfoId/success"
                  element={<CouponPayment status="success" />}
              />
              <Route path="couponPayment/fail" element={<CouponPayment status="fail" />} />

              <Route
                  path="payment"
                  index
                  element={<Payment status="ready" />}
              />
              <Route
                  path="payment/:orderInfoId/success"
                  element={<Payment status="success" />}
              />
              <Route path="payment/fail" element={<Payment status="fail" />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Route>
            <Route path="step" element={<Step />} />
          </Route>
        )}
      </Routes>
    </Container>
  );
}

const RequiredAgreement = () => {
  const user = useSelector(state => state?.auth.user);
  const isOpenTermsModal = useSelector(state => state.app.isOpenModal.terms);

  if (!user) redirect('http://adsward.co.kr/member/login');
  if (!user?.terms.basicAdsmallUserTerms) {
    return <AgreeTerms open={isOpenTermsModal} />;
  }
  return <Outlet />
}
