import { Box } from '@mui/material';

export default function StepLayout({
  children,
  title,
  footerOff,
  noBackButton,
  ...rest
}) {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#fff',
        }}
        {...rest}
      >
        {children}
      </Box>
    </>
  );
}
