import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCategoriesByRank,
  fetchCategoriesByParentCode,
} from 'api/category';
import { fetchProductsByCategory } from 'api/product';

const initialState = {
  initialStatus: 'idle',
  status: 'idle',
  error: null,
  tabIndex: 0,
  categories: {
    first: [],
    second: [],
    third:[],
  },
  product: [],
  productList: [],
};

export const getProductsByCategory = createAsyncThunk(
  'categorySort/getProductsByCategory',
  async data => {
    const res = await fetchProductsByCategory(data);

    return res.data;
  },
);
export const getCategoriesByParentCode = createAsyncThunk(
  'categorySort/getCategoriesByParentCode',
  async code => {
    const res = await fetchCategoriesByParentCode(code);

    return res.data;
  },
);

export const getThirdLevelCategoriesByParentCode = createAsyncThunk(
    'categorySort/getThirdLevelCategoriesByParentCode',
    async code => {
      const res = await fetchCategoriesByParentCode(code);

      return res.data;
    },
);
export const getCategoriesByRank = createAsyncThunk(
  'categorySort/getCategoriesByRank',
  async rank => {
    const res = await fetchCategoriesByRank(rank);

    return res.data;
  },
);
export const categorySortSlice = createSlice({
  name: 'categorySort',
  initialState,
  reducers: {
    setCategoryProductList: (state, action) => {
      state.productList = action.payload;
    },
    initProductData: (state, action) => {
      state.product = [];
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCategoriesByRank.pending, (state, action) => {
        state.initialStatus = 'loading';
      })
      .addCase(getCategoriesByRank.fulfilled, (state, action) => {
        state.initialStatus = 'succeeded';
        state.categories.first = action.payload;
      })
      .addCase(getCategoriesByRank.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(getCategoriesByParentCode.pending, (state, action) => {
        state.initialStatus = 'loading';
      })
      .addCase(getCategoriesByParentCode.fulfilled, (state, action) => {
        state.initialStatus = 'succeeded';
        state.categories.second = action.payload;
      })
      .addCase(getCategoriesByParentCode.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(getThirdLevelCategoriesByParentCode.pending, (state, action) => {
        state.initialStatus = 'loading';
      })
      .addCase(getThirdLevelCategoriesByParentCode.fulfilled, (state, action) => {
        state.initialStatus = 'succeeded';
        state.categories.third = action.payload;
      })
      .addCase(getThirdLevelCategoriesByParentCode.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(getProductsByCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProductsByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.product = action.payload;
      })
      .addCase(getProductsByCategory.rejected, (state, action) => {
        state.error = action?.error.message;
      });
  },
});

export const { setTabIndex, setCategoryProductList, initProductData } =
  categorySortSlice.actions;

export default categorySortSlice.reducer;
