import { Box } from '@mui/material';
import Footer from './Footer';
import Header from './Header';

import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
export default function PageLayout({
  children,
  title,
  footerOff,
  noBackButton,
  ...rest
}) {

  return (
    <>
      <Box
        sx={{
          minHeight: 'calc(100vh - (48px + 70px + 158px))',
          backgroundColor: '#fff',
        }}
        {...rest}
      >
        <Header title={title} noBackButton={noBackButton} />
        {children}
      </Box>
      {footerOff || <Footer />}
    </>
  );
}
