import React from 'react';

import { Divider as _Divider } from '@mui/material';

export default function Divider({ color, thickness, vertical, ...rest }) {
  return (
    <_Divider
      orientation={vertical ? 'vertical' : 'horizontal'}
      sx={{ borderColor: 'grey.300', borderBottomWidth: thickness }}
      {...rest}
    />
  );
}
