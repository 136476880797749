import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CardMedia, Divider, Typography } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/scrollbar';
// import 'swiper/css/pagination';
import 'swiper/css/bundle';
import 'styles/swiper.css';

import {
  setCurrentCategory,
  getMainBestItems
 } from 'store/mall';
import {
  setTabIndex,
  setCategoryProductList,
  initProductData,
} from 'store/categorySort';

import Items from 'components/Items';
import BannerSection from 'pages/home/StorePanel/BannerSection';
import UserPointSection from 'pages/home/StorePanel/UserPointSection';

export default function HomePanel() {
  const navigate = useNavigate();
  const categories = useSelector(state => state?.mall.categories);

  const dispatch = useDispatch();



  const handleClickCategory = (category, index) => {
    dispatch(setCurrentCategory(category.code));
    dispatch(setCategoryProductList([]));
    dispatch(initProductData([]));

    switch (index) {
      case 12:
        dispatch(setTabIndex(0));
        break;
      case 13:
        dispatch(setTabIndex(3));
        break;
      case 14:
        dispatch(setTabIndex(9));
        break;
      case 15:
        dispatch(setTabIndex(7));
        break;
      case 16:
        dispatch(setTabIndex(10));
        break;
      case 17:
        dispatch(setTabIndex(10));
        break;
      case 18:
        dispatch(setTabIndex(10));
        break;

      default:
        dispatch(setTabIndex(index - 1));

        break;
    }

    navigate('/category');
  };

  return (
    <>
      <UserPointSection/>
      <BannerSection />

      <Box sx={{ px: 3 }}>
        <Swiper
          slidesPerView={5}
          grid={{
            rows: 2,
          }}
          scrollbar={{
            hide: false,
            // dragSize: 10,
            enabled: true,
          }}
          modules={[Grid, Scrollbar]}
          style={{ height: '250px' }}
        >
          {categories?.map((category, index) => {
            if (category.code !== 'all') {
              return (
                <SwiperSlide
                  key={category.code}
                  data-code={category.code}
                  onClick={() => {
                    handleClickCategory(category, index);
                  }}
                >
                  <CardMedia component="img" src={category.icon} />
                  <Typography
                    fontSize="10px"
                    align="center"
                    pt={1}
                    value={category.code}
                  >
                    {category.name_2}
                  </Typography>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </Box>

      <Divider />

      <Items name="베스트" shape="col" xs={6} tabIndex={1} />
      <Items name="신제품순" shape="row" xs={6} tabIndex={2} />
      {/*<Items name="이벤트" shape="card" tabIndex={3} />*/}
    </>
  );
}
