import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllFromBasket,
  addShopItemToBasket,
  popShopItemFromBasket,
} from 'api/cart';

const initialState = {
  status: 'idle',
  error: null,
  cartUpdate: [],
  cartItems: [],
  orderCartItems: false,
  orderItemsInfo: [],
  orderItemsInfoForDisplay: [],
};

export const getAllCartItems = createAsyncThunk(
  'cart/getAllCartItems',
  async () => {
    const res = await getAllFromBasket();
    const cartItemsInfo = res.data.itemInfos;
    const newArray = cartItemsInfo?.map(item => {
      return {
        ...item,
        itemOption: item.itemOptionInfo
          ? item.itemOptionInfo?.map(data => data.optionId)
          : '',
        selected: true,
      };
    });
    // return cartItems.data;
    // return cartItemsInfo;
    return newArray;
  },
);

export const addShopItemToBasketAPI = createAsyncThunk(
    'cart/addShopItemToBasketAPI',
    async data => {
      await addShopItemToBasket(data);
    },
);

export const popShopItemFromBasketAPI = createAsyncThunk(
  'cart/popShopItemFromBasketAPI',
  async data => {
    await popShopItemFromBasket(data);
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setOrderCartItems: (state, action) => {
      state.orderCartItems = action.payload;
    },
    setOrderItemsInfo: (state, action) => {
      state.orderItemsInfo = action.payload;
    },
    setCartUpdate: (state, action) => {
      state.cartUpdate = action.payload;
    },
    setOrderItemsInfoForDisplay: (state, action) => {
      state.orderItemsInfoForDisplay = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // getAllCartItems
      .addCase(getAllCartItems.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllCartItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = action.payload;
      })
      .addCase(getAllCartItems.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // add Items into Cart
      .addCase(addShopItemToBasketAPI.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addShopItemToBasketAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // action.payload 수정필요
        state.cartUpdate = action.payload?.itemId;
      })
      .addCase(addShopItemToBasketAPI.rejected, (state, action) => {
        state.error = action?.error.message;
      })
      // delete Items into Cart
      .addCase(popShopItemFromBasketAPI.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(popShopItemFromBasketAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartUpdate = state.cartItems;
      })
      .addCase(popShopItemFromBasketAPI.rejected, (state, action) => {
        state.error = action?.error.message;
      });
  },
});

export const {
  setCartItems,
  setOrderCartItems,
  setOrderItemsInfo,
  setCartUpdate,
  setOrderItemsInfoForDisplay,
} = cartSlice.actions;

export default cartSlice.reducer;
