import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
} from '@mui/material';
import { setCoupon } from 'store/coupon';
import TextSet from 'components/coupon/TextSet';
import { useDispatch } from 'react-redux';
import React from "react";

export default function CardShapeItem({ product, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClickItem = e => {
    dispatch(setCoupon(product));
    navigate('/coupon/' + e.currentTarget.id);
  };
  return (
    <Card
      sx={{
        mb: 1,
      }}
      {...rest}
    >
      <CardActionArea onClick={handleOnClickItem} id={product.productId}>
        <CardMedia
          component="img"
          src={product.imageUrl}
          height="136"
          // width="100%"
          sx={{
            objectFit: 'cover',
            aspectRatio: '1 / 2',
          }}
        />
        <CardContent>
          <TextSet product={product} />
          <Grid container justifyContent="end">
            {/* Todo: Need to be Button */}
            {/* <Typography fontSize="14px" fontWeight="bold" color="#FF203B">
          구매하기
        </Typography> */}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
