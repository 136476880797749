import API from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchPaidOrderList,
  fetchPaidCouponOrderList,
  fetchOrderByOrderItems,
  fetchOrderOneItem,
  fetchPaymentByPoint,
} from 'api/order';

const initialState = {
  status: 'idle',
  disableOrder: false,
  orderStatus: null,
  error: null,
  isSameInfoWithUser: false, // not required
  destinationName: '', // not required
  recipient: '',
  phoneNumber: '',
  address: {
    zipCode: '',
    road: '',
    jibun: '',
    extraAddress: '',
  },
  isRegisteredDefaultAddress: false, // not required
  paymentMethod: '',
  shippingRequest: '',
  agreements: {
    0: false,
    1: false,
    2: false,
  },
  orderDetail: {
    hasInfo: false,
    itemId: '',
    itemName: '',
    tossOrderId: '',
    customerName: '',
    itemCount: 1,
    amount: 0,
    usePoint: 0,
    optionToOrderItems: [
      // can be Added
      {
        optionCount: 0,
        optionId: 0,
      },
    ],
    textOption: '',
    shippingFee: 0,
    additionalFee: 0,
  },
  payWithPoint: false,
  successOrderInfo: 'idle',
  orderInfoId: null,
  paidOrderList: [],
};

export const getPaidCouponOrderList = createAsyncThunk(
  'order/getPaidCouponOrderList',
  async page => {
    const response = await fetchPaidCouponOrderList(page);

    return response.data;
  },
);

export const getPaidOrderList = createAsyncThunk(
    'order/getPaidOrderList',
    async page => {
      const response = await fetchPaidOrderList(page);

      return response.data;
    },
);

export const makeMultipleOrder = createAsyncThunk(
  'order/makeMultipleOrder',
  async data => {
    const response = await fetchOrderByOrderItems(data);

    return response.orderInfoId;
  },
);

export const makeOrder = createAsyncThunk('order/makeOrder', async data => {
  const response = await fetchOrderOneItem(data);

  return response.orderInfoId;
});

export const sendOrderStatus = createAsyncThunk(
  'order/tossSendOrder',
  async data => {
    if (data.paymentKey === undefined) {
      const response = await fetchPaymentByPoint(data);

      return response;
    } else {
      try {
        const response = await API.get('v1/toss/payment', {
          params: {
            paymentKey: data.paymentKey,
            orderId: data.orderId,
            amount: data.amount,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPayWithPoint: (state, action) => {
      state.payWithPoint = action.payload;
    },
    initOrderInfoId: (state, action) => {
      state.orderInfoId = null;
    },
    setDeliveryStart: (state, action) => {
      state.deliveryStart = action.payload;
    },
    setDisableOrder: (state, action) => {
      state.disableOrder = action.payload;
    },
    setOrderDetail: (state, action) => {
      state.orderDetail = action.payload;
    },
    setShippingFee: (state, action) => {
      state.orderDetail.shippingFee = action.payload;
    },
    setAdditionalFee: (state, action) => {
      state.orderDetail.additionalFee = action.payload;
    },
    setOrderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder
        .addCase(getPaidOrderList.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getPaidOrderList.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.paidOrderList = action.payload;
        })
        .addCase(getPaidOrderList.rejected, (state, action) => {
          state.error = action?.error.message;
        })

        .addCase(getPaidCouponOrderList.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getPaidCouponOrderList.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.paidOrderList = action.payload;
        })
        .addCase(getPaidCouponOrderList.rejected, (state, action) => {
          state.error = action?.error.message;
        })

      .addCase(makeMultipleOrder.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(makeMultipleOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderInfoId = action.payload;
      })
      .addCase(makeMultipleOrder.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(makeOrder.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(makeOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderInfoId = action.payload;
      })
      .addCase(makeOrder.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      // Check Payment Is Success
      .addCase(sendOrderStatus.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(sendOrderStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload?.error === 'none') {
          state.successOrderInfo = action.payload.paymentResult;
        }
      })
      .addCase(sendOrderStatus.rejected, (state, action) => {
        state.error = action?.error.message;
        state.successOrderInfo = 'failed';
      });
  },
});

export const {
  setPayWithPoint,
  initOrderInfoId,
  setDisableOrder,
  setOrderDetail,
  setShippingFee,
  setAdditionalFee,
  setOrderStatus,
} = orderSlice.actions;

export default orderSlice.reducer;
