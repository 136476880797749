import API from 'api';

export const fetchCategories = async () => {
  try {
    const response = await API.get('v1/shopCate/main');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCategoriesByRank = async rank => {
  try {
    const response = await API.post('v1/shopCate/allByRank', { rank: rank });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCategoriesByParentCode = async code => {
  try {
    const res = await API.post('v1/shopCate/getByParentCode', {
      code: code,
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
};
