import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import mallReducer from './mall';
import orderReducer from './order';
import cartReducer from './cart';
import couponReducer from './coupon';
import categorySortReducer from './categorySort';
import otherShopReducer from './otherShop';

import { createLogger } from 'redux-logger';

const logger = createLogger({
  timeStamp: false,
  duration: false,
});

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    mall: mallReducer,
    coupon: couponReducer,
    order: orderReducer,
    cart: cartReducer,
    categorySort: categorySortReducer,
    otherShop: otherShopReducer,
  },

  devTools: true,
   middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
});
