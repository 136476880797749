import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { changeActiveTab } from 'store/app';

export default function ItemsName({ name, tabIndex }) {
  const dispatch = useDispatch();

  const handleMoveToTab = () => {
    dispatch(
      changeActiveTab({
        name: 'home',
        nextTabIdx: tabIndex,
      }),
    );
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      pt={2}
      pb={2}
    >
      <Typography fontSize="16px" fontWeight="bold" color="#191919">
        {name}
      </Typography>
      {/* <Typography fontSize="12px" onClick={handleMoveToTab}>
        전체보기
      </Typography> */}
    </Grid>
  );
}
