import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetachOrderPurchaseConfirmation } from 'api/order';

import PageLayout from 'components/layout/PageLayout';
import Divider from 'components/Divider';
import WriteReview from 'pages/profile/order/WriteReview';
import { parseStringToPrice } from 'utils';

export default function ConfirmOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();

  const paidOrderList = useSelector(
    state => state?.order.paidOrderList.orderInfos,
  );

  const [currentPaidOrderListItem, setCurrentPaidOrderListItem] = useState([]);
  const [currentPaidOrder, setCurrentPaidOrder] = useState();
  const [confirm, setConfirm] = useState(false);
  const [isConfirmedBefore, setIsConfirmedBefore] = useState(false);

  const [init, setInit] = useState(false);

  useEffect(() => {
    const pathname = location.pathname.split('/');
    if (pathname.includes('review') || pathname.includes('reviewlist')) {
      setConfirm(true);
      setIsConfirmedBefore(true);
    }
  }, []);

  useEffect(() => {
    return () => setInit(false);
  }, []);

  useEffect(() => {
    if (!init) return;
    if (!currentPaidOrder) {
      alert('구매확정할 주문을 선택해주세요');
      navigate('/profile');
    }
  }, [setCurrentPaidOrder, init]);

  useEffect(() => {
    setInit(true);

    if (paidOrderList?.length === 0) return;
    const filtered = paidOrderList?.find(
      item => Number(orderId) === item?.orderInfoId,
    );
    setCurrentPaidOrder(filtered);
    setCurrentPaidOrderListItem(filtered?.orderItems);
  }, [paidOrderList]);

  const confirmOrderPurchase = async id => {
    const res = await fetachOrderPurchaseConfirmation(id);

    if (res.error === 'none') setConfirm(true);
  };
  const calcPointToGet = () => {
    let point = 0;
    currentPaidOrderListItem?.forEach(item => {
      point += item.orderItemGetPoint;
    });

    return point;
  };

  return (
    <PageLayout
      title={
        confirm ? (
          <>{isConfirmedBefore ? '후기작성' : '구매확정 완료'}</>
        ) : (
          '구매확정'
        )
      }
    >
      {confirm ? (
        <>
          {isConfirmedBefore || (
            <>
              <Box p={3} pb={0}>
                <Typography align="center" mb={4}>
                  {parseStringToPrice(calcPointToGet())} 포인트 적립되었습니다.
                </Typography>
              </Box>
              <Divider />
            </>
          )}
          {currentPaidOrderListItem?.length > 1 ? (
            <Box p={2} mt={1.5}>
              {currentPaidOrderListItem?.map(item => (
                <Grid
                  container
                  gap={1}
                  key={item.orderItemId}
                  sx={{
                    py: 2,
                    borderBottom: '1px solid',
                    borderColor: 'grey.100',
                  }}
                >
                  <Grid item xs={3.5}>
                    <img
                      src={item.thumbnail}
                      alt={item.itemName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={7.5} sx={{ position: 'relative' }}>
                    <Typography mb={1}>{item.itemName}</Typography>
                    {item?.reviewWrite ? (
                      <Button disabled sx={{ display: 'block', ml: 'auto' }}>
                        후기작성완료
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ display: 'block', ml: 'auto' }}
                        onClick={() =>
                          navigate(
                            `/orderhistory/${orderId}/review/${item.orderItemId}`,
                          )
                        }
                      >
                        후기작성
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
          ) : (
            <>
              {currentPaidOrderListItem && (
                <WriteReview
                  embedded
                  confirmedItem={currentPaidOrderListItem[0]}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Box p={2} mb={1}>
            <Typography align="center" fontSize="0.9rem">
              구매확정 후에는 <br />
              반품/교환 신청을 하실 수 없습니다.
            </Typography>
          </Box>
          <Divider />
          <Box p={2} mt={1.5}>
            <Grid container mb={1.2} gap={1}>
              {currentPaidOrderListItem?.map(item => (
                <React.Fragment key={item.orderItemId}>
                  <Grid item xs={3.5}>
                    <img
                      src={item.thumbnail}
                      alt={item.itemName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={7.5}>
                    <Typography mb={1}>{item.itemName}</Typography>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="600"
                      variant="span"
                    >
                      구매확정시{' '}
                      <Typography variant="span" sx={{ color: 'primary.main' }}>
                        {item.orderItemGetPoint} 포인트{' '}
                      </Typography>
                      적립
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              position: 'sticky',
              bottom: '90px',
              display: 'flex',
              gap: 1,
              p: 2,
            }}
          >
            <Button fullWidth variant="outlined" onClick={() => navigate(-1)}>
              취소
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                confirmOrderPurchase(currentPaidOrder.orderInfoId);
              }}
            >
              구매확정
            </Button>
          </Box>
        </>
      )}
    </PageLayout>
  );
}
