import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  IconButton,
  Grid,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import { CountController } from 'components/product';
import { OptionsTypeSelector, setTextOptionValue } from 'store/mall';
import { setDisableOrder } from 'store/order';

import { setProductOptionDetail } from 'store/mall';

export default function OptionSection() {
  const dispatch = useDispatch();

  const currentProduct = useSelector(state => state?.mall.productDetail);
  const productOptionDetail = useSelector(
    state => state?.mall.productOptionDetail,
  );
  const { chosenOptions, additionalOptions } = useSelector(OptionsTypeSelector);
  const textOptionValue = useSelector(state => state?.mall.textOptionValue);

  useEffect(() => {
    // 페이지가 render될 때 선택했던 상품 수량, 옵션을 초기화
    dispatch(setProductOptionDetail([]));
    dispatch(setTextOptionValue(''));
  }, []);
  useEffect(() => {
    return () => dispatch(setDisableOrder(false));
  }, []);
  useEffect(() => {
    /**
     * 1. 필수 옵션이 포함된 상품
     * 2. text옵션이 포함된 상품
     * 3. 필수 옵션과 text옵션이 모두 포함된 상품
     *  위 세 가지에 적용
     */
    if (currentProduct.chosenOption) {
      const chosenOption = productOptionDetail.filter(
        option => option.type === 'chosen',
      );
      if (chosenOption.length === 0) {
        dispatch(setDisableOrder(true));
      } else {
        dispatch(setDisableOrder(false));
      }
    }
    if (currentProduct.textOption) {
      if (!textOptionValue) {
        dispatch(setDisableOrder(true));
      } else {
        dispatch(setDisableOrder(false));
      }
    }
  }, [productOptionDetail, textOptionValue]);

  const addSelectedOption = e => {
    // 필수옵션선택이 하나만 가능할 경우
    // const selectedChosenOption = productOptionDetail.find(
    //   option => option.type === 'chosen',
    // );
    // if (selectedChosenOption) return;

    const { value } = e.target;

    // Check already selected options
    if (value.name) {
      const selected = productOptionDetail.find(
        option => value.name === option.name,
      );
      // Count up if select a same option
      if (selected) {
        const newArray = productOptionDetail.map(option => {
          if (value.name === option.name) {
            const currentOptionQty = option.qty;
            const newQty = currentOptionQty + 1;

            if (option.qty === option.stockCount) return;

            const unFreezedOptions = productOptionDetail.map(item => ({
              ...item,
            }));
            const newOptions = unFreezedOptions.map(presentOption => {
              if (presentOption.name === option.name) {
                presentOption.qty = newQty;
              }
              return presentOption;
            });
            dispatch(setProductOptionDetail(newOptions));
          }
        });
        return;
      }
    }
    // Add a new option into selected option array
    if (!currentProduct.textOption) {
      dispatch(
        setProductOptionDetail([
          ...productOptionDetail,
          {
            optionId: value.optionId,
            type: value.type,
            name: value.name,
            price:
              value.type === 'additional' ? value.price : currentProduct.price,
            qty: 1,
            stockCount: value.stockCount,
          },
        ]),
      );
    }
    if (currentProduct.textOption) {
      dispatch(
        setProductOptionDetail([
          {
            type: 'text',
            qty: 1,
            textOption: textOptionValue,
          },
        ]),
      );
    }
  };

  const deleteSelectOption = option => {
    const newOptions = productOptionDetail.filter(
      presentOption => presentOption.name !== option.name,
    );
    dispatch(setProductOptionDetail(newOptions));
  };

  const handleChangeTextOptionValue = e => {
    dispatch(setTextOptionValue(e.currentTarget.value));
  };

  return (
    <Box p={2} sx={{ width: 1, maxHeight: '60vh' }}>
      <Box>
        <FormControl
          fullWidth
          sx={{
            height: 'fit-content',
            mb: 1,
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, 16px)',
            },
          }}
        >
          {currentProduct.chosenOption && (
            <>
              <InputLabel htmlFor="chosen-option">필수선택옵션</InputLabel>
              <Select
                sx={{
                  borderRadius: '7px 7px 0px 0px',
                }}
                value=""
                onChange={addSelectedOption}
                inputProps={genInputProps('chosen-option', 'chosen')}
              >
                <MenuItem disabled>{currentProduct.chosenOption}</MenuItem>
                {chosenOptions?.map((optionValue, index) => (
                  <MenuItem
                    key={optionValue.optionId + index}
                    value={optionValue}
                  >
                    {optionValue.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, 16px)',
            },
          }}
        >
          {!currentProduct.textOption &&
            (currentProduct.optionInfos?.length !== 0 ||
              currentProduct?.optionInfos[0].name.includes('추가옵션')) &&
            currentProduct?.optionInfos[0].price !== 0 && (
              <>
                <InputLabel htmlFor="additional-option">
                  기타선택옵션
                </InputLabel>
                <Select
                  sx={{
                    borderRadius: '7px 7px 0px 0px',
                  }}
                  value=""
                  onChange={addSelectedOption}
                  inputProps={genInputProps(
                    'select-additional-option',
                    'additional',
                  )}
                >
                  {additionalOptions?.map((optionValue, index) => (
                    <MenuItem
                      key={optionValue.optionId + index}
                      value={optionValue}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      {optionValue.name} + {optionValue.price}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          <FormControl
            fullWidth
            sx={{
              mb: 1,
              '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, 16px)',
              },
            }}
          ></FormControl>
          {currentProduct.textOption && (
            <>
              <Typography>{currentProduct.textOption}</Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                {/* <InputLabel htmlFor="text-option">텍스트 옵션</InputLabel> */}
                <TextField
                  placeholder="옵션"
                  value={textOptionValue}
                  onChange={handleChangeTextOptionValue}
                  onKeyUp={addSelectedOption}
                />
              </FormControl>
            </>
          )}
        </FormControl>
      </Box>
      <Box sx={{ maxHeight: '28vh', mb: 1, overflow: 'auto' }}>
        {productOptionDetail.map((option, index) => {
          if (
            option.type === 'none' ||
            option.type === 'text' ||
            option.type === 'noneAndAdditional'
          )
            return;
          return (
            <Grid
              sx={{ bgcolor: '#F1F1F5' }}
              container
              justifyContent="space-between"
              alignItems="center"
              key={option.optionId}
              p={1}
              mb={1}
            >
              <Grid item xs={6}>
                <Typography>{option.name}</Typography>
                <Typography>
                  {option.type === 'additional' && `- 추가:${option.price}원`}
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'flex' }}>
                <CountController currentOption={option} />
                <IconButton onClick={() => deleteSelectOption(option)}>
                  <ClearIcon sx={{ fontSize: '1.1rem' }} />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}

const genInputProps = (id, name) => ({
  id: id,
  name: name,
});
