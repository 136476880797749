import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  getCategoriesByRank,
  getThirdLevelCategoriesByParentCode,
  getProductsByCategory,
  setTabIndex,
  setCategoryProductList,
  initProductData,
} from 'store/categorySort';
import { CardShapeItem } from 'components/Items/container';
import ScrollTop from 'components/ScrollTop';
import { setCurrentCategory } from 'store/mall';

export default function CategoryProduct() {
  const dispatch = useDispatch();

  const product = useSelector(state => state?.categorySort.product);

  const ctg = useSelector(state => state?.categorySort.categories);
  const status = useSelector(state => state?.categorySort.status);
  const productList = useSelector(state => state?.categorySort.productList);
  const tabIndex = useSelector(state => state?.categorySort.tabIndex);
  const currentCategory = useSelector(state => state?.mall.currentCategory);

  const [page, setPage] = useState(1);
  const [currentCode, setCurrentCode] = useState(
    currentCategory?.length > 2 ? currentCategory : currentCategory + '01',
  );

  useEffect(async () => {
    dispatch(initProductData([]));
    dispatch(setCategoryProductList([]));
    // 대분류,중분류 초기 카테고리 불러오기, 상품풀러오기
    dispatch(getCategoriesByRank(1));
    dispatch(getThirdLevelCategoriesByParentCode(currentCategory?.slice(0, 4)));
    // dispatch(
    //   getProductsByCategory({
    //     categoryCode:
    //       currentCategory.length > 2 ? currentCategory : currentCategory + '01',
    //     page: 1,
    //     count: 20,
    //     viewStandard: 'margin',
    //     viewDirection: 'DESC',
    //   }),
    // );
  }, []);

  useEffect(() => {
    setPage(1);
    getProductBySecondRank(currentCode, page);
  }, [currentCode]);

  useEffect(() => {
    setCurrentCode(
      currentCategory?.length > 2 ? currentCategory : currentCategory + '01',
    );
  }, [currentCategory]);

  useEffect(() => {
    addProductIntoList(product);
  }, [product]);

  useEffect(() => {
    getProductBySecondRank(currentCode, page);
  }, [page]);

  const handleChange = (e, value) => {
    dispatch(setTabIndex(value));
    const code = ctg?.second[value].code + '01';
    if (ctg?.second.length !== 0) getProductBySecondRank(code, 1);
    setCurrentCode(code);
  };

  const handleOnClickFirstRankedCtg = async code => {
    if (code === currentCode.slice(0, 4) && currentCode.slice(-2) === '01')
      return;

    dispatch(setCategoryProductList([]));
    dispatch(setCurrentCategory(code));

    setPage(1);
    dispatch(getThirdLevelCategoriesByParentCode(code));
  };

  const handleOnClickSecondCategory = async item => {
    if (item.code === currentCode) return;
    dispatch(setCategoryProductList([]));
    dispatch(setCurrentCategory(item.code));
    // getProductBySecondRank(item.code, page);
    setCurrentCode(item.code);
    setPage(1);
  };

  const getProductBySecondRank = (code, p) => {
    const data = {
      categoryCode: code,
      page: p,
      count: 20,
      viewStandard: 'margin',
      viewDirection: 'DESC',
    };
    dispatch(getProductsByCategory(data));
  };

  const addProductIntoList = items => {
    const same = items.find(item => {
      const result = productList?.find(data => data.itemId === item.itemId);
      return result;
    });
    if (same) return;

    const newList = [...productList, ...items];
    dispatch(setCategoryProductList(newList));
  };

  const changePage = () => {

    setPage(prev => prev + 1);
  };

  return (
    <Box sx={{ minHeight: 'calc(100vh - 100px)' }}>
      <ThemeProvider theme={theme}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="scrollable"
          aria-label="category tab"
          sx={{ mb: 2 }}
        >
          {ctg?.second?.map((item, idx) => (
            <Tab
              key={item.code}
              label={item.name}
              value={idx}
              onClick={() => {
                handleOnClickFirstRankedCtg(item.code);
              }}
            />
          ))}
        </Tabs>
      </ThemeProvider>
      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
        }}
      >
        {ctg?.third?.map((item, idx) => (
          <ListItem
            key={item.code}
            sx={{
              py: '4px',
              px: '6px',
              border: '1px solid',
              borderColor: 'grey.100',
              fontSize: '0.8rem',
              fontWeight: currentCode === item.code ? 600 : 'inherit',
              color: currentCode === item.code ? 'inherit' : 'grey.600',
            }}
            onClick={() => {
              handleOnClickSecondCategory(item);
            }}
          >
            {item.name}
          </ListItem>
        ))}
      </List>
      {status === 'loading' && (
        <Box
          sx={{
            pt: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <InfiniteScroll
        dataLength={productList?.length}
        next={changePage}
        hasMore={product?.length !== 0}
        loader={<Typography align="center">상품을 불러오는 중</Typography>}
        endMessage={
          status === 'succeeded' && (
            <Typography fontSize="0.9rem" align="center">
              더이상 목록이 없습니다
            </Typography>
          )
        }
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 1,
            p: 2,
          }}
        >
          {productList?.map(item => (
            <CardShapeItem product={item} key={item.itemId} />
          ))}
        </Box>
      </InfiniteScroll>

      <ScrollTop />
    </Box>
  );
}

const theme = createTheme({
  components: {
    '& .MuiTabs-root': {
      style: {
        marginBottom: 0,
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        },
        scroller: {
          display: 'flex',
        },
        flexContainer: {
          alignItems: 'center',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          padding: '10px 10px',
          font: 'normal normal medium 12px/18px Noto Sans CJK KR',
          color: '#191919',
          minWidth: '50px',
          minHeight: '0',
          borderRadius: 20,

          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#191919',
          },
        },
      },
    },
  },
});
