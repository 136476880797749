import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent, Typography,
} from '@mui/material';
import { setCurrentCouponCategory, getCouponBrandList, setCouponBrandList ,  setCoupon} from 'store/coupon';

import { useDispatch } from 'react-redux';
import React from "react";
import {Box} from "@mui/system";

import cat1 from 'assets/img/coupon/cat-1.png';
import cat2 from 'assets/img/coupon/cat-2.png';
import cat3 from 'assets/img/coupon/cat-3.png';
import cat4 from 'assets/img/coupon/cat-4.png';
import cat5 from 'assets/img/coupon/cat-5.png';
import cat6 from 'assets/img/coupon/cat-6.png';
import cat7 from 'assets/img/coupon/cat-7.png';
import cat8 from 'assets/img/coupon/cat-8.png';
import cat9 from 'assets/img/coupon/cat-9.png';
import cat10 from 'assets/img/coupon/cat-10.png';
import cat11 from 'assets/img/coupon/cat-11.png';
import cat12 from 'assets/img/coupon/cat-12.png';

export default function CategoryShapeItem({ category,index }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClickItem = async e => {
     dispatch(setCurrentCouponCategory(category));
    dispatch(getCouponBrandList({category:category.value}))
    dispatch(setCouponBrandList([]));
    navigate('/couponList');
  };

  var  catImage;
  console.log(category.value);
  switch (category.value){
    case "커피": catImage = cat1; break;
    case "아이스크림/빙수": catImage = cat2; break;
    case "버거/치킨/피자": catImage = cat3; break;
    case "베이커리/도넛": catImage  = cat4; break;
    case "외식": catImage  = cat5; break;
    case "편의점": catImage  = cat6; break;
    case "상품권": catImage  = cat7; break;
    case "주유권": catImage  = cat8; break;
    case "영화/레저": catImage  = cat9; break;
    case "뷰티/헤어": catImage  = cat10; break;
    case "레저/여행": catImage  = cat11; break;
    case "기타": catImage  = cat12; break;
  }

  return (
      <Box
          width={85}
          height={115}
          onClick={handleOnClickItem}
          id={category.id}
      >
            <Box
                height={85}
                width={85}
            >
              <Box
                  height={85}
                  width={85}
                  justifyContent={"center"}
                  textAlign={"center"} mx={"auto"} p={0}>
                <CardMedia component="img" src={catImage}  style={{cursor:"pointer"}}/>
              </Box>
            </Box>

        <Typography
            fontSize="12px"
            align="center"
            pt={0}
            style={{cursor:"pointer"}}
            value={category.name}
        >
          {category.name}
        </Typography>
      </Box>
  );
}
