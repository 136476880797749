import API from 'api';

export const getRecentList = async () => {
  try {
    const response = await API.post('/v1/shopItemKeyword/recentList', {
      keywordCount: 15,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const removeRecentList = async keyword => {
  try {
    const response = await API.post('/v1/shopItemKeyword/remove', {
      keyword: keyword,
    });
    return response.status;
  } catch (error) {
    console.error(error);
  }
};
export const getItemsByKeyword = async data => {
  try {
    const response = await API.post(
      '/v1/shopItems/CategoryCodeOrProductName',
      data,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
