export const parseStringToPrice = value => {
  return Number(value).toLocaleString('ko-KR');
};

export const redirect = path => {
  window.location.replace(path);
};

export const encodeToken = token => {
  const parsedToken = JSON.stringify(token);
  const encodedToken = window.btoa(parsedToken);

  return encodedToken;
};

export const decodeToken = token => {
  return JSON.parse(window.atob(token));
};

export const parseDate = date => {
  const orderDate = date.split('T');
  return orderDate[0] + ' ' + orderDate[1];
};
