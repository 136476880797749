import PageLayout from 'components/layout/PageLayout';
import { getFAQ } from 'api/board';
import { useEffect, useState } from 'react';
import { List, Pagination, Stack } from '@mui/material';
import FAQListItem from './FAQList';

export default function FAQ() {
  const [FAQData, setFAQData] = useState();
  const [FAQList, setFAQList] = useState([]);

  useEffect(async () => {
    const FAQListFirstPage = await getFAQList(1);
    setFAQList(FAQListFirstPage);
  }, []);

  const getFAQList = async page => {
    const res = await getFAQ(page);
    setFAQData(res?.faqPage);
    return res?.faqPage?.boardInfos;
  };

  const handleChangePage = async page => {
    const FAQListItemsByPage = await getFAQList(page);

    setFAQList(FAQListItemsByPage);
  };

  return (
    <PageLayout title="FAQ">
      <List component="ul">
        {FAQList?.map((item, index) => (
          <FAQListItem key={index} FAQItem={item} FAQList={FAQList} />
        ))}
      </List>
      <Stack
        spacing={2}
        sx={{ py: 3, ul: { display: 'flex', justifyContent: 'center' } }}
      >
        <Pagination
          count={FAQData?.totalPage}
          shape="rounded"
          // showFirstButton
          // showLastButton
          onChange={(e, currentPage) => handleChangePage(currentPage)}
        />
      </Stack>
    </PageLayout>
  );
}
