import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import TabBar from 'components/layout/TabBar';
import TabPanel from 'components/layout/TabPanel';

import { PROFILE_TAB_NAMES } from 'contants';

import MyPageSection from './MyPageSection';
import AccordionSection from './AccordionSection';

import Orders from './OrdersSection';
import Coupons from './CouponsSection';
import PageLayout from 'components/layout/PageLayout';
import UserReviews from 'pages/profile/UserReviews';

import { changeActiveTab } from 'store/app';

export default function Profile() {
  const dispatch = useDispatch();
  const currentTab = useSelector(state => state.app.activeTab.profile);

  useEffect(() => {
    return () => dispatch(changeActiveTab({ name: 'profile', nextTabIdx: 0 }));
  }, []);

  return (
    <PageLayout title="마이페이지">
      <MyPageSection />

      <ThemeProvider theme={theme}>
        <TabBar
          name="profile"
          dataset={PROFILE_TAB_NAMES}
          activeTab={currentTab}
        />
      </ThemeProvider>

      <Box sx={{ bgcolor: '#F1F1F5', pb: 7 }}>
        {PROFILE_TAB_NAMES.map((name, index) => {
          return (
            <TabPanel key={name + index} name="profile" tabIndex={index}>
              {index === 0 && <Orders />}
              {index === 1 && <Coupons />}
            </TabPanel>
          );
        })}
      </Box>

      <AccordionSection />
    </PageLayout>
  );
}

const theme = createTheme({
  components: {
    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth',
      },
      styleOverrides: {
        indicator: {
          backgroundColor: '#FF203B',
          height: '2px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',

          '&.Mui-selected': {
            font: 'normal normal bold 14px/20px Noto Sans CJK KR',
            color: '#191919',
          },
        },
      },
    },
  },
});
