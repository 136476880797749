import API from 'api';

export const getAllFromBasket = async () => {
  try {
    const response = await API.post('/v1/shopOrderBasket/all', {
      pageSize: 100,
      page: 1,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const addShopItemToBasket = async data => {
  try {
    const response = await API.post(
        '/v1/shopOrderBasket/addShopItemToBasket',
        data,
    );
  } catch (error) {
    console.error(error);
  }
};

export const popShopItemFromBasket = async data => {
  try {
    const response = await API.post(
      '/v1/shopOrderBasket/popShopItemFromBasket',
      data,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const popShopItemOptionFromBasket = async () => {
  try {
    const response = await API.post(
      '/v1/shopOrderBasket/popShopItemOptionFromBasket',
    );
  } catch (error) {
    console.error(error);
  }
};
