import { useEffect, useState } from 'react';
import { Collapse, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import Divider from 'components/Divider';

import parse from 'html-react-parser';

export default function FAQList({ FAQList, FAQItem }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [FAQList]);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem
        key={FAQItem.id}
        component="li"
        sx={{ display: 'flex', pt: 1, pb: 1 }}
      >
        <ListItemText primary={FAQItem.question} onClick={handleClick} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pt={3} pb={3} pl={2} pr={2} bgcolor="#eee">
          {parse(FAQItem.answer)}
        </Box>
      </Collapse>
      <Divider />
    </>
  );
}
