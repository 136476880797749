import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTNKCategoryListItems, fetchTNKList } from 'api/otherShop';

const initialState = {
  status: 'idle',
  error: null,
  shopList: [],
  TNKList: [],
  linkPriceList: [],
};

export const getTNKListItems = createAsyncThunk(
  'otherShop/getTNKListItems',
  async page => {
    const res = await fetchTNKList(page);

    return res.data;
  },
);
export const getTNKListItemsByCategory = createAsyncThunk(
  'otherShop/getTNKListItemsByCategory',
  async ({ category, page }) => {
    const res = await fetchTNKCategoryListItems(category, page);

    return res.data;
  },
);

export const otherShopSlice = createSlice({
  name: 'categorySort',
  initialState,
  reducers: {
    initTNKList: (state, action) => {
      state.TNKList = [];
    },
    initShopList: (state, action) => {
      state.shopList = [];
    },
    updateShopList: (state, action) => {
      state.shopList = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTNKListItems.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTNKListItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.TNKList = action.payload;
      })
      .addCase(getTNKListItems.rejected, (state, action) => {
        state.error = action?.error.message;
      })

      .addCase(getTNKListItemsByCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTNKListItemsByCategory.fulfilled, (state, action) => {
        state.status = 'success';
        state.TNKList = action.payload;
      })
      .addCase(getTNKListItemsByCategory.rejected, (state, action) => {
        state.error = action?.error.message;
      });
  },
});

export const { initTNKList, initShopList, updateShopList } =
  otherShopSlice.actions;

export default otherShopSlice.reducer;
