import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Divider from 'components/Divider';
import Banner from 'components/Banner';
import SortButton from 'components/atom/SortButton';
import TabBar from 'components/layout/TabBar';
import TabPanel from 'components/layout/TabPanel';
import Items from 'components/Items';

import { useState } from 'react';
import { useEffect } from 'react';

import { setCurrentSortBy } from 'store/app';
import { initProductList } from 'store/mall';
import { useDispatch } from 'react-redux';

export default function AssortedPanel({ name, buttonsArr }) {
  const dispatch = useDispatch();
  const currentTab = useSelector(state => state?.app.activeTab[name]);
  const initialCategories = useSelector(state => state?.mall.categories);
  const mallBanners = useSelector(state => state?.mall.mallBanners);
  const currentSortBy = useSelector(state => state?.app.sortBy[name]);

  const [categories, setCategories] = useState(initialCategories);
  const [currentBanner, setCurrentBanner] = useState('');

  useEffect(() => {
    const resetSortTab = (name, value) => {
      dispatch(
        setCurrentSortBy({
          name: name,
          value: value,
        }),
      );
    };

    // dispatch(setCurrentCategory('all'));
    dispatch(initProductList());

    resetSortTab('best', 'DAY');
    resetSortTab('savingsPoint', 'margin');
  }, []);

  useEffect(() => {
    dispatch(initProductList());
  }, [currentSortBy]);

  useEffect(() => {
    displayBanners();
  }, [currentSortBy]);

  const displayBanners = () => {
    let current;
    switch (currentSortBy) {
      case 'margin':
        current = mallBanners?.margin.imgUrl;
        break;
      case 'yield':
        current = mallBanners?.yield.imgUrl;
        break;
      case 'update':
        current = mallBanners?.update.imgUrl;
        break;
    }
    setCurrentBanner(current);
  };

  return (
    <>
      <Divider color="#EBEBEB" thickness="thick" />
      {name === 'best' ? (
        <Banner
          image={
            currentSortBy === 'DAY'
              ? mallBanners?.day.imgUrl
              : mallBanners?.week.imgUrl
          }
          height="77"
          alt="베스트"
        />
      ) : (
        <Banner image={currentBanner} height="77" alt="알뜰적립" />
      )}

      <ThemeProvider theme={theme}>
        <TabBar
          name={name}
          type="category"
          activeTab={currentTab}
          dataset={categories}
          variant="scrollable"
          scrollButtons={false}
        />
      </ThemeProvider>
      <SortButtonsContainer>
        {buttonsArr.map((button, index) => {
          return (
            <Fragment key={button.name}>
              <SortButton name={name} value={button.value} fullWidth>
                {button.name}
              </SortButton>

              {index !== buttonsArr.length - 1 && (
                <Divider
                  vertical
                  variant="middle"
                  color="#EAEBEB"
                  thickness="thin"
                  flexItem
                />
              )}
            </Fragment>
          );
        })}
      </SortButtonsContainer>
      <Divider color="#EBEBEB" thickness="thick" />
      {categories.map((category, index) => (
        <Fragment key={category + index}>
          <TabPanel name={name} tabIndex={index}>
            {name === 'best' && (
              <Items
                name={category.name_2}
                categoryName={name}
                shape="col"
                xs={6}
              />
            )}
            {name === 'savingsPoint' && (
              <Items
                name={category.name_2}
                categoryName={name}
                shape="row"
                xs={6}
              />
            )}
          </TabPanel>
        </Fragment>
      ))}
    </>
  );
}

const SortButtonsContainer = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
    }}
  >
    {children}
  </Box>
);

const theme = createTheme({
  components: {
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: {
          paddingLeft: '15px',
          backgroundColor: '#FAFBFD',
        },
        scroller: {
          display: 'flex',
        },
        flexContainer: {
          alignItems: 'center',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '10px 10px',
          font: 'normal normal medium 12px/18px Noto Sans CJK KR',
          color: '#191919',
          minWidth: '50px',
          minHeight: '0',
          borderRadius: 20,

          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#191919',
          },
        },
      },
    },
  },
});
