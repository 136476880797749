import API from 'api';

export const fetchValidUser = async data => {
  try {
    const response = await API.get('v1/user/viewMyInfo', {
      headers: data,
    });

    return response;
  } catch (e) {
    console.error(e);
  }
};
export const agreeTerms = async () => {
  try {
    const response = await API.post('/v1/user/agreeTerms', {
      basicAdsmallUserTerms: true,
      personalInformationProtection: true,
    });

    return response;
  } catch (e) {
    console.error(e);
  }
};
