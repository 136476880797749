import { Box, Grid, Rating, Typography } from '@mui/material';

export default function TextReview({ review, onClick }) {
  return (
    <Box p={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            component="span"
            fontSize="13px"
            fontWeight={400}
            color="#777777"
          >
            {review.userMail}
          </Typography>
          <Typography
            pl={1}
            component="span"
            fontSize="13px"
            fontWeight={400}
            color="#777777"
          >
            {review.createDate}
          </Typography>
        </Grid>
        {/*
        <Grid item onClick={onClick}>
          <Typography fontSize="13px" fontWeight={400} color="#777777">
            신고
          </Typography>
        </Grid>
        */}
      </Grid>

      <Grid container>
        <Rating value={review.score} readOnly size="small" />
        <Typography pl={0.5} fontSize="13px" fontWeight={400}>
          {review.score === 5 && '최고예요!'}
          {review.score === 4 && '좋아요!'}
          {review.score === 3 && '보통이에요'}
          {review.score === 2 && '별로에요'}
          {review.score === 1 && '비추천'}
        </Typography>
      </Grid>
      <Grid container>
        <Typography>{review.content}</Typography>
      </Grid>
    </Box>
  );
}
